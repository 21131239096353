import { useTranslate } from "@refinedev/core";
import React, { useState } from "react";
import { Authenticated, useCreate, useGo } from "@refinedev/core";
import {
  Form,
  Button,
  Card,
  ColorPicker,
  Input,
  message,
  Col,
  Row,
  Flex,
} from "antd";
import styled from "styled-components";
import { useOrganization } from "hooks/useOrganization";
import { BrandKit } from "../types";
import { Create } from "@refinedev/antd";
import { colorKeys } from "./index";
import { BrandKitMockup } from "./components/BrandKitMockup";

// const ColorRow = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 8px;
//   gap: 8px;
// `;

export const BrandKitCreate: React.FC = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { mutateAsync: createBrandKit } = useCreate<BrandKit>();

  const go = useGo();
  const [form] = Form.useForm();

  const [colors, setColors] = useState(form.getFieldsValue().colors || {});

  const handleSave = async (values: any) => {
    try {
      const response = await createBrandKit({
        resource: `media/${organization?.id}/brand_kits`,
        values: {
          name: values.name,
          palette: values.colors,
        },
      });

      if (response.data) {
        go({
          to: {
            id: response.data.id,
            resource: "media_brandkits",
            action: "edit",
          },
        });
      } else {
        throw new Error(t("media.brandkit.create.failedToCreate"));
      }
    } catch (error) {
      message.error(t("media.brandkit.create.failedToCreate"));
      console.error(error);
    }
  };

  const onFinishFailed = ({ errorFields }: any) => {
    if (errorFields.length) {
      const firstErrorField = errorFields[0];
      const fieldElement = document.querySelector(
        `#${firstErrorField.name[0]}`
      );
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
  return (
    <Authenticated key="brandkit_create">
      <StyledWrapper>
        <Create footerButtons={<></>}>
          <Flex style={{ paddingRight: 380, minHeight: 700 }}>
            <Form
              form={form}
              onFinish={handleSave}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                name="name"
                label={t("media.brandkit.create.name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Card
                title={t("media.brandkit.create.colorPalette")}
                type="inner"
              >
                <Row gutter={[16, 16]}>
                  {colorKeys.map((colorKey) => (
                    <Col
                      xl={8}
                      lg={24}
                      md={24}
                      sm={24}
                      key={colorKey}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <Card style={{ width: "100%" }} size="small">
                        <Form.Item
                          name={["colors", colorKey]}
                          key={colorKey}
                          label={t(`media.brandkit.create.${colorKey}`)}
                          rules={[
                            {
                              required: colorKey === "primary_color",
                            },
                          ]}
                        >
                          <ColorPicker
                            showText
                            disabledAlpha
                            onChange={(color) =>
                              // handleColorChange(color.toHexString(), colorKey)
                              {
                                form.setFieldValue(
                                  ["colors", colorKey],
                                  color.toHexString()
                                );
                                setColors((prev) => ({
                                  ...prev,
                                  [colorKey]: color.toHexString(),
                                }));
                              }
                            }
                          />
                        </Form.Item>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>

              <Button
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                style={{ marginTop: 30 }}
              >
                {t("media.brandkit.create.create")}
              </Button>
            </Form>
          </Flex>
          <BrandKitMockup colors={colors} />
        </Create>
      </StyledWrapper>
    </Authenticated>
  );
};

const StyledWrapper = styled.div`
  .ant-card-actions {
    border: 0;
    > li > span > .ant-space {
      display: flex;
      padding: 24px;
      float: none !important;
      width: 100%;
      > .ant-space-item {
        width: 100%;
      }
    }
  }
`;
