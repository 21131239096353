import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  Button,
  Input,
  InputRef,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  Typography,
  Segmented,
  Row,
  Col,
  Flex,
} from "antd";
import { CreateButton, DateField, List, useTable } from "@refinedev/antd";
import { Authenticated, useGo } from "@refinedev/core";
import { UUID } from "components/UUID";
import {
  AppstoreOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useOrganization } from "hooks/useOrganization";
import { useRef, useState, useTransition } from "react";
import {
  FilterDropdownProps,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { MediaProject, TableViewMode } from "../types";
import dayjs from "dayjs";
import { HoverableCard } from "components/HoverableCard";
import { Maybe, Organization } from "types";
import SearchInput from "../../media/projects/components/SearchInput";
import { useAntTheme } from "hooks/useAntTheme";
import { useBoundStore } from "../../../store";
import { addDateTz } from "../utils";

const { Title } = Typography;

export type DataIndex = keyof MediaProject;

function getSortOrders(
  sorter: SorterResult<MediaProject> | SorterResult<MediaProject>[]
) {
  if ("length" in sorter) {
    return {
      title: sorter.find((x) => x.field === "title")?.order,
      organization: sorter.find((x) => x.field === "organization_id")?.order,
      deadline: sorter.find((x) => x.field === "deadline")?.order,
      created: sorter.find((x) => x.field === "created")?.order,
    };
  } else {
    return {
      title: sorter.field === "title" ? sorter.order : undefined,
      organization:
        sorter.field === "organization_id" ? sorter.order : undefined,
      deadline: sorter.field === "deadline" ? sorter.order : undefined,
      created: sorter.field === "created" ? sorter.order : undefined,
    };
  }
}

export const ProjectsList = () => {
  const t = useTranslate();
  const go = useGo();
  const { theme } = useAntTheme();
  const { getOrganization } = useOrganization({});
  const projectsState = useBoundStore((state) => state.projectsState);
  const setProjectsState = useBoundStore((state) => state.setProjectsState);
  const resetProjectsState = useBoundStore((state) => state.resetProjectsState);
  const { pagination, filters, sorter, searchText, viewMode } = projectsState;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MediaProject> | SorterResult<MediaProject>[]
  ) => {
    setProjectsState({ pagination, filters, sorter });
  };

  const {
    tableProps,
    tableQueryResult: { isLoading },
  } = useTable<MediaProject>({
    resource: `media/projects`,
    pagination,
    filters,
    sorters: {
      initial: [
        {
          field: "title",
          order: "desc",
        },
      ],
    },
  });
  const data = tableProps.dataSource;

  const handleViewModeChange = (mode: TableViewMode) => {
    setProjectsState({ viewMode: mode });
  };
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const sortOrders = getSortOrders(sorter);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setProjectsState({ searchText: selectedKeys[0] });
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setProjectsState({ searchText: "" });
  };

  const handleTableSearch = (searchText: string) => {
    startTransition(() => {
      setProjectsState({ searchText });
      setSearchedColumn("title");
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<MediaProject> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("media.projects.index.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("media.projects.index.reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              if (typeof selectedKeys[0] === "string") {
                setProjectsState({ searchText: selectedKeys[0] });
              }
              setSearchedColumn(dataIndex);
            }}
          >
            {t("media.projects.index.filter")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("media.projects.index.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ fontSize: "18px", color: filtered ? "#1677ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getFieldFilters = (
    field: keyof MediaProject,
    callback: (id: string | undefined) => string = (id) => id ?? ""
  ) => {
    const values = data?.map((x) => x[field]);
    const unique = new Set(values);
    return [...unique].sort().map((x) => ({
      text: callback(x as string),
      value: x as string,
    }));
  };

  const filteredData = data?.filter((item) => {
    if (searchText === "") {
      return true;
    }
    return (
      item.title.toLowerCase().includes(searchText.toLowerCase()) ||
      item.id.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const columns: TableColumnsType<MediaProject> = [
    {
      title: t("media.projects.index.id"),
      dataIndex: "id",
      showSorterTooltip: true,
      ...getColumnSearchProps("id"),
      sorter: (a: MediaProject, b: MediaProject) =>
        (a.id as string).localeCompare(b.id as string),
      sortDirections: ["descend"],
      render: (_, entry) => (
        <UUID id={entry.id as string} copyable={false} type="secondary" />
      ),
    },
    {
      title: t("media.projects.index.title"),
      dataIndex: "title",
      showSorterTooltip: true,
      ...getColumnSearchProps("title"),
      sortDirections: ["descend"],
      sorter: (a: MediaProject, b: MediaProject) =>
        a.title.localeCompare(b.title),
      sortOrder: sortOrders.title,
    },
    {
      title: t("media.projects.index.organization"),
      dataIndex: "organization_id",
      showSorterTooltip: true,
      sorter: (a: MediaProject, b: MediaProject) =>
        a.title.localeCompare(b.title),
      sortDirections: ["descend"],
      filteredValue: projectsState.filters.organization_id,
      onFilter: (value, record) =>
        record.organization_id.toLocaleLowerCase().includes(value as string),
      filters: getFieldFilters("organization_id", (id) => {
        return getOrganization(id as string)?.name ?? "";
      }),
      filterSearch: true,
      render: (_, entry) => (
        <Typography.Text type="secondary">
          {getOrganization(entry.organization_id)?.name}
        </Typography.Text>
      ),
      sortOrder: sortOrders.organization,
    },
    // not used for now
    // {
    //   title: "Deadline",
    //   showSorterTooltip: true,
    //   ...getColumnSearchProps("deadline"),
    //   sortDirections: ["descend"],
    //   dataIndex: "deadline",
    //   sorter: (a: MediaProject, b: MediaProject) =>
    //     a.deadline && b.deadline ? a.deadline.localeCompare(b.deadline) : -0,
    //   render: (_, record) =>
    //     record.deadline && (
    //       <DateField value={record.deadline} type="secondary" />
    //     ),
    //   sortOrder: sortOrders.deadline,
    // },
    {
      title: t("media.projects.index.creationDate"),
      showSorterTooltip: true,
      ...getColumnSearchProps("created"),
      sortDirections: ["ascend", "descend"],
      dataIndex: "created",
      sorter: (a: MediaProject, b: MediaProject) => {
        const dateA = a.created ?? "";
        const dateB = b.created ?? "";
        return dateA.localeCompare(dateB);
      },
      render: (_, record) =>
        record.created && (
          <DateField value={addDateTz(record.created)} type="secondary" />
        ),
      sortOrder: sortOrders.created,
    },
  ];

  const [isMatching, startTransition] = useTransition();

  return (
    <Authenticated key="projectsList">
      <List
        headerButtons={({ createButtonProps }) => (
          <>
            <Segmented
              size="large"
              options={[
                {
                  value: "list",
                  icon: <MenuOutlined />,
                },
                {
                  value: "grid",
                  icon: <AppstoreOutlined />,
                },
              ]}
              value={viewMode}
              onChange={handleViewModeChange}
            />
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.projects.index.createAProject")}
              </CreateButton>
            )}
          </>
        )}
      >
        <Flex vertical gap={20} align="center">
          <SearchInput
            onSearch={handleTableSearch}
            value={searchText}
            style={{ width: "40vw", minWidth: 300 }}
            size="large"
            name={t("media.projects.index.q")}
          />
          {viewMode === "grid" ? (
            <ProjectsGrid
              data={filteredData ? [...filteredData] : []}
              getOrganization={getOrganization}
            />
          ) : (
            <Table
              loading={isLoading}
              style={{ cursor: "pointer", width: "100%" }}
              columns={columns}
              onChange={handleTableChange}
              dataSource={filteredData}
              pagination={{ ...pagination, pageSize: 50 }}
              size="large"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    go({
                      to: {
                        resource: "media_projects",
                        id: record.id,
                        action: "show",
                      },
                    });
                  },
                };
              }}
            />
          )}
        </Flex>
      </List>
    </Authenticated>
  );
};

const ProjectsGrid = ({
  data,
  getOrganization,
}: {
  data: MediaProject[];
  getOrganization: (id: string) => Maybe<Organization>;
}) => {
  const go = useGo();

  // Group projects by organization
  const organizations: {
    [key: string]: MediaProject[];
  } = {};
  data.forEach((project) => {
    const organizationId = project.organization_id;
    if (!organizations[organizationId]) {
      organizations[organizationId] = [];
    }
    organizations[organizationId].push(project);
  });

  const formatDateTime = (
    dateTime: string | number | Date | dayjs.Dayjs | null | undefined
  ) => {
    if (!dateTime || !dayjs(dateTime).isValid()) {
      return "";
    }
    return (
      <DateField
        value={addDateTz(dateTime.toString())}
        format="MMMM D, YYYY h:mm A"
      />
    );
  };

  return (
    <Row gutter={[0, 20]} style={{ width: "100%" }}>
      {Object.entries(organizations)
        .sort(([orgNameA], [orgNameB]) => orgNameA.localeCompare(orgNameB))
        .map(([orgName, projects]) => (
          <Col key={orgName} span={24}>
            <Title level={5}>{getOrganization(orgName)?.name}</Title>
            <Row gutter={[16, 16]}>
              {projects
                .sort((projectA, projectB) =>
                  projectA.created && projectB.created
                    ? projectB.created.localeCompare(projectA.created)
                    : 0
                )
                .map((project) => (
                  <Col key={project.id} xs={24} sm={12} md={8} lg={8}>
                    <HoverableCard
                      hoverable
                      size="small"
                      title={project.title}
                      onClick={() =>
                        go({
                          to: {
                            resource: "media_projects",
                            id: project.id,
                            action: "show",
                          },
                        })
                      }
                    >
                      {dayjs(project.created).format("DD/MM/YYYY")} •{" "}
                      {dayjs(project.created).fromNow()}
                    </HoverableCard>
                  </Col>
                ))}
            </Row>
          </Col>
        ))}
    </Row>
  );
};

