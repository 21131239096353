import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { useState } from "react";
import {
  Authenticated,
  useTable,
  HttpError,
  useCreate,
  CanAccess,
} from "@refinedev/core";
import { Button, Card, Form, Input, Space, Table } from "antd";
import { PronunciationResponse, SavePronunciationRequest } from "../types";
import { DeleteButton } from "@refinedev/antd";
import { ColumnsType } from "antd/es/table";

const PronunciationsList = () => {
  const t = useTranslate();
  const [filter, setFilter] = useState<string>("");

  const { tableQueryResult } = useTable<PronunciationResponse, HttpError>({
    resource: `media/pronunciations`,
  });

  const { data, isLoading } = tableQueryResult;
  const [form] = Form.useForm();

  const formProps = {
    form,
    onFinish: async (values: any) => {
      setFilter(values.word);
    },
  };

  const filteredData =
    data?.data.filter((item: PronunciationResponse) =>
      item.word.toLowerCase().includes(filter.toLowerCase())
    ) || [];

  const columns: ColumnsType<PronunciationResponse> = [
    {
      title: t("media.pronunciations.index.language"),
      dataIndex: "language",
      key: "language",
    },
    {
      title: t("media.pronunciations.index.word"),
      dataIndex: "word",
      key: "word",
    },
    {
      title: t("media.pronunciations.index.pronouncedWord"),
      dataIndex: "pronounced_word",
      key: "pronounced_word",
    },
    {
      title: t("media.pronunciations.index.actions"),
      key: "actions",
      render: (_: any, record: PronunciationResponse) => (
        <CanAccess resource="media_pronunciations" action="delete">
          <Space>
            <DeleteButton
              size="small"
              shape="round"
              resource="media/pronunciations"
              accessControl={{
                enabled: false,
              }}
              recordItemId={`${record.language}/${record.word}`}
            >
              {t("media.pronunciations.index.delete")}
            </DeleteButton>
          </Space>
        </CanAccess>
      ),
    },
  ];

  return (
    <Card
      title={t("media.pronunciations.index.pronunciationsList")}
      size="small"
      type="inner"
    >
      <Form {...formProps} layout="inline" style={{ marginBottom: "20px" }}>
        <Form.Item name="word">
          <Input.Search
            placeholder={t("media.pronunciations.index.searchByWord")}
            allowClear
            onSearch={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <Table
        size="small"
        columns={columns}
        dataSource={filteredData}
        bordered
        loading={isLoading}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
    </Card>
  );
};
const AddPronunciationForm = () => {
  const t = useTranslate();
  const { mutateAsync: createPronunciation, isLoading } =
    useCreate<SavePronunciationRequest>();
  const [form] = Form.useForm();

  const handleFinish = async (values: SavePronunciationRequest) => {
    await createPronunciation({
      resource: "media/pronunciations",
      values,
    });
    form.resetFields();
  };

  return (
    <Card
      title={t("media.pronunciations.index.addPronunciation")}
      size="small"
      type="inner"
      style={{ marginTop: 20 }}
    >
      <Form onFinish={handleFinish} form={form}>
        <Form.Item name="language">
          <Input placeholder={t("media.pronunciations.index.enterLanguage")} />
        </Form.Item>
        <Form.Item name="word">
          <Input placeholder={t("media.pronunciations.index.enterWord")} />
        </Form.Item>
        <Form.Item name="pronounced_word">
          <Input
            placeholder={t("media.pronunciations.index.enterPronouncedWord")}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("media.pronunciations.index.add")}
        </Button>
      </Form>
    </Card>
  );
};

const PronunciationsPage = () => {
  return (
    <Authenticated key="pronunciations-page">
      <PronunciationsList />
      <AddPronunciationForm />
    </Authenticated>
  );
};

export default PronunciationsPage;

