import { useCustomMutation, useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Button, Flex, Popconfirm, Space, Typography } from "antd";
import { TDetailedStoryboard } from "pages/media/types";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";

export const Present = ({
  storyboard,
  handleStoryboardApproval,
}: {
  storyboard: TDetailedStoryboard;
  handleStoryboardApproval: () => Promise<void>;
}) => {
  const t = useTranslate();
  const [copyRef, setCopyRef] = useState<HTMLSpanElement | null>(null);

  const getFullScript = (includeTopics = false) => {
    return storyboard?.sections?.reduce((acc, x) => {
      if (includeTopics) {
        return `${acc}# ${x.topic}\n${x.script?.content}\n\n`;
      } else {
        return `${acc}${x.script?.content}\n\n`;
      }
    }, "");
  };

  const { isLoading: isStoryboardApprovalLoading } = useCustomMutation({});
  const canApprove = Boolean(storyboard);

  return (
    <Flex
      vertical
      style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}
      gap={0}
    >
      <Flex justify="space-between">
        {canApprove && (
          <Popconfirm
            title={t(
              "components.storyboard.StoryboardEditor.storyboardApproval"
            )}
            description={t("components.storyboard.StoryboardEditor.areYouSure")}
            onConfirm={handleStoryboardApproval}
            placement="topLeft"
          >
            <Button
              icon={<CheckOutlined />}
              shape="round"
              size="middle"
              loading={isStoryboardApprovalLoading}
              disabled={isStoryboardApprovalLoading}
            >
              {t("components.storyboard.StoryboardEditor.approve")}
            </Button>
          </Popconfirm>
        )}
        <Button
          shape="round"
          size="middle"
          type="default"
          onClick={() => {
            (
              copyRef?.getElementsByClassName(
                "ant-typography-copy"
              )[0] as HTMLAnchorElement
            ).click();
          }}
        >
          <Typography.Text copyable={{ text: getFullScript }} ref={setCopyRef}>
            {t("storyboard.editor.Present.copyFullScript")}
          </Typography.Text>
        </Button>
      </Flex>

      {storyboard?.sections?.map((section) => {
        return (
          <Flex key={section.id} vertical>
            <Typography.Title level={4}>{section.topic}</Typography.Title>
            <Typography.Text style={{ fontSize: 18 }}>
              {section.script?.content}
            </Typography.Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
