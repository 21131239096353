import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import styled from "styled-components";
import { Flex, Typography, Badge, Modal } from "antd";
import ProjectDocumentsList from "./ProjectDocumentsList";
import { ProjectDocumentUpload } from "./ProjectDocumentUpload";
import { useInvalidate } from "@refinedev/core";
import { MediaProjectResponse } from "pages/media/types";
import "simplebar-react/dist/simplebar.min.css";

type ProjectModal = "AssetUpload" | "DocumentUpload" | "MediaUpload";

export const ProjectKB = ({
  project,
  projectId,
  openModal,
  openedModal,
  closeModal,
}: {
  project: MediaProjectResponse;
  projectId?: string;
  openModal: (modal: ProjectModal) => void;
  openedModal?: ProjectModal;
  closeModal: () => void;
}) => {
  const t = useTranslate();
  // const go = useGo();
  const invalidate = useInvalidate();
  return (
    <KBContainer>
      <Flex vertical={true} gap={10} style={{ paddingBottom: 100 }}>
        <Typography.Title
          level={4}
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          {t("projects.components.ProjectKB.knowledgeBase")}
          <Badge
            showZero={true}
            count={project.documents?.length}
            color="#999"
          />
        </Typography.Title>
        {/* <Typography.Text
                type="secondary"
                style={{ fontWeight: "normal" }}
                >
                Attach documents to provide context for your AI
                assistant
                </Typography.Text> */}
        {/* <SimpleBar
          style={{
            paddingRight: 0,
            paddingBottom: 0,
/*!--empty-line--!*/}
        {projectId && (
          <ProjectDocumentsList
            documents={project.documents}
            primaryDocumentId={project.settings.primary_document_id}
            projectId={projectId}
          />
        )}
      </Flex>

      <Modal
        title={t("projects.components.ProjectKB.attachADocument")}
        open={openedModal === "DocumentUpload"}
        onCancel={() => closeModal()}
        footer={null}
        styles={{ body: { padding: 30 } }}
      >
        <ProjectDocumentUpload
          projectId={project.id}
          onUploadSuccess={async () => {
            await invalidate({
              resource: "media/projects",
              id: project.id,
              invalidates: ["detail"],
            });
            closeModal();
          }}
        />
      </Modal>
    </KBContainer>
  );
};

const KBContainer = styled(Flex)`
  flex-direction: column;
  gap: 20px;

  .ant-list-item {
    border: 0;
  }

  .ant-list-item-meta-title {
  }
`;

