import { useTranslate } from "@refinedev/core";
import {
  Alert,
  Button,
  Card,
  Empty,
  Flex,
  GlobalToken,
  Space,
  Typography,
} from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import type {
  TDetailedScript,
  TDetailedSection,
  TDetailedStoryboard,
  TKeyInsight,
  TScript,
} from "pages/media/types";
import { useAntTheme } from "hooks/useAntTheme";
import { DisplayMode } from "./types";
import { KeyInsight } from "./KeyInsight";
import { Label } from "./Label";
import { Script } from "./Script";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { DotsSix } from "@phosphor-icons/react";
import styled from "styled-components";
import { AudioSample } from "../../AudioSample";
import { useEffect, useState } from "react";

type TDetailedSectionProps = {
  requestTextToSpeech: (sectionId: string) => void;
  changeSection: (section: TDetailedSection) => void;
  generateId: (target: "section" | "key_insights") => string;
  generateSection: (topic: string, sectionId: string) => void;
  generateSectionScripts: (
    sectionId: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => void;
  improveScript: (
    sectionId: string,
    script: TScript,
    message: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => void; // TODO
  section: TDetailedSection;
  id: string;
  setKeyInsightId: React.Dispatch<React.SetStateAction<string[]>>;
  keyInsightsId: string[];
  displayMode: DisplayMode;
  editable?: boolean;
  index: number;
  storyboard: TDetailedStoryboard;
};

export const Section: React.FC<TDetailedSectionProps> = ({
  id,
  generateId,
  section,
  changeSection,
  generateSection,
  generateSectionScripts,
  keyInsightsId,
  displayMode,
  editable = true,
  index,
  improveScript,
  requestTextToSpeech,
}: TDetailedSectionProps) => {
  const { theme } = useAntTheme();
  const t = useTranslate();
  const [loadingTTS, setLoadingTTS] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      data: { type: "section" },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { sources } = section;

  useEffect(() => {
    if (section.speech) {
      setLoadingTTS(false);
    }
  }, [section.speech]);

  const handleKeyInsightChange = (
    index: number,
    newKeyInsight: TKeyInsight
  ) => {
    if (newKeyInsight.id) {
      const newSection = {
        ...section,
        key_insights: section.key_insights?.map((keyInsight, indice) => {
          if (index === indice)
            return {
              ...newKeyInsight,
            };
          else return keyInsight;
        }),
      };
      changeSection(newSection);
    } else {
      const newSection = {
        ...section,
        key_insights: [
          ...section.key_insights!.slice(0, index),
          ...section.key_insights!.slice(index + 1),
        ],
      };
      changeSection(newSection);
    }
  };

  const handleScriptChange = (
    index: number,
    newScript: Partial<TDetailedScript>
  ) => {
    const oldScript = section?.scripts?.[index];
    if (!newScript || !oldScript) return;

    const otherScriptsSelected =
      newScript.selected === true && oldScript.selected !== true
        ? { selected: false }
        : {};

    const updatedScripts =
      section.scripts?.map((script, indice) => {
        if (index === indice)
          return {
            // merge values
            ...script,
            ...newScript,
          };
        else {
          return { ...script, ...otherScriptsSelected };
        }
      }) ?? null;

    const newSection: TDetailedSection = {
      ...section,
      script: updatedScripts?.find((script) => script.selected), // the selected script from the list must be assigned to section.script
      scripts: updatedScripts,
    };
    console.debug("handleScriptChange", { newSection });
    changeSection(newSection);
  };

  const handleAddKeyInsight = () => {
    const newKeyInsightId = generateId("key_insights");

    changeSection({
      ...section,
      key_insights: [
        ...section.key_insights,
        {
          main_idea: "",
          description: "",
          id: newKeyInsightId,
        },
      ],
    });

    // why do we need this ? FIXME to refactor ? to dnd between sections ??
    // setKeyInsightId((old) => {
    //   if (section.key_insights.length > 0) {
    //     const lastKeyInsightId =
    //       section.key_insights[section.key_insights.length - 1].id;
    //     const index = section.key_insights.findIndex(
    //       (keyInsight) => keyInsight.id === lastKeyInsightId
    //     );
    //     return [
    //       ...old.slice(0, index),
    //       lastKeyInsightId,
    //       ...old.slice(index, old.length),
    //     ];
    //   }
    //   const sectionIndex = storyboard.sections.findIndex(
    //     (item) => item.id === section.id
    //   );
    //   const previousSection = storyboard.sections[sectionIndex - 1];
    //   const lastKeyInsightId = previousSection
    //     ? previousSection.key_insights[previousSection.key_insights.length - 1]
    //         .id
    //     : null;
    //   if (lastKeyInsightId) {
    //     return [
    //       ...old.slice(0, index),
    //       lastKeyInsightId,
    //       ...old.slice(index, old.length),
    //     ];
    //   } else {
    //     return [...old.slice(0, index), ...old.slice(index, old.length)];
    //   }
    // });
  };

  const cardTitle = (
    <Flex vertical gap={0}>
      <Space>
        <Typography.Text
          style={{
            fontSize: "1.3em",
          }}
        >
          {index + 1}.{" "}
        </Typography.Text>
        <Typography.Text
          style={{
            padding: 0,
            margin: 0,
            background: "none",
            // marginLeft: 8,
            fontSize: "1.3em",
            width: "100%",
          }}
          editable={{
            enterIcon: null,
            onChange: (value) => {
              changeSection({
                ...section,
                topic: value,
              });
            },
          }}
        >
          {section.topic}
        </Typography.Text>
      </Space>
    </Flex>
  );

  if (displayMode === "minimal") {
    return (
      <div ref={setNodeRef} {...attributes} style={style}>
        <Card
          title={cardTitle}
          size="small"
          styles={{
            body: { padding: 0, border: 0 },
            header: {
              border: 0,
              backgroundColor: "transparent",
              borderRadius: 0,
            },

            extra: { display: "flex", alignItems: "center", border: 0 },
            ...style,
          }}
          extra={[
            <DotsSix
              key={1}
              {...listeners}
              size={24}
              style={{ cursor: "move" }}
            />,
            <Button
              key={2}
              danger
              icon={
                <DeleteOutlined
                  onClick={() => {
                    changeSection({
                      detailed_summary: "",
                      scripts: [],
                      script: {
                        content: "",
                        selected: false,
                      },
                      key_insights: [],
                      topic: "",
                      id: section.id,
                      sources: [],
                    });
                  }}
                />
              }
              type="text"
            ></Button>,
          ]}
        ></Card>
      </div>
    );
  }

  return (
    <StyledSection
      style={{ marginBottom: 30 }}
      // hoverable
      styles={{
        header: {
          // backgroundColor: theme.colorText,
          // position: "sticky",
          // top: 55,
          // zIndex: 100,
          border: 0,
          padding: 16,
          paddingRight: 0,
          paddingTop: 8,
          // paddingBottom: 30,
          // alignItems: "flex-start",
        },
        body: {
          padding: 0,
        },
      }}
      title={cardTitle}
      size="small"
      extra={[
        displayMode === "detailed" ? (
          <Button
            disabled={!section.topic || !editable}
            size="small"
            shape="round"
            icon={<CaretRightOutlined />}
            onClick={() =>
              section.topic && generateSection(section.topic, section.id)
            }
          >
            {section.detailed_summary && section.detailed_summary?.length > 0
              ? "Regenerate summary"
              : "Generate summary"}
          </Button>
        ) : null,
        <Button
          danger
          key="1"
          size="middle"
          shape="circle"
          icon={
            <DeleteOutlined
              onClick={() => {
                changeSection({
                  detailed_summary: "",
                  scripts: [],
                  script: {
                    content: "",
                    selected: false,
                  },
                  key_insights: [],
                  topic: "",
                  id: section.id,
                  sources: [],
                });
              }}
            />
          }
          type="text"
        ></Button>,
      ]}
    >
      {!section.script && (
        <Alert
          showIcon
          style={{ display: "inline-flex", marginBottom: 16, marginLeft: 16 }}
          type="warning"
          message={t("storyboard.editor.Section.noFinalScript")}
        ></Alert>
      )}
      <PanelGroup direction="horizontal" autoSaveId={"storyboard_section"}>
        {displayMode !== "compact" && (
          <Panel defaultSize={50} minSize={20} order={1}>
            {/* <Flex> */}
            <Flex vertical gap={10} style={{ flex: 1 }}>
              {(displayMode === "detailed" || displayMode === "summary") &&
                section.detailed_summary && (
                  <Card
                    style={{
                      border: 0,
                      // , flex: 2
                    }}
                    styles={{
                      body: {
                        whiteSpace: "pre-wrap",
                        paddingLeft: 0,
                        paddingTop: 0,
                      },
                    }}
                  >
                    <Label
                      style={{
                        // color: theme.colorInfo,
                        background: theme.colorInfo,
                        color: "#000",
                        padding: "4px 6px",
                      }}
                    >
                      {t("storyboard.editor.Section.summary")}
                    </Label>
                    <Typography.Text
                      style={{
                        paddingLeft: 15,
                        paddingTop: 10,

                        borderLeft: "3px solid",
                        borderColor: theme.colorInfo,
                        display: "block",
                      }}
                    >
                      {section.detailed_summary}
                    </Typography.Text>
                  </Card>
                )}

              {displayMode === "detailed" && (
                <Card
                  title={
                    <Label
                      style={{
                        background: "#20EEFF",
                        color: "#000",
                        padding: "4px 6px",
                      }}
                    >
                      {t("storyboard.editor.Section.keyInsights")}
                    </Label>
                  }
                  size="small"
                  style={{ flex: 2, border: 0 }}
                  styles={{
                    body: {
                      padding: 0,
                      borderLeft: "3px solid",
                      borderColor: "#20EEFF",
                      borderRadius: 0,
                    },
                    header: {
                      padding: 0,
                      minHeight: 0,
                      alignContent: "flex-end",
                    },
                  }}
                  extra={[
                    <Button
                      key="1"
                      size="small"
                      shape="circle"
                      icon={<PlusOutlined />}
                      type="text"
                      onClick={handleAddKeyInsight}
                    />,
                  ]}
                >
                  {section.key_insights?.length === 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={t("storyboard.editor.Section.createItemsOr")}
                    />
                  )}
                  <SortableContext
                    items={keyInsightsId}
                    strategy={verticalListSortingStrategy}
                  >
                    {section.key_insights?.map((keyInsight, index) => {
                      return (
                        <KeyInsight
                          index={index}
                          key={keyInsight.id}
                          id={keyInsight.id}
                          changeKeyInsight={handleKeyInsightChange}
                          keyInsight={keyInsight}
                        />
                      );
                    })}
                  </SortableContext>
                </Card>
              )}
            </Flex>
          </Panel>
        )}

        {displayMode === "detailed" && (
          <PanelResizeHandle>
            <StyledPanelResizeHandle />
          </PanelResizeHandle>
        )}
        {(displayMode === "detailed" || displayMode === "compact") && (
          <Panel style={{ marginLeft: 16 }} order={2}>
            {(displayMode === "detailed" || displayMode === "compact") && (
              <Card
                title={
                  <Label
                    style={{
                      fontWeight: "normal",
                      background: "#43C0A0",
                      color: "#000",
                      padding: "4px 6px",
                    }}
                    type="secondary"
                  >
                    {t("storyboard.editor.Section.scripts")}
                  </Label>
                }
                size="small"
                bordered={displayMode !== "compact"}
                style={{
                  flex: 1,
                  border: 0,
                  // borderColor: theme.colorSuccessBorder,
                  // backgroundColor: theme.colorSuccessBg,
                }}
                styles={{
                  body: {
                    padding: 0,
                    borderLeft: "3px solid",
                    borderColor: "#43C0A0",
                    borderRadius: 0,
                  },
                  header: {
                    display: displayMode === "compact" ? "none" : "block",
                    alignContent: "flex-end",
                    border: 0,
                    paddingLeft: 0,
                    // borderBottom: "1px solid",
                    // borderColor: theme.colorSuccess,
                  },
                }}
                extra={[
                  // <Tooltip title={"Generate"}>
                  <Button
                    key="1"
                    disabled={!section.topic || !editable}
                    size="small"
                    shape="round"
                    // icon=
                    // icon={
                    //   <AiIcon
                    //     stroke={theme.colorText}
                    //     strokeWidth={2}
                    //     width={14}
                    //     height={14}
                    //   />
                    // }
                    // type="text"
                    onClick={() => {
                      // if (section.topic) {
                      generateSectionScripts(
                        section.id,
                        (scripts: TDetailedScript[]) => {
                          // override scripts with new generated ones
                          const newSection: TDetailedSection = {
                            ...section,
                            script: undefined,
                            scripts,
                          };
                          changeSection(newSection);
                        }
                      );
                      // }
                    }}
                    icon={<CaretRightOutlined />}
                  >
                    {t("storyboard.editor.Section.generateAlternatives")}
                  </Button>,
                  // </Tooltip>,
                ]}
              >
                {(!section.scripts || section.scripts?.length === 0) && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t(
                      "storyboard.editor.Section.generateScriptsTo"
                    )}
                  />
                )}
                {section.scripts
                  ?.filter((x) =>
                    displayMode === "compact" ? x.selected : true
                  )
                  ?.map((script, sectionIndex) => {
                    return (
                      script && (
                        <>
                          <Script
                            index={sectionIndex}
                            key={sectionIndex}
                            sectionId={section.id}
                            changeScript={handleScriptChange}
                            improveScript={improveScript}
                            script={script}
                            editable={editable}
                            sources={sources}
                          />

                          <Flex
                            style={{
                              padding: "0px 32px 10px 17px",
                              marginTop: -16,
                            }}
                            vertical
                          >
                            {/* t(
                                "storyboard.editor.Section.listenToTheScript"
                              ) */}
                            <AudioSample
                              onDemand={!section.speech}
                              playImmediately
                              loading={loadingTTS}
                              onClickPlay={() => {
                                if (!section.speech) {
                                  setLoadingTTS(true);
                                  requestTextToSpeech(section.id);
                                }
                              }}
                              audio={section.speech}
                              canDelete={false}
                            />
                          </Flex>
                        </>
                      )
                    );
                  })}
              </Card>
            )}
          </Panel>
        )}
      </PanelGroup>
    </StyledSection>
  );
};

const StyledPanelResizeHandle = styled.div<{
  theme: GlobalToken;
}>`
  width: 2px;
  background: ${({ theme }) => theme.colorBorder};
  margin-left: 1px;
  margin-right: 1px;
  height: 30px;
`;

const StyledSection = styled(Card)`
  div[data-resize-handle] {
    display: flex;
    align-items: center;
  }

  div[data-resize-handle-active="keyboard"],
  div[data-resize-handle-active="pointer"] {
    width: 3px;
    margin: 0;

    background: ${({ theme }) => theme.colorPrimary};
    > div {
      background: none;
      height: 100% !important;
    }
  }
`;
