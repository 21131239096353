import { useTranslate } from "@refinedev/core";
import {
  Authenticated,
  useCreate,
  useGo,
  useOne,
  useUpdate,
} from "@refinedev/core";
import {
  Form,
  Input,
  Typography,
  Flex,
  Button,
  Steps,
  Space,
  Spin,
  Result,
  Progress,
  Alert,
} from "antd";
import {
  BookOpenText,
  Swatches,
  Files,
  HourglassHigh,
  MaskHappy,
  Translate,
  UsersThree,
  LightbulbFilament,
  SealCheck,
  UploadSimple,
  PaintBrush,
  DotOutline,
} from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import { ProjectDocumentUpload } from "./ProjectDocumentUpload";
import { MediaProject } from "pages/media/types";
import { AiIcon, AiStarIcon } from "components/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { OrganizationSwitch } from "components/OrganizationSwitch";
import { WizardStepHeader } from "components/WizardStepHeader";
import { ProjectSettingForm } from "./ProjectSettingForm";
import styled from "styled-components";
import { CascadingText } from "components/CascadingText";
import { SettingsApprovalScreen } from "./SettingsApproval";
import { useParams } from "react-router-dom";
import { guessProjectTitleFromDocument } from "../../utils";
import { useOrganization } from "hooks/useOrganization";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { EllipsisAnimated } from "components/EllipsisAnimated";
import { MarkdownField } from "@refinedev/antd";
import { ExpandableAlert } from "components/ExpandableAlert";
import Markdown from "react-markdown";
import { StyledMarkdownFieldWrapper } from "components/StyledMarkdownFieldWrapper";
const { Text } = Typography;
import { t } from "i18n";

// const requiredSettingsKeys = ["workflow", "language", "duration", "status"];
// const settingsAreValid = (settings: { [key: string]: any }) => {
//   for (const key of requiredSettingsKeys) {
//     if (!settings[key]) return false;
//   }
//   return true;
// };

const allSteps = [
  "Upload",
  "Confirm",
  "Language",
  "Pages",
  "ContentExtraction",
  "Duration",
  "Tone",
  // "Presenter",
  "Contact",
  "BrandKit",
  "Storyboard",
  // "Call To Actions",
] as const;
type Step = (typeof allSteps)[number];
const maxWidth = 600;
const FIVE_MINUTES = 5 * 60 * 1000;

const ProjectStatus = {
  WAITING_FOR_SETTINGS: "WaitingForSettings",
  NEW: "New",
  ANALYZING_DOCUMENT: "AnalyzingDocument",
};

const StatusMessages: {
  [key: string]: string;
} = {
  // not used ?
  // [ProjectStatus.WAITING_FOR_SETTINGS]: "Waiting for settings",
  [ProjectStatus.NEW]: "Creating project",
  [ProjectStatus.ANALYZING_DOCUMENT]: "Analyzing document",
};

const ProjectProgress = ({ project }: { project: MediaProject }) => {
  const t = useTranslate();
  const [processPercent, setProcessPercent] = useState(0);
  // const { theme } = useAntTheme();
  //   const { status: liveDocumentStatus } = useDocumentStatus(
  //     data?.data?.documents[0]
  //   );

  useEffect(() => {
    if (!isDocumentProcessing(project.status ?? "")) {
      setProcessPercent(100);
    }
  }, [project.status]);

  useEffect(() => {
    if (processPercent < 100) {
      // simulate progress
      setTimeout(() => {
        if (isDocumentProcessing(project.status ?? "")) {
          setProcessPercent((value) => value + 1);
        }
      }, FIVE_MINUTES / 100);
    }
  }, [processPercent]);

  return (
    <Flex vertical>
      <Typography.Text type="secondary">
        <CascadingText
          key={project?.status ?? "none"}
          delay={0}
          cascade
          damping={5e-2}
        >
          {isDocumentProcessing(project?.status ?? "") ? (
            <>
              {StatusMessages[project.status ?? ""]}
              <EllipsisAnimated />
            </>
          ) : (
            <Space>
              <CheckCircleOutlined />
              {t("projects.components.ProjectCreateWizard.documentProcessed")}
            </Space>
          )}
        </CascadingText>
      </Typography.Text>
      <Progress
        style={{ width: "100%" }}
        showInfo={false}
        percent={processPercent}
        strokeColor={{ from: "#C13BF1", to: "#4900E5" }}
      />
    </Flex>
  );
};

const isDocumentProcessing = (status: string) => {
  return [ProjectStatus.ANALYZING_DOCUMENT, ProjectStatus.NEW].includes(status);
};

export const ProjectCreateWizard = () => {
  const params = useParams();

  const initialStep: Step = "Upload";
  const { organization, hasMultipleOrganizations } = useOrganization({});
  const go = useGo();
  const [project, setProject] = useState<MediaProject>();
  const [projectId, setProjectId] = useState<string>();
  const isEdit = !!projectId;
  const steps = isEdit ? allSteps.slice(2) : allSteps;
  const [step, setStep] = useState<Step>(initialStep);
  const stepIndex = steps.findIndex((x) => x === step);
  const { mutateAsync: create } = useCreate<MediaProject>();
  const { mutateAsync: update } = useUpdate<MediaProject>();
  const [isPollingForProject, setIsPollingForProject] = useState(false);
  const [finished, setFinished] = useState(false);
  const { theme } = useAntTheme();
  const { Text, Paragraph, Title } = Typography;
  const {
    data: projectData,
    isSuccess: projectDataSuccess,
    isLoading: projectDataLoading,
  } = useOne<MediaProject>({
    resource: "media/projects",
    id: project?.id || projectId,
    queryOptions: {
      enabled: isEdit || isPollingForProject,
      refetchInterval: (data) => {
        if (
          data?.data.status &&
          isDocumentProcessing(data.data.status) &&
          isPollingForProject
        ) {
          return 10000; // 10s
        } else {
          return false;
        }
      },
    },
  });
  const [form] = Form.useForm();

  const createProject = async (filenames: string[]) => {
    const title = guessProjectTitleFromDocument(filenames);
    // todo placeholder project name
    const data = await create({
      resource: "media/projects",
      values: {
        title,
        organization_id: organization?.id,
        type: "new_video",
      },
      successNotification() {
        return false;
      },
    });
    setProject(data.data);
    return data.data;
  };

  const onUploadSuccess = () => {
    setIsPollingForProject(true);
    setStep("Confirm");
  };

  const onUploadAbort = () => {
    setIsPollingForProject(false);
  };

  const handleSubmitTitle = async (values: {
    title: string;
    description: string;
  }) => {
    try {
      // update project title
      await update({
        resource: "media/projects",
        id: project?.id!,
        values: {
          ...values,
        },
        successNotification() {
          return false;
        },
      });
      if (finished) {
        goToProject();
        return;
      }
      setStep(steps[2]); // after 2 first ones
    } catch (e) {
      setFinished(false);
    }
  };
  // deprecated ?
  //   const minimumDate = dayjs().add(7, "day");
  const submitAndFinish = () => {
    setFinished(true);
    form.submit();
  };
  const goToProject = () => {
    go({
      to: { resource: "media_projects", id: project?.id!, action: "show" },
    });
  };

  useEffect(() => {
    if (params.id) {
      setProjectId(params.id);
      setStep(steps[2]);
    }
  }, [params.id]);

  // when editing
  useEffect(() => {
    if (projectDataSuccess) {
      setProject(projectData.data);
    }
  }, [projectDataSuccess, projectData]);

  const emptyRequiredMark = (
    label: React.ReactNode,
    {
      required,
    }: {
      required: boolean;
    }
  ) => <>{label}</>;

  return (
    <Authenticated key="projectCreate">
      <Flex>
        <Flex
          vertical
          gap={40}
          style={{
            maxWidth,
            width: maxWidth,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {/* dummy space to avoid flickering of empty div when no content */}{" "}
          {projectDataLoading && projectId && <Spin spinning />}
          {step === "Upload" && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.uploadYourDocument"
                )}
                subtitle={
                  <ExpandableAlertWrapper
                    title={t(
                      "projects.components.ProjectCreateWizard.uploadDocumentWelcome.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.uploadDocumentWelcome.text"
                    )}
                  />
                }
              />
              {hasMultipleOrganizations && (
                <Flex vertical gap={5}>
                  <Text type="secondary">
                    {t("projects.components.ProjectCreateWizard.organization")}
                  </Text>
                  <OrganizationSwitch />
                </Flex>
              )}

              <ProjectDocumentUpload
                onUploadAbort={onUploadAbort}
                onUploadSuccess={onUploadSuccess}
                onSubmit={createProject}
                submitButtonProps={{
                  type: "primary",
                  shape: "round",
                  size: "large",
                  style: { marginTop: 30 },
                  icon: null,
                }}
                submitButtonLabel={t("buttons.next")}
                showSuccessNotification={false}
                acceptedMimeTypes="application/pdf"
              />
            </>
          )}
          {step === "Confirm" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.weAreProcessing"
                )}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.itShouldTake"
                )}
              />

              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmitTitle}
                initialValues={project}
                requiredMark={emptyRequiredMark}
              >
                <Form.Item
                  label={t(
                    "projects.components.ProjectCreateWizard.confirmYourProject"
                  )}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "projects.components.ProjectCreateWizard.aProjectName"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    style={{ maxWidth }}
                    suffix={<AiIcon stroke={theme.colorText} opacity={0.4} />}
                  />
                </Form.Item>
                <Form.Item
                  label={t(
                    "projects.components.ProjectCreateWizard.describeYourGoal"
                  )}
                  name="description"
                >
                  <Input.TextArea
                    placeholder={t(
                      "projects.components.ProjectCreateWizard.optional"
                    )}
                    size="large"
                    rows={5}
                    style={{ maxWidth }}
                  />
                </Form.Item>
                <Alert
                  type="info"
                  showIcon
                  icon={<LightbulbFilament size={40} />}
                  description={
                    <Flex vertical gap={10}>
                      <Typography.Text>
                        {t("projects.components.ProjectCreateWizard.example")}
                      </Typography.Text>
                      <Typography.Text type="secondary" copyable>
                        {t("projects.components.ProjectCreateWizard.weWantTo")}
                      </Typography.Text>
                    </Flex>
                  }
                />
                <Flex justify="space-between">
                  <Button
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    size="large"
                    style={{ marginTop: 30 }}
                  >
                    {t("buttons.next")}
                  </Button>
                </Flex>
              </Form>
            </>
          )}
          {step === "Language" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.fineTuneYourRequirements"
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Language")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheLanguage"
                )}
                icon={<Translate size={24} />}
                value={project?.settings.language} // default from org
                type="Language"
                onClose={() => setStep("Pages")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
                cancellable={false}
              />
            </>
          )}
          {step === "Pages" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.fineTuneYourRequirements"
                )}
              />
              <ProjectSettingForm<string>
                projectId={project.id!}
                title={t("projects.components.ProjectCreateWizard.Pages")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseThePages"
                )}
                icon={<Files size={24} />}
                type="Pages"
                value={project.settings.selected_pages ?? ""}
                onClose={() => setStep("ContentExtraction")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "ContentExtraction" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.fineTuneYourRequirements"
                )}
                subtitle={
                  <ExpandableAlertWrapper
                    title={t(
                      "projects.components.ProjectCreateWizard.contentExtractionHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.contentExtractionHint.text"
                    )}
                  />
                }
              />
              <ProjectSettingForm<boolean>
                projectId={project.id!}
                title={t(
                  "projects.components.ProjectCreateWizard.ContentExtraction"
                )}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseYourType"
                )}
                icon={<SealCheck size={24} />}
                // TODO default to false until backend change None to false
                value={project.settings.preserve_original_doc ?? false}
                type="PreserveDocument"
                onClose={() => setStep("Duration")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "Duration" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.fineTuneYourRequirements"
                )}
                subtitle={
                  <ExpandableAlertWrapper
                    title={t(
                      "projects.components.ProjectCreateWizard.contentDuration.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.contentDuration.text"
                    )}
                  />
                }
              />
              <ProjectSettingForm<number>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Duration")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheDuration"
                )}
                icon={<HourglassHigh size={24} />}
                value={project?.settings.duration}
                type="Duration"
                onClose={() => setStep("Tone")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "Tone" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.fineTuneYourRequirements"
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Tone")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheTone"
                )}
                icon={<MaskHappy size={24} />}
                value={project.settings.tone_id}
                type="Tone"
                onClose={() => setStep("Contact")}
                // onClose={() => goToProject()}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "Contact" && (
            /* project &&  */ <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.customizeYourContact"
                )}
                subtitle={
                  <ExpandableAlertWrapper
                    title={t(
                      "projects.components.ProjectCreateWizard.chooseContactHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.chooseContactHint.text"
                    )}
                  />
                }
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Contact")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheContact"
                )}
                icon={<UsersThree size={24} />}
                value={project?.settings?.contact_id}
                type="Contacts"
                onClose={() => setStep("BrandKit")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "BrandKit" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.selectYourBrand"
                )}
                subtitle={
                  <ExpandableAlertWrapper
                    title={t(
                      "projects.components.ProjectCreateWizard.brandKitHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.brandKitHint.text"
                    )}
                  />
                }
              />
              <ProjectSettingForm<string>
                projectId={project.id!}
                title={t("projects.components.ProjectCreateWizard.BrandKit")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheBrand"
                )}
                icon={<Swatches size={24} />}
                type="BrandKit"
                value={project.settings.brand_kit_id}
                onClose={() => setStep("Storyboard")}
                cancelButtonLabel={t("buttons.skip")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {project && (
            <>
              {step === "Storyboard" && (
                <>
                  {project.settings.status === "Approved" ? (
                    <Result
                      status="success"
                      icon={<BookOpenText size={100} />}
                      title={t(
                        "projects.components.ProjectCreateWizard.thanksYourRequirements"
                      )}
                      subTitle="It should take a few minutes to generate"
                      extra={[
                        <Button
                          size="large"
                          key="new_project"
                          shape="round"
                          onClick={() =>
                            go({
                              to: {
                                resource: "media_projects",
                                action: "list",
                              },
                            })
                          }
                        >
                          {t(
                            "projects.components.ProjectCreateWizard.createAnotherProject"
                          )}
                        </Button>,
                        <Button
                          size="large"
                          type="primary"
                          key="project"
                          shape="round"
                          onClick={goToProject}
                        >
                          {t(
                            "projects.components.ProjectCreateWizard.openProject"
                          )}
                        </Button>,
                      ]}
                    />
                  ) : (
                    <SettingsApprovalScreen projectId={project?.id!} />
                  )}
                </>
              )}
            </>
          )}
        </Flex>
        <StyledSide
          vertical
          align="center"
          style={{
            margin: -theme.paddingLG, //24
            width: "24vw",
            minWidth: 250,
            padding: theme.paddingLG * 2,
            paddingTop: theme.paddingLG,
            height: "100%",
            minHeight: "100vh",
            background: "linear-gradient(0deg, #3BD4CA33 0%, #3BD4CA10 100%)",
          }}
        >
          <Flex vertical gap={60} style={{ width: "100%" }}>
            {!!project && (isEdit || stepIndex > 0) && (
              <ProjectProgress project={project} />
            )}
            {!isEdit && (
              <Flex vertical>
                <Typography.Title level={4} type="secondary">
                  {t(
                    "projects.components.ProjectCreateWizard.createYourProject"
                  )}
                </Typography.Title>
                <Space size="large">
                  <AiStarIcon width={26} />
                  <Typography.Text>
                    {t(
                      "projects.components.ProjectCreateWizard.weWillAutomatically"
                    )}
                  </Typography.Text>
                </Space>
              </Flex>
            )}
            {/* {stepIndex >= 2 && ( */}
            <Steps
              style={{ minHeight: "50vh", width: "auto" }}
              items={
                // project &&
                steps.map((step, index) => {
                  const title = t(
                    "projects.components.ProjectCreateWizard.step." +
                      step.split(" ").join("")
                  );
                  return {
                    title,
                    disabled: isEdit ? false : stepIndex < 2 || index < 2,
                  };
                })
              }
              direction="vertical"
              current={stepIndex}
              onChange={(value) => {
                setStep(steps[value]);
                // console.debug(steps[value]);
              }}
            />
            {/* )} */}
          </Flex>
        </StyledSide>
      </Flex>
    </Authenticated>
  );
};

const ExpandableAlertWrapper = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  const { theme } = useAntTheme();

  return (
    <ExpandableAlert
      description={
        <StyledMarkdownFieldWrapper color={theme.colorInfoActive}>
          <Typography.Title level={5} style={{ margin: 0, marginBottom: 10 }}>
            {title}
          </Typography.Title>
          <MarkdownField value={text} />
        </StyledMarkdownFieldWrapper>
      }
    />
  );
};

const StyledSide = styled(Flex)`
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("https://images.unsplash.com/photo-1634017839464-5c339ebe3cb4?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
  }
`;
