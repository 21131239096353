import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { SaveButton, useForm } from "@refinedev/antd";
import { useApiUrl, useNotification } from "@refinedev/core";
import { ButtonProps, Flex, Form, Select, UploadFile } from "antd";
import { useRef, useState } from "react";

import { PaperClipOutlined } from "@ant-design/icons";
import { MediaProject } from "pages/media/types";
import UploadFiles, { UploadFilesRef } from "components/UploadFiles";

type FormData = {
  document_type: string;
  sub_category: string;
  source_url: string;
  filename: string;
};
const ACCEPTED_MIME_TYPES =
  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf";

export const ProjectDocumentUpload = ({
  projectId,
  onUploadSuccess,
  onSubmit,
  onUploadAbort,
  submitButtonProps,
  submitButtonLabel,
  showSuccessNotification = true,
  acceptedMimeTypes = ACCEPTED_MIME_TYPES,
}: {
  projectId?: string;
  onUploadSuccess?: Function;
  onUploadAbort?: Function;
  onSubmit?: (filenames: string[]) => Promise<MediaProject>;
  submitButtonProps?: ButtonProps;
  submitButtonLabel?: string;
  showSuccessNotification?: boolean;
  acceptedMimeTypes?: string;
}) => {
  const t = useTranslate();
  const [isUploading, setIsUploading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const { open } = useNotification();
  const [file, setFile] = useState<UploadFile | null>(null);
  const uploadFilesRef = useRef<UploadFilesRef>(null);
  const { formProps, saveButtonProps, form } = useForm<FormData>({
    // won't be used, the form will be submitted manually to support
    // custom file upload
    action: "create",
    resource: "media/documents",
    onMutationSuccess: (data: any) => {},
  });
  const document_type = Form.useWatch("document_type", form);

  const apiUrl = useApiUrl();

  const handleFinish = async (values: object) => {
    setCompleted(0);

    console.debug("handleFinish");
    setIsUploading(true);
    const newProject = onSubmit && (await onSubmit([file!.name]));
    try {
      await uploadFilesRef.current?.upload(
        (fileName: string) =>
          `${apiUrl}/media/projects/${projectId ?? newProject?.id}/documents/${fileName}`
      );
      setIsUploading(false);
    } catch (error) {
      console.error(error);
      open?.({
        type: "error",
        message: t(
          "projects.components.ProjectDocumentUpload.somethingWentWrong"
        ),
        description: t(
          "projects.components.ProjectDocumentUpload.pleaseTryAgain"
        ),
      });
    }
    // update the file upload status in the upload component
    onUploadSuccess && onUploadSuccess();
    setIsUploading(false);
    form.resetFields();

    if (showSuccessNotification) {
      open?.({
        type: "success",
        message: t(
          "projects.components.ProjectDocumentUpload.uploadSuccessful"
        ),
        description: `Starting processing document ${file!.name}`,
        key: file!.name,
      });
    }
    // invalidate on end
    onUploadSuccess && onUploadSuccess();
  };
  const assetRequestProperties = {
    document_type,
    project_id: projectId,
  };
  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={handleFinish}
      disabled={isUploading}
    >
      <Form.Item
        name="document_type"
        rules={[
          {
            required: true,
            message: t(
              "projects.components.ProjectDocumentUpload.pleaseChooseA"
            ),
          },
        ]}
      >
        <Select
          size="large"
          placeholder={t(
            "projects.components.ProjectDocumentUpload.typeOfYour"
          )}
          options={[
            {
              value: "BankResearch",
              label: t(
                "projects.components.ProjectDocumentUpload.bankResearch"
              ),
            },
            {
              value: "FundsPresentation",
              label: t(
                "projects.components.ProjectDocumentUpload.fundProspectus"
              ),
            },
            {
              value: "IpoDocument",
              label: t(
                "projects.components.ProjectDocumentUpload.ipoProspectus"
              ),
            },
            {
              value: "PortfolioReview",
              label: t(
                "projects.components.ProjectDocumentUpload.portfolioReview"
              ),
            },
            {
              value: "EquityResearch",
              label: t(
                "projects.components.ProjectDocumentUpload.equityResearch"
              ),
            },
            {
              value: "CompanyFinancialPublication",
              label: t(
                "projects.components.ProjectDocumentUpload.companyFinancialPublication"
              ),
            },
            {
              value: "Unknown",
              label: t("projects.components.ProjectDocumentUpload.other"),
            },
          ]}
        />
      </Form.Item>
      <Flex vertical gap={5} style={{ marginTop: 10 }}>
        <UploadFiles
          ref={uploadFilesRef}
          onFileAdded={(fileList) => setFile(fileList[0])}
          onAbort={() => {
            onUploadAbort?.();
            setIsUploading(false);
          }}
          onUploadComplete={(_files) => onUploadSuccess && onUploadSuccess()}
          itemRequestProperties={assetRequestProperties}
          maxCount={1}
          accept={acceptedMimeTypes}

              name="file"

          multiple={false}
          acceptedMimeTypes="application/pdf"
        ></UploadFiles>
      </Flex>
      <SaveButton
        {...saveButtonProps}
        type="default"
        style={{ marginTop: 30 }}
        icon={<PaperClipOutlined />}
        {...submitButtonProps}
        disabled={isUploading}
      >
        {submitButtonLabel ?? "Attach"}
      </SaveButton>
    </Form>
  );
};

