import { useTranslate } from "@refinedev/core";
import React, { useEffect, useRef, useState } from "react";
import { Slider, Flex, ConfigProvider, Button, Space } from "antd";
import {
  CaretRightOutlined,
  PauseOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { BrandKitAssetResponseWithTypedPurpose } from "./BrandKitAssets";

import { FontPreviewText } from "./FontPreview";
import iPhoneFrame from "../../../../assets/iPhoneMockup.png";
import { PaletteResponse } from "media";

interface BrandKitMockupProps {
  assets?: BrandKitAssetResponseWithTypedPurpose[];
  colors?: PaletteResponse;
}

export const BrandKitMockup: React.FC<BrandKitMockupProps> = ({
  assets,
  colors,
}) => {
  const t = useTranslate();

  const logo = assets?.find((asset) => asset.purpose === "Logo");
  const titleFont = assets?.find((asset) => asset.purpose === "Title");
  const textFont = assets?.find((asset) => asset.purpose === "Text");
  const audio = assets?.find((asset) => asset.purpose === "Music");
  const background = assets?.find((asset) => asset.purpose === "Background");

  if (!iPhoneFrame) return;

  return (
    <Flex
      align="center"
      justify="center"
      style={{
        width: "380px",
        height: "768px",
        // smaller for smaller screens
        transform: "scale(0.9)",
        position: "fixed",
        top: 100,
        right: 30,
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      >
        <img height="100%" src={iPhoneFrame} alt="" />
      </Flex>
      <Flex
        vertical
        style={{
          height: "700px",
          width: "339px",
          marginBottom: "-35px",
          zIndex: 2,
        }}
      >
        <Flex
          align="center"
          justify="center"
          style={{
            height: "60px",
            width: "100%",
            textAlign: "center",
            padding: 2,
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          {logo?.path && (
            <img src={logo?.path} height="100%" width="auto" alt="logo" />
          )}
        </Flex>
        <Flex
          align="center"
          justify="center"
          vertical
          style={{
            display: "flex",
            backgroundColor: colors?.secondary_color
              ? ""
              : colors?.primary_color,
            backgroundImage: background
              ? `url(${background.path})`
              : `linear-gradient(45deg, ${colors?.secondary_color} 50%, ${colors?.primary_color} 50%)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <FontPreviewText
            font={titleFont?.path ?? ""}
            text={t("media.brandkit.mockup.title")}
            style={{
              textAlign: "center",
              width: 200,
              height: 60,
              letterSpacing: 2,
              color: colors?.title_color ?? "#000",
              WebkitTextStroke: `2px ${colors?.title_stroke_color ?? ""}`,
            }}
          />
          <FontPreviewText
            style={{
              fontSize: "30px",
              width: "300px",
              color: colors?.subtitle_color ?? "#000",
              WebkitTextStroke: `1px ${colors?.subtitle_stroke_color ?? ""}`,
            }}
            font={textFont?.path ?? ""}
            text={t("media.brandkit.mockup.exampleText")}
          />
        </Flex>
        <PlayerWrapper src={audio?.path} />
      </Flex>
    </Flex>
  );
};

const PlayerWrapper = ({ src }: { src?: string }) => {
  const player = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const handleEnded = () => {
      if (player.current) {
        player.current.pause();
        setCurrentTime(0);
        setIsPlaying(false);
      }
    };

    if (player.current) {
      player.current.addEventListener("ended", handleEnded);
    }

    return () => {
      if (player.current) {
        player.current.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  const handlePlayerClick = () => {
    if (player.current) {
      if (isPlaying) {
        setIsPlaying(false);
        player.current.pause();
      } else {
        setIsPlaying(true);
        player.current.play();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (player.current) {
      setCurrentTime(player.current.currentTime);
    }
  };

  const handleSliderChange = (value: number) => {
    if (player.current) {
      player.current.currentTime = value;
    }
  };

  const handleDuration = () => {
    if (player.current) {
      setDuration(player.current.duration);
    }
  };

  // show time in min:sec
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      style={{
        height: "fit-content",
        width: "100%",
        background: "#000",
        padding: 10,
        boxSizing: "border-box",
        borderRadius: "0px 0px 20px 20px",
      }}
      gap={2}
    >
      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Flex align="center" gap={5} style={{ color: "#fff" }}>
          {src && (
            <audio
              ref={player}
              src={src}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleDuration}
            />
          )}
          <Button
            type="text"
            onClick={src === "" ? undefined : handlePlayerClick}
            icon={
              isPlaying ? (
                <PauseOutlined style={{ fontSize: "20px" }} />
              ) : (
                <CaretRightOutlined style={{ fontSize: "25px" }} />
              )
            }
            style={{ color: "#fff" }}
          />
          <div>
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
        </Flex>
        <Space align="center" style={{ color: "#fff", fontSize: "20px" }}>
          {src && <SoundOutlined />}
          {/* <FullscreenOutlined /> */}
          {/* <MoreOutlined /> */}
        </Space>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              railBg: "rgba(255,255,255,0.3)",
            },
          },
        }}
      >
        <div style={{ width: "90%" }}>
          <Slider
            value={currentTime}
            onChange={handleSliderChange}
            max={duration}
            tooltip={{ open: false }}
            styles={{
              tracks: {
                background: "#fff",
              },
            }}
          />
        </div>
      </ConfigProvider>
    </Flex>
  );
};
