import { useGo, useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { useOne } from "@refinedev/core";
import {
  Button,
  Flex,
  GlobalToken,
  Segmented,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Ref, useCallback, useRef, useState, forwardRef } from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { Link } from "react-router-dom";
import { BookOpenText, Play } from "@phosphor-icons/react";

import { useAntTheme } from "hooks/useAntTheme";
import { PdfViewer } from "components/PdfViewer";
import { StoryboardEditor } from "./StoryboardEditor";
import {
  DocumentExtract,
  MediaDocument,
  MediaProjectResponse,
  TDetailedSection,
  TDetailedStoryboard,
} from "pages/media/types";
import { DisplayMode } from "./editor/types";
import {
  ArrowLeftOutlined,
  OrderedListOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { StoryboardStatusTag } from "../StoryboardStatus";
import { getLocaleCountryCode } from "pages/media/utils";
import { StoryboardProvider, useStoryboard } from "./context/StoryboardContext";
import styled from "styled-components";
import { positionToXY } from "helpers/utils";
import { IHighlight } from "react-pdf-highlighter";

export const StoryboardStudio = ({
  project,
  storyboardId,
}: {
  project?: MediaProjectResponse;
  storyboardId?: string;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const go = useGo();
  const [displayMode, setDisplayMode] = useState<DisplayMode>("present");
  const [highlightedSource, setHighlightedSource] = useState<string>();
  // const [showPlan, setShowPlan] = useState(true);
  // const [storyboard, dispatch] = useReducer(
  //   storyboardReducer,
  //   sampleStoryboard
  // );
  const pdfViewerPanelRef = useRef<ImperativePanelHandle>(null);
  const pdfViewerRef = useRef<ImperativePanelHandle>(null);
  const sourcesRef = useRef<Record<string, HTMLElement>>({});
  const currentDocument = project?.settings.primary_document_id
    ? project.documents.find(
        (x) => x.id === project?.settings.primary_document_id
      )
    : project?.documents[0];
  // const { organization } = useOrganization({ id: project?.organization_id });

  const { data } = useOne<MediaDocument>({
    resource: `media/documents/${project?.organization_id}`,
    id: currentDocument?.id,
    queryOptions: {
      enabled: Boolean(project?.organization_id) && Boolean(currentDocument),
    },
  });
  const document = data?.data;
  // console.debug({ document, currentDocument, project });

  // const { currentStep = "Plan" } = storyboard;
  // const steps = [
  //   {
  //     key: "Plan",
  //     title: "Storytelling",
  //   },
  //   {
  //     key: "Content",
  //     title: "Storyboard",
  //   },
  //   {
  //     key: "Timeline",
  //     title: "Timeline",
  //   },
  // ];

  // const handleStepChange = (index: number) => {
  //   dispatch({ type: "set_step", payload: steps[index].key });
  // };

  // const currentStepIndex = steps.findIndex((x) => x.key === currentStep);

  // const resetHighlights = () => {
  //   setHighlights([]);
  // };

  // const [highlights, setHighlights] = useState<IHighlight[]>([]);

  // const addHighlightToCurrentScene = (value: IHighlight) => {
  //   dispatch({
  //     type: "add_object",
  //     payload: {
  //       type: value.content.text ? "Text" : "Image",
  //       content: value.content.text || value.content.image,
  //     },
  //   });
  // };

  // useEffect(() => {
  //   console.debug({ storyboard });
  // }, [storyboard]);

  // useEffect(() => {
  //   if (currentStep === "Timeline") {
  //     pdfViewerPanelRef.current?.collapse();
  //     planPanelRef.current?.collapse();
  //     storyboardPanelRef.current?.expand();
  //     bottomPanelRef.current?.expand();
  //   } else if (currentStep === "Plan") {
  //     planPanelRef.current?.expand();
  //     storyboardPanelRef.current?.collapse();
  //     pdfViewerPanelRef.current?.expand();
  //     bottomPanelRef.current?.collapse();
  //   } else if (currentStep === "Content") {
  //     planPanelRef.current?.expand();
  //     storyboardPanelRef.current?.expand();
  //     pdfViewerPanelRef.current?.expand();
  //     bottomPanelRef.current?.collapse();
  //   }
  // }, [currentStep]);

  const handleDisplaySource = useCallback(
    (source: DocumentExtract) => {
      (pdfViewerRef?.current as any)?.scrollViewerTo(source);
    },
    [pdfViewerRef]
  );

  const handleClickHighlight = (sourceId: string) => {
    const sourceRef = sourcesRef.current[sourceId];
    if (!sourcesRef) return;
    sourceRef.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
    setHighlightedSource(sourceId);
  };

  const storyboard = project?.storyboards.find(
    (x) => x.asset_id === storyboardId
  );
  const [detailedStoryboard, setDetailedStoryboard] =
    useState<TDetailedStoryboard>();

  return (
    <StoryboardProvider
      value={{
        project: project!,
        handleDisplaySource,
        storyboard: detailedStoryboard,
        highlights: detailedStoryboard?.sections.reduce((acc, section) => {
          if (!section.sources) return acc;
          acc.push(...sourcesToHighlights(section));
          return acc;
        }, [] as IHighlight[]),
        setStoryboard: setDetailedStoryboard,
        handleClickHighlight: () => null,
        sourcesRef,
        highlightedSource,
        setHighlightedSource,
      }}
    >
      <Flex vertical style={{ margin: -24 }}>
        <Flex
          justify="space-between"
          style={{
            borderBottom: "1px solid",
            borderBottomColor: theme.colorBorderSecondary,
            padding: 8,
            paddingRight: 16,
          }}
        >
          <Button
            type="text"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              go({ to: `/media/projects/${project?.id}` });
            }}
          >
            {t("components.binder.index.backToProject")}
          </Button>

          <Space wrap={true}>
            <Typography.Text type="secondary">
              {t("components.storyboard.StoryboardStudio.projects")}
            </Typography.Text>
            <Link
              to={"/media/projects/" + project?.id}
              style={{ color: theme.colorText }}
            >
              {project?.title}
            </Link>
            <Typography.Text type="secondary">
              {t("components.storyboard.StoryboardStudio.storyboard")}{" "}
              {/* {storyboardId && <UUID id={storyboardId} type="secondary" />} */}
            </Typography.Text>
            {storyboard && (
              <Space size="small">
                <Tooltip
                  title={storyboard.language}
                  showArrow={false}
                  placement="left"
                  mouseEnterDelay={1}
                >
                  <div
                    className={`fi-${getLocaleCountryCode(storyboard.language)}`}
                    style={{
                      width: "24px",
                      height: "14px",
                      backgroundSize: "cover",
                    }}
                  />
                </Tooltip>
                <StoryboardStatusTag status={storyboard.status} />
              </Space>
            )}
          </Space>
          <Space>
            <Select
              size="small"
              options={[{ value: document?.id, label: document?.name }]}
              value={document?.id}
            />

            {/* <Button
        size="middle"
        type="text"
        // disabled
        icon={
          <span className="anticon">
            <Play size={16} />
          </span>
        }
      >
        Present
      </Button> */}
          </Space>
        </Flex>
        <StyledPanelGroupWrapper>
          <PanelGroup
            autoSaveId="storyboard_container"
            direction="vertical"
            style={{ height: "calc(100vh - 60px)" }}
          >
            <Panel defaultSize={75}>
              <PanelGroup autoSaveId="storyboard" direction="horizontal">
                <Panel order={1} defaultSize={20} minSize={20}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      padding: 15,
                      paddingTop: 0,
                    }}
                  >
                    <Space
                      style={{
                        width: "110%",
                        marginLeft: -15,
                        padding: "10px 15px",
                        position: "sticky",
                        top: 0,
                        background: theme.colorBgLayout,
                        zIndex: 10,
                        borderBottom: `1px solid ${theme.colorBorderSecondary}`,
                      }}
                    >
                      <StyledSegmented<DisplayMode>
                        size="large"
                        value={displayMode}
                        onChange={(value) =>
                          setDisplayMode(value as DisplayMode)
                        }
                        options={[
                          {
                            label: t(
                              "components.storyboard.StoryboardStudio.present"
                            ),
                            value: "present",
                            icon: (
                              <span className="anticon">
                                <Play size={16} />
                              </span>
                            ),
                          },
                          {
                            label: t(
                              "components.storyboard.StoryboardStudio.chapters"
                            ),
                            value: "minimal",
                            icon: <OrderedListOutlined />,
                          },
                          // {
                          //   label: "Summary",
                          //   value: "summary",
                          //   icon: <AlignLeftOutlined />,
                          // },
                          {
                            label: t(
                              "components.storyboard.StoryboardStudio.script"
                            ),
                            value: "compact",
                            icon: (
                              <span className="anticon">
                                <BookOpenText size={16} />
                              </span>
                            ),
                          },
                          {
                            label: t(
                              "components.storyboard.StoryboardStudio.advanced"
                            ),
                            value: "detailed",
                            icon: <RocketOutlined />,
                          },
                        ]}
                      />
                    </Space>
                    <StoryboardEditor
                      project={project}
                      storyboardId={storyboardId}
                      displayMode={displayMode}
                    />
                  </div>
                </Panel>
                <PanelResizeHandle>
                  <StyledPanelResizeHandle></StyledPanelResizeHandle>
                </PanelResizeHandle>
                <Panel
                  order={3}
                  collapsible
                  ref={pdfViewerPanelRef}
                  defaultSize={40}
                  minSize={0}
                  maxSize={90}
                  style={{ position: "relative" }}
                >
                  {document?.url && (
                    <PdfViewerWrapper
                      ref={pdfViewerRef as unknown as Ref<void>}
                      url={document?.url}
                    />
                  )}
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </StyledPanelGroupWrapper>
      </Flex>
    </StoryboardProvider>
  );
};

const PdfViewerWrapperBase = (
  {
    url,
  }: {
    url: string;
  },
  ref: React.Ref<void>
) => {
  const { highlights, highlightedSource, handleClickHighlight } =
    useStoryboard();

  return (
    <PdfViewer
      ref={ref}
      url={url}
      highlights={highlights}
      activeHighlightId={highlightedSource}
      onHighlightClick={handleClickHighlight}
      scrollToHighlightYOffset={50}
    />
  );
};

// to fix eslint Component definition is missing display name  react/display-name
const PdfViewerWrapper = forwardRef(PdfViewerWrapperBase);

const StyledSegmented = styled(Segmented)<{
  theme: GlobalToken;
}>`
  background: none;
  .ant-segmented-item {
    padding: 2px 0;
    color: ${({ theme }) => theme.colorTextSecondary};
    border: 1px solid transparent;
  }

  .ant-segmented-thumb {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }

  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    // transition: color 0.2s;
    color: ${({ theme }) => theme.colorText};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
  }
`;
const StyledPanelResizeHandle = styled.div<{
  theme: GlobalToken;
}>`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.colorBorder};
  margin-left: 1px;
  margin-right: 1px;
`;

const StyledPanelGroupWrapper = styled.div<{
  theme: GlobalToken;
}>`
  div[data-resize-handle-active="keyboard"],
  div[data-resize-handle-active="pointer"] {
    width: 3px;
    margin: 0;
    background: ${({ theme }) => theme.colorPrimary};
    > div {
      background: none;
    }
  }
`;

// interface HighlightListProps {
//   highlights: Array<IHighlight>;
//   resetHighlights: () => void;
//   onPick: (value: IHighlight) => void;
//   vertical?: boolean;
// }

// const updateHash = (highlight: IHighlight) => {
//   global.document.location.hash = `highlight-${highlight.id}`;
// };

// const HighlightList = ({
//   highlights,
//   resetHighlights,
//   onPick,
//   vertical,
// }: HighlightListProps) => {
//   return (
//     <Flex vertical={vertical === true}>
//       {highlights.map((highlight, index) => (
//         <StyledHighlight
//           size="small"
//           key={index}
//           // className="sidebar__highlight"
//           onClick={() => {
//             updateHash(highlight);
//           }}
//         >
//           <StyledHighlightText>
//             <Space size={"middle"}>
//               <Button
//                 size="small"
//                 icon={<PlusOutlined />}
//                 onClick={() => onPick(highlight)}
//               />
//               <Typography.Text className="highlight__location">
//                 Page {highlight.position.pageNumber}
//               </Typography.Text>
//             </Space>
//             {/* <strong>{highlight.comment.text}</strong> */}
//             {highlight.content.text ? (
//               <div style={{ marginTop: "0.5rem" }}>
//                 {`${highlight.content.text.slice(0, 90).trim()}…`}
//               </div>
//             ) : null}
//             {highlight.content.image ? (
//               <div className="highlight__image" style={{ marginTop: "0.5rem" }}>
//                 <img
//                   src={highlight.content.image}
//                   alt={"Screenshot"}
//                   style={{ maxWidth: 200, maxHeight: 150 }}
//                 />
//               </div>
//             ) : null}
//           </StyledHighlightText>
//         </StyledHighlight>
//       ))}
//     </Flex>
//   );
//   {
//   }
//   {
//   }
// };

// const HighlightPopup = ({
//   comment,
// }: {
//   comment: { text: string; emoji: string };
// }) =>
//   comment.text ? (
//     <div className="Highlight__popup">
//       {comment.emoji} {comment.text}
//     </div>
//   ) : null;

// const PdfDocumentViewer = ({
//   document,
//   highlights,
//   setHighlights,
// }: {
//   document: any;
//   highlights: IHighlight[];
//   setHighlights: Dispatch<SetStateAction<IHighlight[]>>;
// }) => {
//   const getHighlightById = (id: string) => {
//     return highlights.find((highlight) => highlight.id === id);
//   };

//   const getNextId = () => String(Math.random()).slice(2);

//   const addHighlight = (highlight: NewHighlight) => {
//     console.log("Saving highlight", highlight);

//     setHighlights((value) => [{ ...highlight, id: getNextId() }, ...value]);
//   };

//   const updateHighlight = (
//     highlightId: string,
//     position: Object,
//     content: Object
//   ) => {
//     console.log("Updating highlight", highlightId, position, content);

//     setHighlights((value) =>
//       value.map((h) => {
//         const {
//           id,
//           position: originalPosition,
//           content: originalContent,
//           ...rest
//         } = h;
//         return id === highlightId
//           ? {
//               id,
//               position: { ...originalPosition, ...position },
//               content: { ...originalContent, ...content },
//               ...rest,
//             }
//           : h;
//       })
//     );
//   };

//   let scrollViewerTo = (highlight: any) => {};

//   const parseIdFromHash = () =>
//     global.document.location.hash.slice("#highlight-".length);

//   const resetHash = () => {
//     global.document.location.hash = "";
//   };

//   const scrollToHighlightFromHash = () => {
//     const highlight = getHighlightById(parseIdFromHash());

//     if (highlight) {
//       scrollViewerTo(highlight);
//     }
//   };

//   return (
//     <PdfLoader url={document?.url} beforeLoad={<Spin />}>
//       {(pdfDocument) => (
//         <PdfHighlighter
//           pdfDocument={pdfDocument}
//           enableAreaSelection={(event) => event.altKey}
//           onScrollChange={resetHash}
//           // pdfScaleValue="page-width"
//           scrollRef={(scrollTo) => {
//             scrollViewerTo = scrollTo;

//             scrollToHighlightFromHash();
//           }}
//           onSelectionFinished={(
//             position,
//             content,
//             hideTipAndSelection,
//             transformSelection
//           ) => (
//             <Tip
//               onOpen={transformSelection}
//               onConfirm={(comment) => {
//                 addHighlight({ content, position, comment });

//                 hideTipAndSelection();
//               }}
//             />
//           )}
//           highlightTransform={(
//             highlight,
//             index,
//             setTip,
//             hideTip,
//             viewportToScaled,
//             screenshot,
//             isScrolledTo
//           ) => {
//             const isTextHighlight = !Boolean(
//               highlight.content && highlight.content.image
//             );

//             const component = isTextHighlight ? (
//               <Highlight
//                 isScrolledTo={isScrolledTo}
//                 position={highlight.position}
//                 comment={highlight.comment}
//               />
//             ) : (
//               <AreaHighlight
//                 isScrolledTo={isScrolledTo}
//                 highlight={highlight}
//                 onChange={(boundingRect) => {
//                   updateHighlight(
//                     highlight.id,
//                     {
//                       boundingRect: viewportToScaled(boundingRect),
//                     },
//                     { image: screenshot(boundingRect) }
//                   );
//                 }}
//               />
//             );

//             return (
//               <Popup
//                 popupContent={<HighlightPopup {...highlight} />}
//                 onMouseOver={(popupContent) =>
//                   setTip(highlight, (highlight) => popupContent)
//                 }
//                 onMouseOut={hideTip}
//                 key={index}
//                 children={component}
//               />
//             );
//           }}
//           highlights={highlights}
//         />
//       )}
//     </PdfLoader>
//   );
// };

function sourcesToHighlights({
  sources,
  ...section
}: {
  [key in keyof TDetailedSection]: TDetailedSection[key];
}): IHighlight[] {
  return sources.flatMap((source, sourceIndex) => {
    const id = `${section.id}-${sourceIndex}`;
    const occurrence = source.occurrences[0];
    return {
      id,
      position: {
        pageNumber: occurrence.page_number + 1,
        usePdfCoordinates: true,
        boundingRect: {
          ...positionToXY(occurrence.coordinates),
          width: 0,
          height: 0,
          pageNumber: occurrence.page_number + 1,
        },
        rects: source.occurrences.map((occurrence) => ({
          ...positionToXY(occurrence.coordinates),
          width: 0,
          height: 0,
          pageNumber: occurrence.page_number + 1,
        })),
      },
      content: {
        text: occurrence.chunk,
      },
      comment: {
        text: source.text,
        emoji: "",
      },
    };
  });
}
