import { useTranslate } from "@refinedev/core";
import React, { useState } from "react";
import {
  Typography,
  Input,
  Button,
  Flex,
  Form,
  Space,
  Switch,
  TableColumnsType,
  Radio,
  TableColumnType,
  Table,
  Tooltip,
  Alert,
  Segmented,
  GlobalToken,
} from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { FormInstance } from "antd/lib/form";
import { FormValues, PublicationFeatures } from "./SimpleProjectMediaPublish";
import {
  MediaProject,
  MediaProjectPublicationResponse,
  ProjectMediaResponse,
} from "../../types";
import {
  Info,
  ArrowUpRight,
  LinkSimpleHorizontal,
  Globe,
  Video,
  File,
  ChatTeardrop,
} from "@phosphor-icons/react";
import styled from "styled-components";
import { DateField } from "@refinedev/antd";
import { ColumnType } from "antd/es/table";
import { InfoCircleOutlined } from "@ant-design/icons";

import { UUID } from "components/UUID";
import { MEDIAPLAYER_URL } from "../../../../constants";
import { addDateTz } from "pages/media/utils";
import { MiniLabel } from "components/MiniLabel";

const { Text } = Typography;
const { TextArea } = Input;
interface DocumentsTableDataItem {
  id: React.Key;
  name?: string;
  created?: string;
  display?: string;
  document_type?: string;
}
interface AssetsTableDataItem {
  id: React.Key;
  name?: string;
  asset_type?: string;
  created?: string;
}

export interface ShareMediaProps {
  project: MediaProject;
  media: ProjectMediaResponse;
  form: FormInstance;
  publication?: MediaProjectPublicationResponse;
  onFinish: (values: FormValues) => void;
  approvePublication: () => void;
  selectedDocumentsIds: string[];
  setSelectedDocumentsIds: (ids: string[]) => void;
  selectedAssetIds: string[];
  setSelectedAssetIds: (ids: string[]) => void;
  assets: AssetsTableDataItem[];
  documents: DocumentsTableDataItem[];
  setDisplayDocument: (displayDocument: string) => void;
  displayDocument?: string;
  expert?: boolean;
  isMutating?: boolean;
}
const ShareMedia: React.FC<ShareMediaProps> = ({
  project,
  media,
  form,
  publication,
  onFinish,
  approvePublication,
  selectedDocumentsIds,
  setSelectedDocumentsIds,
  selectedAssetIds,
  setSelectedAssetIds,
  documents,
  assets,
  setDisplayDocument,
  displayDocument,
  expert,
  isMutating,
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();

  const isEdit = !!publication;
  const link = `${MEDIAPLAYER_URL}/embed/${project?.id}/${publication?.project_publication_id}`;

  const embedCode = `<iframe src="${link}" width="1400" height="800" frameborder="0"></iframe>`;
  const [copyLinkRef, setCopyLinkRef] = useState<HTMLSpanElement | null>(null);
  const [copyCodeRef, setCopyCodeRef] = useState<HTMLSpanElement | null>(null);
  const mediaLink = link.replace("embed", "p");
  // const videoCuepoints = Form.useWatch("video_cuepoints", form);

  const rowSelectionDocuments = {
    selectedRowKeys: selectedDocumentsIds,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: DocumentsTableDataItem[]
    ) => {
      setSelectedDocumentsIds(selectedRowKeys as string[]);
    },
    getCheckboxProps: (record: DocumentsTableDataItem) => ({
      name: record.name,
    }),
  };
  const rowSelectionAssets = {
    selectedRowKeys: selectedAssetIds,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: AssetsTableDataItem[]
    ) => {
      console.debug(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedAssetIds(selectedRowKeys as string[]);
    },
    getCheckboxProps: (record: AssetsTableDataItem) => ({
      name: record.name,
    }),
  };

  const columns: TableColumnsType<DocumentsTableDataItem> = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   render: (text: string) => (
    //     <Typography.Text>
    //       <UUID id={text} />
    //     </Typography.Text>
    //   ),
    // },
    {
      title: t("projects.components.ShareMedia.name"),
      dataIndex: "name",
      render: (text: string, record: DocumentsTableDataItem) => (
        <Tooltip title={record.id}>
          <Typography.Text>{text ?? "-"}</Typography.Text>
        </Tooltip>
      ),
    },
  ];
  const columnsAssets: TableColumnsType<AssetsTableDataItem> = [
    {
      title: t("projects.components.ShareMedia.id"),
      dataIndex: "id",
      render: (text: string) => (
        <Typography.Text>
          <UUID id={text} />
        </Typography.Text>
      ),
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   render: (text: string, record: DocumentsTableDataItem) => (
    //     <Tooltip title={record.id}>
    //       <Typography.Text>{text ?? "-"}</Typography.Text>
    //     </Tooltip>
    //   ),
    // },
    {
      title: t("projects.components.ShareMedia.type"),
      dataIndex: "asset_type",
      render: (text: string) =>
        text ? <Typography.Text code>{text}</Typography.Text> : null,
    },
  ];

  const createdColumn: ColumnType<DocumentsTableDataItem> = {
    title: t("projects.components.ShareMedia.createdAt"),
    dataIndex: "created",
    key: "created",
    sorter: (a: DocumentsTableDataItem, b: DocumentsTableDataItem) =>
      new Date(a.created!).getTime() - new Date(b.created!).getTime(),
    render: (created: string) => (
      <DateField format="LLL" value={addDateTz(created)} />
    ),
  };

  const displayedColumn: TableColumnType<DocumentsTableDataItem> = {
    title: t("projects.components.ShareMedia.displayed"),
    dataIndex: "display",
    render: (_, { name, id }) => (
      <Radio
        checked={displayDocument === (id as string)}
        onChange={() => setDisplayDocument(id as string)}
        disabled={!name?.toLocaleLowerCase().endsWith("pdf")}
      />
    ),
  };

  // useEffect(() => {
  //   if (form && !videoCuepoints) {
  //     form.setFieldValue("chat", false);
  //   }
  // }, [videoCuepoints, form]);

  return (
    <StyledFormWrapper>
      <Form<FormValues> onFinish={onFinish} form={form} layout="vertical">
        <Flex gap={30} vertical>
          <Form.Item
            required
            name="title"
            label={
              <Space>
                <Typography.Text strong>
                  {t("projects.components.ShareMedia.title")}
                </Typography.Text>
                <Tooltip
                  trigger={"click"}
                  title={t("projects.components.ShareMedia.titleOfThe")}
                >
                  <Button
                    type="text"
                    shape="circle"
                    size="small"
                    style={{ opacity: 0.5 }}
                    icon={<InfoCircleOutlined />}
                  />
                </Tooltip>
              </Space>
            }
            style={{ margin: 0 }}
          >
            <Input size="large" />
          </Form.Item>
          {expert && (
            <Form.Item
              required
              name="project_publication_id"
              label={
                <Text strong>{t("projects.components.ShareMedia.id")}</Text>
              }
              style={{ margin: 0 }}
            >
              <Input size="large" readOnly={isEdit} />
            </Form.Item>
          )}

          <Flex vertical gap={8}>
            <Form.Item required name="features" style={{ margin: 0 }}>
              <Segmented<PublicationFeatures>
                block
                options={[
                  {
                    label: (
                      <Flex align="center" vertical style={{ padding: 5 }}>
                        <Space size={"middle"}>
                          <Video size={30} />
                        </Space>
                        <Flex
                          vertical
                          style={{ lineHeight: 1.1, marginTop: -5 }}
                        >
                          <div>{t("projects.components.ShareMedia.video")}</div>
                        </Flex>
                      </Flex>
                    ),
                    value: "video",
                  },
                  {
                    label: (
                      <Flex align="center" vertical style={{ padding: 5 }}>
                        <Space size={"middle"}>
                          <Video size={30} />
                          <File size={30} />
                        </Space>
                        <Flex
                          vertical
                          style={{ lineHeight: 1.1, marginTop: -5 }}
                        >
                          <div>{t("projects.components.ShareMedia.video")}</div>
                          <div>
                            {t("projects.components.ShareMedia.aiDocument")}
                          </div>
                        </Flex>
                      </Flex>
                    ),
                    value: "video_doc",
                  },
                  {
                    label: (
                      <div style={{ padding: 5 }}>
                        <Space size={"middle"}>
                          <Video size={30} />
                          <File size={30} />
                          <ChatTeardrop size={30} />
                        </Space>
                        <Flex
                          vertical
                          style={{
                            lineHeight: 1.1,
                            marginTop: -5,
                          }}
                        >
                          <div>{t("projects.components.ShareMedia.video")}</div>
                          <div>
                            {t("projects.components.ShareMedia.aiDocument")}
                          </div>
                          <div>
                            {t("projects.components.ShareMedia.aiChat")}
                          </div>
                        </Flex>
                      </div>
                    ),
                    value: "video_doc_chat",
                  },
                ]}
              />
            </Form.Item>

            <Flex vertical>
              <Flex
                justify="space-between"
                align="center"
                style={{
                  opacity:
                    form.getFieldValue("features") === "video_doc_chat"
                      ? 1
                      : 0.4,
                }}
              >
                <label htmlFor="chat">
                  <Flex vertical>
                    <Space>
                      <Text strong>
                        {t("projects.components.ShareMedia.displayAiAnswers")}
                      </Text>
                      <Tooltip
                        trigger={"click"}
                        title={t(
                          "projects.components.ShareMedia.useTheLibertify"
                        )}
                      >
                        <Button
                          type="text"
                          shape="circle"
                          style={{ opacity: 0.5 }}
                          icon={<InfoCircleOutlined />}
                        />
                      </Tooltip>
                    </Space>
                  </Flex>
                </label>
                <Form.Item
                  name="enable_chat_answers"
                  valuePropName="checked"
                  style={{ marginBottom: 10 }}
                >
                  <Switch
                    disabled={
                      form.getFieldValue("features") !== "video_doc_chat"
                    }
                  />
                </Form.Item>
              </Flex>
              <Flex
                vertical
                style={{
                  opacity:
                    form.getFieldValue("features") === "video_doc_chat"
                      ? 1
                      : 0.4,
                }}
              >
                <Flex justify="space-between" align="center">
                  <label htmlFor="attachAllDocuments">
                    <Flex vertical>
                      <Space align="center">
                        <Text strong>
                          {t("projects.components.ShareMedia.useAllKnowledge")}
                        </Text>
                        <Tooltip
                          overlayInnerStyle={{ minWidth: 350 }}
                          trigger={"click"}
                          title={t(
                            "projects.components.ShareMedia.aKnowledgeBase"
                          )}
                        >
                          <Button
                            type="text"
                            shape="circle"
                            style={{ opacity: 0.5 }}
                            icon={<InfoCircleOutlined />}
                          />
                        </Tooltip>
                      </Space>
                      <Text type="secondary" style={{ marginTop: -5 }}>
                        {t("projects.components.ShareMedia.aRepublishIs")}
                      </Text>
                    </Flex>
                  </label>
                  <Form.Item
                    name="attachAllDocuments"
                    valuePropName="checked"
                    style={{ marginBottom: 10 }}
                  >
                    <Switch
                      disabled={
                        form.getFieldValue("features") !== "video_doc_chat"
                      }
                    />
                  </Form.Item>
                </Flex>
                {!form.getFieldValue("attachAllDocuments") && (
                  <Table
                    size="small"
                    rowKey="id"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelectionDocuments,
                    }}
                    columns={
                      expert
                        ? [...columns, displayedColumn, createdColumn]
                        : [...columns, createdColumn]
                    }
                    dataSource={documents}
                    pagination={false}
                    style={{ borderRadius: 10, marginTop: 8 }}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>

          {expert && (
            <Flex vertical>
              <Typography.Text strong style={{ margin: 0 }}>
                {t("projects.components.ShareMedia.assets")}
              </Typography.Text>
              <Table
                style={{ margin: 0 }}
                size="small"
                rowKey="id"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelectionAssets,
                }}
                columns={[...columnsAssets, createdColumn]}
                dataSource={assets}
                pagination={false}
              />
            </Flex>
          )}
          {/* not used for now */}
          {/* <Flex justify="space-between" align="center">
        <label htmlFor="public">
          <Flex vertical>
            <Text strong>Public</Text>
            <Text type="secondary" style={{ fontSize: theme.fontSizeSM }}>
              Anyone with the link can view and ask questions
            </Text>
          </Flex>
        </label>
        <Form.Item name="public" valuePropName="checked">
          <Switch disabled />
        </Form.Item>
      </Flex> */}

          {isEdit && (
            <Flex vertical gap={10}>
              <Text strong>
                {t("projects.components.ShareMedia.embedWithIFrame")}
              </Text>
              <TextArea rows={2} readOnly defaultValue={embedCode} />
              <Flex justify="space-between" style={{ marginTop: -10 }}>
                <TextOnlyButton
                  type="text"
                  size="large"
                  onClick={() => {
                    (
                      copyCodeRef?.getElementsByClassName(
                        "ant-typography-copy"
                      )[0] as HTMLAnchorElement
                    ).click();
                  }}
                >
                  <Typography.Text
                    copyable={{ text: embedCode, icon: <></> }}
                    ref={setCopyCodeRef}
                  >
                    {t("projects.components.ShareMedia.copyHtmlCode")}
                  </Typography.Text>
                </TextOnlyButton>
                <TextOnlyButton
                  type="text"
                  size="large"
                  onClick={() =>
                    window.open(
                      "https://libertify.notion.site/Libertify-Media-Player-Integration-9c88b337a40b4f5b8cc621dcf1efd4f5",
                      "noopener,noreferrer"
                    )
                  }
                  icon={
                    <span className="anticon">
                      <Info />
                    </span>
                  }
                >
                  {t("projects.components.ShareMedia.customize")}
                </TextOnlyButton>
              </Flex>
            </Flex>
          )}

          {media.status === "Published" && (
            <Alert
              style={{
                paddingTop: 7,
                paddingBottom: 7,
                alignItems: "center",
              }}
              type="success"
              showIcon
              icon={<Globe />}
              description={t("projects.components.ShareMedia.yourVideoIs")}
            />
          )}
          {media.status === "Preview" && (
            <Alert
              type="warning"
              showIcon
              icon={<Globe />}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                alignItems: "center",
              }}
              description={
                <Flex justify="space-between" align="center">
                  <Flex vertical>
                    <Typography.Text>
                      {t("projects.components.ShareMedia.yourVideoWont")}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {t("projects.components.ShareMedia.yourVideoCurrently")}
                    </Typography.Text>
                  </Flex>

                  <Form.Item
                    name="live"
                    valuePropName="checked"
                    style={{ margin: 0 }}
                  >
                    <Switch
                      style={{
                        boxShadow: `0px 0px 0px 2px ${
                          form.getFieldValue("live")
                            ? theme.colorSuccess
                            : theme.colorBorder
                        }`,
                        background: !form.getFieldValue("live")
                          ? "none"
                          : theme.colorSuccess,
                        transition: "1s",
                      }}
                      checkedChildren={
                        <MiniLabel strong style={{ color: "#fff" }}>
                          {t("projects.components.ShareMedia.goLive")}
                        </MiniLabel>
                      }
                      unCheckedChildren={
                        <MiniLabel
                          strong
                          style={{ color: theme.colorTextSecondary }}
                        >
                          {t("projects.components.ShareMedia.goLive")}
                        </MiniLabel>
                      }
                    />
                  </Form.Item>
                </Flex>
              }
            />
          )}
        </Flex>

        <Flex
          justify="space-between"
          align="flex-end"
          style={{ marginTop: 30 }}
        >
          {isEdit && (
            <Space>
              <Button
                size="large"
                shape="round"
                icon={
                  <span className="anticon">
                    <LinkSimpleHorizontal />
                  </span>
                }
                onClick={() => {
                  (
                    copyLinkRef?.getElementsByClassName(
                      "ant-typography-copy"
                    )[0] as HTMLAnchorElement
                  ).click();
                }}
              >
                <Typography.Text
                  copyable={{ text: mediaLink, icon: <></> }}
                  ref={setCopyLinkRef}
                >
                  {t("projects.components.ShareMedia.copyLink")}
                </Typography.Text>
              </Button>
              <Tooltip
                title={
                  <span>
                    {t("projects.components.ShareMedia.id")}
                    {publication.project_publication_id}
                  </span>
                }
                placement="bottom"
                arrow={false}
              >
                <TextOnlyButton
                  type="text"
                  size="large"
                  shape="round"
                  icon={
                    <span className="anticon">
                      <ArrowUpRight />
                    </span>
                  }
                  onClick={() => window.open(mediaLink, "noopener,noreferrer")}
                >
                  {t("projects.components.ShareMedia.openLink")}
                </TextOnlyButton>
              </Tooltip>
            </Space>
          )}
          <Space style={{ marginLeft: "auto" }}>
            <Button
              icon={
                <span className="anticon">
                  <Globe />
                </span>
              }
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              disabled={isMutating}
              loading={isMutating}
            >
              {(media.status === "Preview" &&
                form.getFieldValue("live") === true) ||
              !isEdit
                ? "Publish"
                : "Republish"}
              {media.status === "Preview" &&
                !form.getFieldValue("live") &&
                " Preview"}
            </Button>
          </Space>
        </Flex>
      </Form>
    </StyledFormWrapper>
  );
};

export default ShareMedia;
const TextOnlyButton = styled(Button)`
  padding: 0;
  opacity: 0.5;
  &:hover {
    background: transparent !important;
    opacity: 1;
  }
`;
const StyledFormWrapper = styled.div<{
  theme: GlobalToken;
}>`
  .ant-segmented {
    background: ${({ theme }) => theme.colorBgContainer};
    border: 1px solid ${({ theme }) => theme.colorBorder};
    padding: 0;
  }
  .ant-segmented-item {
    padding: 2px 0;
    color: ${({ theme }) => theme.colorTextSecondary};
    border: 1px solid transparent;
  }
  .ant-segmented-thumb {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    // transition: color 0.2s;
    color: ${({ theme }) => theme.colorText};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
`;
