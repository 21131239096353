import { GlobalToken, Select, SelectProps } from "antd";
import { AppContext } from "appContext";
import { SELECTED_ORG_ID_KEY } from "authProvider";
import { useAntTheme } from "hooks/useAntTheme";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
export const OrganizationSwitch = () => {
  const {
    state: { organizations, organization },
    dispatch,
  } = useContext(AppContext);
  const { theme } = useAntTheme();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const storedOrganizationId = localStorage.getItem(SELECTED_ORG_ID_KEY);
    if (storedOrganizationId && organizations) {
      const restoredOrg = organizations.find(
        ({ id }) => id === storedOrganizationId
      );
      if (restoredOrg) {
        // check the value is valid
        dispatch({
          type: "setOrganization",
          payload: restoredOrg,
        });
      }
    } else {
      dispatch({ type: "setOrganization", payload: organizations?.[0] });
    }
    setTimeout(() => setReady(true), 1000); // hack to avoid picker value flickering on page load
  }, [organizations]);

  const changeOrganization = (value: string) => {
    dispatch({
      type: "setOrganization",
      payload: organizations.find(({ id }) => id === value),
    });
    localStorage.setItem(SELECTED_ORG_ID_KEY, value);
  };

  return (
    <StyledSelect
      data-testid="OrganizationSwitch"
      loading={!organizations || !ready}
      theme={theme}
      size="large"
      disabled={organizations?.length < 2}
      onChange={changeOrganization}
      value={ready && organization?.id}
      options={organizations?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))}
      style={{ width: "100%" }}
      virtual={false} // disable for automated pass to find test org
    />
  );
};

const StyledSelect = styled(Select)<SelectProps & { theme: GlobalToken }>`
  &:not(.ant-select-focused) {
    .ant-select-selector {
      color: ${({ theme }) => theme.colorPrimary} !important;
    }
  }
  .ant-select-selector {
    border-width: 2px !important;
    box-shadow: 0 !important;
  }
`;
