import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types/index";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useOne, useGo, Authenticated } from "@refinedev/core";
import {
  Button,
  Empty,
  Flex,
  Form,
  Input,
  List,
  Popconfirm,
  Typography,
} from "antd";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { useOrganization } from "hooks/useOrganization";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CallToActions, MediaAsset } from "../types";
import { nanoid } from "nanoid";
import { CaretDown, DotsSix, Trash } from "@phosphor-icons/react";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import {
  CalendarOutlined,
  DownloadOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Edit } from "@refinedev/antd";
import { languages } from "data/languages";
import { useAntTheme } from "hooks/useAntTheme";

type Item = CallToActions["actions"][number];

type DraggableActionProps = {
  item: Item;
  onClose: (id: string) => void;
  onChange: (id: string, action: Item) => void;
};

const SUPPORTED_LOCALES = languages.map((x) => x.value);

const DraggableAction: React.FC<DraggableActionProps> = (props) => {
  const t = useTranslate();
  const { item, ...rest } = props;
  const hasUrl = item.action.type === "ExternalLink";
  const [form] = Form.useForm<{
    url: string;
  }>();
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: item.id,
    });
  // all possible locales
  const locales = [
    ...new Set([
      ...Object.keys(item.ui_definition.labels),
      ...SUPPORTED_LOCALES,
    ]),
  ];
  const { theme } = useAntTheme();

  const commonStyle = {
    cursor: "move",
    transition: "unset", // Prevent element from shaking after drag
  };

  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? "unset" : transition, // Improve performance/visual effect when dragging
        borderColor: "yellow",
      }
    : commonStyle;

  // FieldData not exposed by antd ???
  const handleFieldsUpdated = (fields: any[], allFields: any[]) => {
    const url = allFields.find((x) => x?.name?.[0] === "url")?.value;
    const newItem = {
      ...item,
      action: {
        ...item.action,
        url,
      },
      ui_definition: {
        ...item.ui_definition,
        labels: {
          ...item.ui_definition.labels,
          ...Object.fromEntries(
            locales.map((locale) => [
              locale,
              allFields.find((x) => x?.name?.[0] === `labels.${locale}`)?.value,
            ])
          ),
        },
      },
    };
    // console.debug({ allFields, newItem });
    // bubble up
    props.onChange(item.id, newItem);
  };

  useEffect(() => {
    form.setFieldValue("url", item.action.url);
    Object.entries(item.ui_definition.labels).forEach(([key, value]) => {
      form.setFieldValue(`labels.${key}`, value);
    });
  }, [item.id]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <List.Item style={style} ref={setNodeRef}>
      <Flex justify="space-between" style={{ width: "100%" }}>
        <Space>
          <Flex vertical gap={10}>
            <Flex vertical gap={0}>
              <Typography.Text
                type="secondary"
                strong
                style={{ color: theme.colorPrimary }}
              >
                {item.action.type}
              </Typography.Text>
              <Button size="large" shape="round">
                {item.ui_definition.labels["en-US"]}
              </Button>
            </Flex>
            <Form
              {...formItemLayout}
              onFieldsChange={handleFieldsUpdated}
              form={form}
              style={{ minWidth: 400 }}
            >
              {hasUrl && (
                <Form.Item label={t("media.ctas.create.url")} name="url">
                  <Input type="url" />
                </Form.Item>
              )}

              {locales.map((locale) => {
                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label={`Label ${t("nativeLanguageNames." + languages.find((x) => x.value === locale)?.value.split("-")[0])}`}
                    name={"labels." + locale}
                    key={locale}
                  >
                    <Input type="text" size="small" />
                  </Form.Item>
                );
              })}
            </Form>
          </Flex>
        </Space>
        <Space align="center">
          <Popconfirm
            title={t("media.ctas.create.areYouSure")}
            onConfirm={() => {
              props.onClose(item.id);
            }}
          >
            <Button danger icon={<Trash size={24} />} type="text" />
          </Popconfirm>
          <DotsSix {...listeners} size={24} />
        </Space>
      </Flex>
    </List.Item>
  );
};

export const CallToActionsFormPage = () => {
  const t = useTranslate();
  const sensors = useSensors(useSensor(PointerSensor));
  const [items, setItems] = useState<CallToActions["actions"]>([]);
  const { id } = useParams();
  const [form] = Form.useForm<{
    description: string;
  }>();
  const description = Form.useWatch("description", form);

  const [loading, setLoading] = useState(false);
  const go = useGo();

  const { data, isSuccess } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id,
    queryOptions: {
      enabled: Boolean(id),
    },
  });
  const { organization } = useOrganization({});
  const { saveOrganizationAsset } = useMediaAssetsStorage({
    organizationId: organization?.id,
  });
  const {
    organizationAssets: { data: ctasData },
  } = useMediaAssetsStorage({
    organizationId: organization?.id,
    assetType: "CallToActions",
    enabled: true,
  });
  const ready = id ? isSuccess : true;
  const hasExistingCtas = ctasData?.data && ctasData?.data.length > 0;
  const initialDescription = hasExistingCtas ? "" : "Default";

  const addItems: MenuProps["items"] = [
    {
      label: t("media.ctas.create.externalLink"),
      key: "ExternalLink",
      icon: <LinkOutlined />,
      disabled: items.some((x) => x.action.type === "ExternalLink"),
    },
    {
      label: t("media.ctas.create.calendarAppointment"),
      key: "CalendarAppointment",
      icon: <CalendarOutlined />,
      disabled: items.some((x) => x.action.type === "CalendarAppointment"),
    },
    {
      label: t("media.ctas.create.documentDownload"),
      key: "DocumentDownload",
      icon: <DownloadOutlined />,
      disabled: items.some((x) => x.action.type === "DocumentDownload"),
    },
    {
      label: t("media.ctas.create.email"),
      key: "Email",
      icon: <MailOutlined />,
      disabled: items.some((x) => x.action.type === "Email"),
    },
    {
      label: t("media.ctas.create.phone"),
      key: "Phone",
      icon: <PhoneOutlined />,
      disabled: items.some((x) => x.action.type === "Phone"),
    },
  ];

  //   const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //     message.info("Click on left button.");
  //     console.log("click left button", e);
  //   };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    handleAddItem({ type: e.key });
  };
  const menuProps = {
    items: addItems,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    (async () => {
      if (!data) return;
      const response = await fetch(data.data.path);
      const json = await response.json();
      setItems(json.actions ?? []);
      form.setFieldValue("description", data.data.description ?? "");
    })();
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      setItems((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);

        return arrayMove(data, oldIndex, newIndex);
      });
    }
  };
  const handleClose = (removedId: string) => {
    console.debug({ removedId });
    setItems((oldItems) => oldItems.filter((item) => item.id !== removedId));
  };
  const handleChange = (id: string, action: Item) => {
    setItems((oldItems) =>
      oldItems.map((item) => (item.id === id ? action : item))
    );
  };

  const handleAddItem = ({
    type,
  }: {
    type: string; //CallToActions["actions"][number]["action"]["type"];
  }) => {
    setItems((oldItems) => [
      ...oldItems,
      {
        id: nanoid(),
        // action: { type: "ExternalLink", url: "" },
        action: { type },
        ui_definition: { labels: { "en-US": "Placeholder" }, type: "Button" },
      },
    ]);
  };

  const handleSave = async () => {
    setLoading(true);

    const assetId = await saveOrganizationAsset(
      "CallToActions",
      { actions: items },
      id,
      {
        category: "MediaMeta",
        description,
      }
    );

    setLoading(false);

    if (!id) {
      go({
        to: {
          resource: "media_ctas",
          id: assetId,
          action: "edit",
        },
      });
    }
  };

  return (
    <Authenticated key="ctas_create">
      <Edit
        isLoading={!ready}
        headerButtons={() => null}
        saveButtonProps={{
          onClick: handleSave,
          shape: "round",
          icon: null,
          loading: loading,
          size: "large",
          disabled: loading,
        }}
      >
        <Flex gap={0} vertical>
          <Flex justify="space-between">
            <div>
              <Form
                style={{ width: 400 }}
                form={form}
                initialValues={{ description: initialDescription }}
              >
                <Form.Item
                  label=""
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: t("media.ctas.create.pleaseChooseA"),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder={t("media.ctas.create.name")}
                    maxLength={20}
                    size="large"
                  />
                </Form.Item>
              </Form>
            </div>
            <div>
              <Dropdown menu={menuProps}>
                <Button shape="round" size="large">
                  {t("media.ctas.create.addA")}
                </Button>
              </Dropdown>
            </div>
          </Flex>

          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={items}
              strategy={horizontalListSortingStrategy}
            >
              <List
                locale={{
                  emptyText: (
                    <Empty
                      description={t("media.ctas.create.emptyHelp")}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
                dataSource={items}
                renderItem={(item) => (
                  <DraggableAction
                    item={item}
                    key={item.id}
                    onClose={handleClose}
                    onChange={handleChange}
                  />
                )}
              ></List>
            </SortableContext>
          </DndContext>
        </Flex>
      </Edit>
    </Authenticated>
  );
};
