import { useTranslate } from "@refinedev/core";
import {
  Badge,
  Button,
  Flex,
  Popconfirm,
  Progress,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  notification,
} from "antd";
import {
  MediaProjectResponse,
  TDetailedScript,
  TDetailedSection,
  TDetailedStoryboard,
  TKeyInsight,
  DisplayMetrics,
  TStoryboard,
  TScript,
  StoryboardAsset,
  SectionUpdate,
  DocumentExtract,
} from "pages/media/types";
import { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  Over,
  Active,
} from "@dnd-kit/core";
import {
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { nanoid } from "nanoid";

import { useStoryboardWs } from "hooks/useStoryboardWs";
import { useAntTheme } from "hooks/useAntTheme";
import { countWords } from "pages/media/utils";
import { sortSourcesByPageNumber } from "./editor/helpers";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { StoryboardMessage, WebSocketMessage } from "../chat/types";
import { StoryboardMetrics } from "./editor/StoryboardMetrics";
import { DisplayMode, TCallbackAssociation } from "./editor/types";
import { Section } from "./editor/Section";
import { Hourglass } from "@phosphor-icons/react";
import { MiniLabel } from "components/MiniLabel";
import { useCustomMutation, useGo, useInvalidate } from "@refinedev/core";
import { SPEECH_WORDS_PER_MINUTE } from "pages/media/constants";
import { Present } from "./editor/Present";
import { useStoryboard } from "./context/StoryboardContext";

const generateId = (target: "section" | "key_insights") => {
  return target + "-" + nanoid();
};

const storyboardDetailedToStoryboard = (storyboard: TDetailedStoryboard) => {
  console.debug("storyboardDetailedToStoryboard", storyboard);
  const result = {
    title: storyboard.title,
    sections: storyboard.sections.map((section) => ({
      id: section.id,
      topic: section.topic,
      script: section.script
        ? // && section.script.content.length > 0
          { content: section.script.content }
        : null, // backend doesn't like empty
    })),
  };
  return result;
};

const addIdsToSectionKeyInsight = (
  keyInsights: TKeyInsight[]
): TKeyInsight[] => {
  return (
    keyInsights?.map((key_insight) => ({
      ...key_insight,
      id: generateId("key_insights"),
    })) ?? []
  );
};
const storyboardToStoryboardDetailed = (
  storyboardData: TStoryboard
): TDetailedStoryboard => {
  // console.debug("storyboardData", storyboardData);
  const newBody = storyboardData.sections.map((section: any) => {
    const key_insights = addIdsToSectionKeyInsight(section.key_insights);

    const scripts =
      section.scripts?.map((script: any) => ({
        ...script,
        selected: false,
      })) ??
      (section.script && section.script.content.length > 0
        ? [
            {
              ...section.script,
              selected: true,
            },
          ]
        : []); // when restoring a SB, copy the selected script in scripts

    return {
      ...section,
      key_insights,
      scripts,
    };
  });

  return {
    ...storyboardData,
    sections: newBody,
  };
};

export const StoryboardEditor = ({
  project,
  storyboardId,
  displayMode,
}: {
  project?: MediaProjectResponse;
  storyboardId?: string;
  displayMode: DisplayMode;
}) => {
  const t = useTranslate();
  const go = useGo();
  const { storyboard, setStoryboard } = useStoryboard();
  // const [displayMode, setDisplayMode] = useState<DisplayMode>("compact");
  const [keyInsightsId, setKeyInsightId] = useState<string[]>([]);
  const [sectionsId, setSectionsId] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [metrics, setMetrics] = useState<DisplayMetrics>();
  const [callbacks, setCallbacks] = useState<TCallbackAssociation[]>([]);
  const invalidate = useInvalidate();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [editing, setEditing] = useState(false);
  const { theme } = useAntTheme();

  const currentStoryboard = project?.storyboards.find(
    (x) => x.asset_id === storyboardId
  );
  const { mutateAsync: approve, isLoading: isStoryboardApprovalLoading } =
    useCustomMutation({});
  //  when getting very fast updates lastResponse state seemed to lose some updates
  const onMessage = (lastResponse: WebSocketMessage) => {
    // console.debug({ lastResponse });

    // const call = callbacks!.find(
    //   (item) => item.query_id === lastResponse?.query_id
    // );
    // console.debug("lastResponse changed", { lastResponse, call, callbacks });
    // deprecated StructureDocument
    // if (
    //   lastResponse &&
    //   "storyboard" in lastResponse &&
    //   lastResponse.storyboard
    // ) {
    //   setStoryboard(storyboardToStoryboardDetailed(lastResponse.storyboard));
    // }
    // if (lastResponse && "structure" in lastResponse && lastResponse.structure) {
    //   setStoryboard(storyboardToStoryboardDetailed(lastResponse.structure));
    // }

    if (
      (lastResponse?.action === "CreateSection" ||
        lastResponse?.action === "RegenerateSection") &&
      "section" in lastResponse &&
      lastResponse.section
    ) {
      const { section_id: id, ...newSection } = lastResponse.section;
      handleChangeSection({
        // update local after conversion
        ...newSection,
        id,
        key_insights: addIdsToSectionKeyInsight(
          lastResponse.section?.key_insights ?? []
        ),
      } as unknown as TDetailedSection); // FIXME
    }
    if (
      lastResponse?.action === "GenerateScript" &&
      lastResponse &&
      "scripts" in lastResponse &&
      lastResponse.scripts &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      const call = callbacks!.find(
        (item) => item.query_id === lastResponse.query_id
      );
      call?.callback(lastResponse.scripts);
    }
    if (
      lastResponse?.action === "ImproveScript" &&
      lastResponse &&
      "message" in lastResponse &&
      "script" in lastResponse &&
      lastResponse.script &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      const call = callbacks!.find(
        (item) => item.query_id === lastResponse.query_id
      );
      const { sectionId, scriptIndex } = call?.callback([]) as unknown as {
        sectionId: string;
        scriptIndex: number;
      };
      setStoryboard((old) => ({
        ...old!,
        sections: old!.sections?.map((section) => {
          if (section.id === sectionId) {
            const isSelectedScript = section.scripts?.[scriptIndex].selected;
            const content = lastResponse.script;
            return {
              ...section,
              ...(isSelectedScript
                ? { script: { content, selected: true } }
                : {}),
              sources: processSources(section.sources),
              scripts:
                section.scripts?.map((script, index) => {
                  if (index === scriptIndex)
                    return {
                      // merge values
                      ...script,
                      content,
                      revisions: [
                        {
                          content: content as string,
                          prompt: lastResponse.message,
                        },
                        ...(script.revisions ?? [
                          { content: script.content, prompt: "" },
                        ]),
                      ],
                    };
                  else {
                    return script;
                  }
                }) ?? [],
            };
          } else {
            return section;
          }
        }),
      }));
    }

    if (
      lastResponse?.action === "ComputeMetrics" &&
      lastResponse &&
      "metrics" in lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setMetrics(lastResponse.metrics as DisplayMetrics);
    }
    if (
      lastResponse?.action === "TextToSpeech" &&
      lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setStoryboard((old) => {
        if (!old) return;
        const sections = old.sections.map((section) => {
          if (section.id !== lastResponse.section_id) {
            return section;
          }
          return {
            ...section,
            speech: lastResponse.speech ?? "",
          };
        });
        return {
          ...old!,
          sections,
        };
      });
    }
    if (
      lastResponse?.action === "UpdateSection" &&
      lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      requestRetrieveSectionData(lastResponse.section_id);
    }
    if (
      lastResponse?.action === "RetrieveSectionData" &&
      lastResponse &&
      "data" in lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setStoryboard((old) => {
        if (!old) return;
        const sections = old.sections.map((section) => {
          if (section.id !== lastResponse.section_id) {
            return section;
          }
          return {
            ...section,
            key_insights:
              addIdsToSectionKeyInsight(
                lastResponse.data?.key_insights ?? []
              ) ?? [],
            detailed_summary: lastResponse.data?.detailed_summary ?? "",
            sources: processSources(lastResponse.data?.sources ?? []),
            speech: null,
          };
        });

        return {
          ...old!,
          sections,
        };
      });
    }
  }; //, [messageHistory, lastResponse, callbacks]);

  const {
    init,
    requestGenerateScript,
    requestRegenerateSection,
    requestCreateSection,
    requestUpdateSection,
    requestImproveScript,
    requestComputeMetrics,
    requestRetrieveSectionData,
    requestTextToSpeech,
    getConnectionStatus,
    lastMessage,
    isConnected,
    isBusy,
  } = useStoryboardWs({
    organizationId: project!.organization_id,
    documentIds: project!.documents.map((doc) => doc.id),
    onMessage: onMessage,
  });

  const {
    filterAssets,
    saveProjectAsset,
    projectAssets,
    isMutateLoading,
    // mediaAssets,
    isLoadingProjectAssets,
    fetchJsonAsset,
  } = useMediaAssetsStorage({
    organizationId: project!.organization_id,
    projectId: project?.id,
    // mediaId: project?.medias[0].id,
    enabled: Boolean(project?.id),
  });

  const storyboardAsset = projectAssets
    ? filterAssets<StoryboardAsset>(projectAssets as StoryboardAsset[], {
        asset_type: "Storyboard",
        id: storyboardId,
      })?.[0]
    : null;
  console.debug({ projectAssets, storyboardId, storyboardAsset });

  // const sync = () => {
  //   // save in db as asset
  // };

  const generateSectionScripts = (
    sectionId: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => {
    if (!storyboardAsset?.id) return; // todo

    setCallbacks((old) => {
      return [
        ...old,
        {
          query_id: requestGenerateScript(
            storyboardDetailedToStoryboard(stripId()!),
            sectionId
          ),
          callback,
        },
      ];
    });
  };

  const generateSection = (topic: string, sectionId: string) => {
    if (sectionId.startsWith("section")) {
      // local id // TODO improve logic
      requestCreateSection(topic, storyboardDetailedToStoryboard(stripId()!));
    } else {
      requestRegenerateSection(
        sectionId,
        topic,
        storyboardDetailedToStoryboard(stripId()!)
      );
    }
  };

  const handleImproveScript = (
    sectionId: string,
    script: TScript,
    message: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => {
    setCallbacks((old) => {
      return [
        ...old,
        {
          query_id: requestImproveScript(sectionId, script, message),
          callback,
        },
      ];
    });
  };

  const computeMetrics = () => {
    requestComputeMetrics(storyboardDetailedToStoryboard(stripId()!));
  };

  const extractSectionsIds = () => {
    if (!storyboard) {
      return [];
    }
    return storyboard.sections.flatMap((elt) => (elt ? elt.id : []));
  };

  // const extractIds = (type: "scripts" | "key_insights"): string[] => {
  const extractIds = (type: "key_insights"): string[] => {
    if (!storyboard) {
      return [];
    }

    const scriptIds = storyboard.sections.flatMap(
      (section) => section[type]?.flatMap((elt) => (elt ? elt.id : [])) ?? []
    );

    return scriptIds;
  };

  const handleChangeSection = (newSection: TDetailedSection) => {
    if (!storyboard) {
      return;
    }

    // delete use case
    if (
      newSection.detailed_summary === "" &&
      newSection.scripts?.length === 0 &&
      newSection.key_insights.length === 0 &&
      newSection.topic === ""
    ) {
      setStoryboard((old) => ({
        ...old!,
        sections: old!.sections.filter((section: TDetailedSection) => {
          return section.id !== newSection.id;
        }),
      }));
      return;
    }

    console.debug("update section", { newSection });
    setStoryboard((value) => ({
      ...value!,
      sections: value!.sections.map((section: TDetailedSection, index) => {
        if (section.id === newSection.id || section.topic === newSection.topic)
          return newSection;
        else return section;
      }),
    }));
    const oldSection = storyboard.sections.find(
      (section) => section.id === newSection.id
    );
    const newValues: SectionUpdate = {
      key_insights: newSection.key_insights,
    };
    if (oldSection?.script?.content !== newSection?.script?.content) {
      newValues.script = newSection?.script?.content;
    }
    if (!newSection.id.startsWith("section")) {
      // sync server generated sections
      requestUpdateSection(newSection.id, newValues);
    }
  };

  const stripId = () => {
    if (!storyboard) {
      return;
    }
    const newStoryboard: any = {
      ...storyboard,
      sections: [
        ...storyboard.sections.map((section) => {
          // const { id: _, ...newSection } = section;
          return {
            ...section,
            key_insights: [
              ...(section.key_insights || []).map((key_insight) => {
                const { id: _, ...newKeyInsight } = key_insight;
                return newKeyInsight;
              }),
            ],
          };
        }),
      ],
    };
    return newStoryboard;
  };

  const switchPlaces = (
    current: Array<any>,
    oldIndex: number,
    newIndex: number
  ) => {
    const amputated = [
      ...current!.slice(0, oldIndex),
      ...current!.slice(oldIndex! + 1, current!.length),
    ];

    const final = [
      ...amputated.slice(0, newIndex),
      current![oldIndex!],
      ...amputated.slice(newIndex, current!.length),
    ];

    return final;
  };

  const moveSection = (
    active: Active,
    over: Over | null
  ): TDetailedSection[] => {
    if (!storyboard) {
      return [];
    }
    const current = storyboard.sections;
    const oldIndex = current?.findIndex((elt) => elt?.id === active.id);
    const newIndex = current?.findIndex((elt) => elt?.id === over?.id);

    const final = switchPlaces(current!, oldIndex!, newIndex!);

    return final;
  };

  const objectMove = (
    type: "key_insights" | "scripts",
    active: Active,
    over: Over | null
  ) => {
    if (!storyboard) {
      return { parent: {}, final: {} };
    }
    const parent = storyboard.sections.find((section) => {
      return section.key_insights?.find((elt: TKeyInsight) => {
        return elt?.id === active.id;
      });
      // ||
      // section.scripts?.find((elt: TDetailedScript) => {
      //   return elt?.id === active.id;
      // })
    });

    const current = parent?.key_insights;
    const oldIndex = current?.findIndex((elt) => elt?.id === active.id);
    const newIndex = current?.findIndex((elt) => elt?.id === over?.id);

    const final = switchPlaces(current!, oldIndex!, newIndex!);

    // console.debug({ current, final });
    return { parent, final };
  };

  const handleDragEnd = (event: DragEndEvent) => {
    // console.debug("drag detected", event);
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeElementType = (
        active.data.current as {
          type: "key_insights" | "section";
        }
      )?.type; // sent as custom data
      if (activeElementType === "key_insights") {
        const { parent, final } = objectMove("key_insights", active, over);
        setStoryboard((old) => ({
          ...old!,
          sections: old!.sections.map((section) => {
            if (section.id === (parent as TKeyInsight).id)
              return {
                ...section,
                key_insights: final as TKeyInsight[],
              };
            else return section;
          }),
        }));
        setKeyInsightId((items) => {
          const oldIndex = items.findIndex((id) => id === active.id);
          const newIndex = items.findIndex((id) => id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
        // sync backend with frontend changes
        if (parent && "id" in parent) {
          requestUpdateSection(parent.id, {
            key_insights: final as TKeyInsight[],
          });
        }
        // @deprecated
        // else if ((event.active.id as string).indexOf("scr") > -1) {
        //   const { parent, final } = objectMove("scripts", active, over);
        //   setStoryboard((old) => {
        //     return {
        //       ...old!,
        //       sections: old!.sections.map((section) => {
        //         if (section.id === (parent as TDetailedSection).id)
        //           return {
        //             ...section,
        //             scripts: final as TDetailedScript[],
        //           };
        //         else return section;
        //       }),
        //     };
        //   });
      } else if (activeElementType === "section") {
        // console.debug("moving section");
        const final = moveSection(active, over);
        setStoryboard((old) => {
          return {
            ...old!,
            sections: final,
          };
        });
        setSectionsId((items) => {
          const oldIndex = items.findIndex((id) => id === active.id);
          const newIndex = items.findIndex((id) => id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  };

  useEffect(() => {
    if (project && storyboardAsset) {
      init({ projectId: project.id, storyboardId: storyboardAsset.id });
    }
  }, [storyboardAsset?.id, project?.id]);

  const loadStoryboard = async () => {
    try {
      if (storyboardAsset?.path) {
        const json = await fetchJsonAsset(storyboardAsset.path);
        json.sections.map((section: TDetailedSection) => {
          requestRetrieveSectionData(section.id);
        });
        setStoryboard(storyboardToStoryboardDetailed(json));
      }
    } catch (error) {
      console.error("Issue while loading JSON:", error);
    }
  };

  useEffect(() => {
    if (storyboardAsset?.path) {
      loadStoryboard();
    }
  }, [storyboardAsset?.path]);

  useEffect(() => {
    // add necessary ids for the ui
    // console.debug({storyboard});
    setKeyInsightId(extractIds("key_insights"));
    setSectionsId(extractSectionsIds());
  }, [storyboard]);

  // useEffect(() => {
  //   if (isConnected) {
  //     requestStructureDocument();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isConnected]);

  // only consider final scripts
  /****
   * CAN BE UPDATED WITH section.script !== undefined
   */
  const sectionsWithSelectedScript = storyboard?.sections
    .filter((x) => x.scripts?.some((x) => x.selected))
    .flatMap((x) => x.scripts?.filter((x) => x.selected));

  const scriptsWordsCount =
    sectionsWithSelectedScript
      ?.map((x) => countWords(x?.content ?? ""))
      .reduce((acc, count) => acc + count, 0) ?? 0;
  // const estimateDuration = scriptsWordsCount / WORDS_PER_MINUTE; // in min.

  const readingTime = (60000 * scriptsWordsCount) / SPEECH_WORDS_PER_MINUTE;
  const minutes = Math.floor(readingTime / 60000);
  const seconds = +((readingTime % 60000) / 1000).toFixed(0);
  const readingTimePretty =
    (minutes > 0 ? minutes + "min" : "") +
    (seconds < 10 ? "0" : "") +
    seconds +
    "s";

  const completionPercent =
    ((sectionsWithSelectedScript?.length ?? 0) * 100) /
    (storyboard?.sections?.length ?? 0);

  const finalScript = sectionsWithSelectedScript?.reduce(
    (acc, x) => acc.concat(x?.content),
    ""
  );

  useEffect(() => {
    // recompute metrics when then final script changes
    if (completionPercent === 100) {
      computeMetrics();
    }
  }, [finalScript, completionPercent]);

  const canSave = Boolean(storyboard);

  const handleSave = async () => {
    if (!canSave || !project?.documents?.[0].id) {
      return;
    }

    try {
      // persist in db for later sessions
      await saveProjectAsset(
        "Storyboard",
        storyboardDetailedToStoryboard(stripId()),
        storyboardAsset?.id,
        {
          document_id: project?.documents?.[0].id,
        }
      );

      // Show success notification
      api.success({
        message: t("components.storyboard.StoryboardEditor.saveSuccessful"),
        description: t(
          "components.storyboard.StoryboardEditor.changesHaveBeen"
        ),
        placement: "bottomRight",
      });
    } catch (error) {
      // Show error notification
      api.error({
        message: t("components.storyboard.StoryboardEditor.saveFailed"),
        description: t("components.storyboard.StoryboardEditor.failedToSave"),
        placement: "bottomRight",
      });
    }
  };

  const handleStoryboardApproval = async () => {
    try {
      await approve({
        url: `/media/projects/${project?.id}/storyboards/${currentStoryboard!.language}/approve`,
        method: "post",
        values: {},
      });
      await invalidate({
        resource: "media_projects",
        id: project?.id,
        invalidates: ["detail"],
      });
      go({
        to: {
          resource: "media_projects",
          action: "show",
          id: project?.id!,
        },
      });
    } catch (e) {}
  };

  const Status = (
    <Space size={"small"}>
      <MiniLabel>
        {t("components.storyboard.StoryboardEditor.status")}
      </MiniLabel>
      <div style={{ position: "relative", marginLeft: 4 }}>
        <LoadingOutlined
          spin
          style={{
            fontSize: 24,
            opacity: isBusy || getConnectionStatus() === "Connecting" ? 1 : 0,
            position: "absolute",
            left: -9,
            top: -4,
            color: isConnected ? "green" : "red",
          }}
        />
        <Tooltip
          title={
            <>
              <div>{getConnectionStatus()}</div>
              {lastMessage?.is_final === false && (
                <div>
                  {t("components.storyboard.StoryboardEditor.loading")}
                  {lastMessage.action}{" "}
                  {(lastMessage as StoryboardMessage).topic}
                </div>
              )}
            </>
          }
        >
          <Badge dot color={isConnected ? "green" : "red"} />
        </Tooltip>
      </div>
    </Space>
  );

  const Controls = (
    <Flex
      align="center"
      justify="space-between"
      style={{
        padding: 10,
        paddingLeft: 20,
        marginLeft: -15,
        marginRight: 0,
        marginBottom: 30,
      }}
    >
      <Space>
        {/* {storyboard === undefined ? (
          <Button
            loading={isLoadingProjectAssets}
            disabled={isBusy || !isConnected}
            icon={<BuildOutlined />}
            size="large"
            shape="round"
            type={"primary"}
            onClick={initStoryboard}
          >
            Start
          </Button>
        ) : (
          <Popconfirm title="Are you sure ?" onConfirm={initStoryboard}>
            <Button
              loading={isLoadingProjectAssets}
              disabled={isBusy || !isConnected}
              icon={<BuildOutlined />}
              size="large"
              shape="round"
            >
              Reset
            </Button>
          </Popconfirm>
        )} */}
        {canSave && (
          <>
            {/* TODO: Perhaps edit this if the storyboard is editable after the
         approval? */}
            <Popconfirm
              title={t(
                "components.storyboard.StoryboardEditor.storyboardApproval"
              )}
              description={t(
                "components.storyboard.StoryboardEditor.areYouSure"
              )}
              onConfirm={handleStoryboardApproval}
            >
              <Button
                icon={<CheckOutlined />}
                shape="round"
                size="middle"
                loading={isStoryboardApprovalLoading}
                disabled={isStoryboardApprovalLoading}
              >
                {t("components.storyboard.StoryboardEditor.approve")}
              </Button>
            </Popconfirm>

            <Button
              type="primary"
              size="middle"
              shape="round"
              onClick={handleSave}
              loading={isMutateLoading || isLoadingProjectAssets}
              disabled={isMutateLoading}
            >
              {t("components.storyboard.StoryboardEditor.saveChanges")}
            </Button>
          </>
        )}
      </Space>
      {Status}
    </Flex>
  );

  const Stats = (
    <Flex
      gap={10}
      align="center"
      style={{
        height: "fit-content",
        // marginBottom: 20,
        borderBottom: "1px solid",
        borderColor: theme.colorBorderSecondary,
        padding: 10,
        paddingLeft: 20,
        marginLeft: -15,
        marginRight: -15,

        flexWrap: "wrap",
      }}
      justify="space-between"
    >
      <Space align="center" size={"large"}>
        <Typography.Text style={{ whiteSpace: "nowrap" }}>
          {scriptsWordsCount}{" "}
          {t("components.storyboard.StoryboardEditor.words")}
        </Typography.Text>
        <Typography.Text
          style={{
            display: "inline-flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Hourglass size={18} /> {readingTimePretty}
        </Typography.Text>
        <Progress
          size="small"
          steps={storyboard?.sections.length}
          percent={Math.floor(completionPercent)}
        />
      </Space>
      <Space>
        {metrics && <StoryboardMetrics metrics={metrics} />}
        <Tooltip title={t("components.storyboard.StoryboardEditor.metrics")}>
          <Button
            type="text"
            size="small"
            shape="circle"
            icon={<SyncOutlined />}
            onClick={computeMetrics}
            loading={isMutateLoading || isLoadingProjectAssets}
            disabled={isMutateLoading}
          ></Button>
        </Tooltip>
      </Space>
    </Flex>
  );

  if (!storyboard) {
    return (
      <>
        {Controls}
        {isBusy && <Skeleton active />}
        {isBusy && <Skeleton active />}
        {isBusy && <Skeleton active />}
        {isBusy && <Skeleton active />}
        {isBusy && <Skeleton active />}
      </>
    );
  }

  const handleAddSection = () => {
    const newSecId = generateId("section");
    const newKeyInsightId = generateId("key_insights");
    setStoryboard((old) => {
      return {
        ...old!,
        sections: [
          ...old!.sections,
          {
            topic: "",
            key_insights: [],
            detailed_summary: "",
            scripts: [],
            script: {
              content: "",
              selected: false,
            },
            id: newSecId,
            sources: [],
          },
        ],
      };
    });

    setSectionsId((old) => {
      return [...old, newSecId];
    });

    setKeyInsightId((old) => {
      return [...old, newKeyInsightId];
    });
  };

  // const getFullScript = (includeTopics = false) => {
  //   return storyboard?.sections?.reduce((acc, x) => {
  //     if (includeTopics) {
  //       return `${acc}# ${x.topic}\n${x.script?.content}\n\n`;
  //     } else {
  //       return `${acc}${x.script?.content}\n\n`;
  //     }
  //   }, "");
  // };

  if (displayMode === "present") {
    return (
      <Flex vertical>
        {Stats}
        <Present
          storyboard={storyboard}
          handleStoryboardApproval={handleStoryboardApproval}
        />
      </Flex>
    );
  }

  return (
    <>
      {contextHolder}

      {Stats}
      {Controls}
      {/* {displayMode === "compact" && (
          <Flex vertical gap={10}>
            <MiniLabel>Markdown final script</MiniLabel>
            <Typography.Paragraph
              copyable
              ellipsis={{ rows: 2, expandable: true }}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {getFullScript()}
            </Typography.Paragraph>
          </Flex>
        )} */}
      <Flex vertical gap={10} style={{ marginTop: 0 }}>
        <Flex justify="space-between" align="center">
          <Typography.Title
            level={4}
            style={{ width: "90%", padding: 0, margin: 0 }}
            editable={{
              enterIcon: null,
              onChange: (value) => {
                setStoryboard((old) => ({
                  ...old!,
                  title: value,
                }));
                setEditing(false);
              },
              editing: editing,
              tooltip: false,
              onStart: () => setEditing(true),
            }}
          >
            {storyboard.title}
          </Typography.Title>
          <Button
            size="large"
            shape="circle"
            icon={<PlusOutlined />}
            type="text"
            onClick={handleAddSection}
          />
        </Flex>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={sectionsId}
            strategy={verticalListSortingStrategy}
          >
            {storyboard?.sections.map(
              (section: TDetailedSection, index: number) => {
                return (
                  <Section
                    requestTextToSpeech={requestTextToSpeech}
                    generateId={generateId}
                    storyboard={storyboard}
                    key={section.id}
                    id={section.id}
                    changeSection={handleChangeSection}
                    generateSectionScripts={generateSectionScripts}
                    improveScript={handleImproveScript}
                    generateSection={generateSection}
                    section={section}
                    keyInsightsId={keyInsightsId}
                    setKeyInsightId={setKeyInsightId}
                    displayMode={displayMode}
                    editable={!isBusy && isConnected}
                    index={index}
                  />
                );
              }
            )}
          </SortableContext>
        </DndContext>

        {/* <Typography.Paragraph>
          {JSON.stringify(keyInsightsId, null, 2)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {JSON.stringify(scriptsId, null, 2)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {JSON.stringify(sectionsId, null, 2)}
        </Typography.Paragraph> */}
        {/* <JsonPreview data={stripId()} /> */}
      </Flex>
    </>
  );
};
function processSources(unsortedSources: DocumentExtract[]) {
  const sources = [...unsortedSources];
  sources.sort(sortSourcesByPageNumber);
  return sources;
}
