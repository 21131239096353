import { Spin, Tooltip, Typography } from "antd";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  AreaHighlight,
  Highlight,
  IHighlight,
  NewHighlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
  Tip,
} from "react-pdf-highlighter";
import { MediaDocument } from "pages/media/types";
import { RoughNotation } from "react-rough-notation";
import styled from "styled-components";
import HighlightZoom from "../binder/HighlightZoom";
import { useHighlightsStore } from "./useHighlightsStore";
import { useCuepointsStore } from "./useCuepointsStore";
import { InspectorToolbar } from "./InspectorToolbar";
import { Cuepoint } from "../binder/types";
import { useAntTheme } from "hooks/useAntTheme";
import { UUID } from "components/UUID";

export const AnimationPdf: React.FC<{
  document: MediaDocument;
  addHighlight: (h: NewHighlight) => void;
  handleNewCuepoint: (update: Partial<Cuepoint>) => void;
  startScrollTo: string;
  handleOnSave: () => Promise<void>;
}> = ({
  document,
  addHighlight,
  startScrollTo,
  handleNewCuepoint,
  handleOnSave,
}) => {
  const scrolledPage = useRef(0);
  const scrolledPages = useRef<number[]>([]);
  const scrollViewerTo = useRef((highlight: IHighlight) => {});

  const { theme } = useAntTheme();

  const { cuepoints, setCurrentCuepoint } = useCuepointsStore();
  const { currentHighlight, highlights, setCurrentHighlight } =
    useHighlightsStore();

  const updateCount = useCuepointsStore((state) => state.updateCount);

  useEffect(() => {
    let h = getHighlightById(startScrollTo);

    if (h) scrollViewerTo.current(h);
  }, [startScrollTo, currentHighlight, updateCount]);

  const getHighlightById = (id: string) => {
    return highlights.find((highlight) => highlight.id === id);
  };

  const resetHash = () => {
    global.document.location.hash = "";
  };

  const handleAddHighlight = (highlight: NewHighlight) => {
    // Reload the inspector if user didn't close it before creating new highlight
    setCurrentHighlight(undefined);
    setCurrentCuepoint(undefined);

    addHighlight(highlight);
  };

  const HighlightPopup = ({
    comment,
  }: {
    comment: { text: string; emoji: string };
  }) =>
    comment.text ? (
      <div className="Highlight__popup">
        {comment.emoji} {comment.text}
      </div>
    ) : null;

  const handleOnClick = (highlight: IHighlight) => {
    // console.debug("BinderPdf handleOnClick highlight", highlight);
    setCurrentHighlight(highlight);
    setCurrentCuepoint(highlight.id);
  };

  // to fix
  // const handleUpdateHighlight = (
  //   highlightId: string,
  //   position: Object,
  //   content: Object
  // ) => {
  //   updateHighlight(highlightId, position, content);
  // };

  if (!document.url) {
    return null;
  }

  return (
    <StyledPdfLoaderWrapper>
      <PdfLoader url={document.url} beforeLoad={<Spin />}>
        {(pdfDocument) => (
          <PdfHighlighter
            pdfDocument={pdfDocument}
            enableAreaSelection={(event) => event.altKey}
            onScrollChange={resetHash}
            scrollRef={(scrollTo) => {
              scrollViewerTo.current = scrollTo;
              // scrollToHighlightFromHash();
            }}
            onSelectionFinished={(
              position,
              content,
              hideTipAndSelection,
              transformSelection
            ) => (
              <Tip
                onOpen={transformSelection}
                onConfirm={(comment) => {
                  handleAddHighlight({ content, position, comment });
                  hideTipAndSelection();
                }}
              />
            )}
            highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo
            ) => {
              const isTextHighlight = !Boolean(
                highlight.content && highlight.content.image
              );
              const idStylesRelative = {
                position: "relative",
                zIndex: 1000,
                left: -25,
                top: 10,
                color: "#fff",
                background:
                  currentHighlight?.id === highlight.id
                    ? theme.colorPrimary
                    : theme.colorBgSpotlight,
                fontSize: 11,
                padding: 3,
                borderRadius: 20,
                minWidth: 20,
                textAlign: "center",
                width: "fit-content",
                fontWeight: "bold",
                opacity: 0.8, // to not cover pdf content
              };

              const idStylesAbsolute = {
                position: "absolute",
                zIndex: 1000,
                left: highlight?.position.boundingRect.left,
                top: highlight?.position.boundingRect.top,
                color: "#fff",
                background:
                  currentHighlight?.id === highlight.id
                    ? theme.colorPrimary
                    : theme.colorBgSpotlight,
                fontSize: 11,
                padding: 3,
                borderRadius: 20,
                fontWeight: "bold",
                opacity: 0.8,
              };

              const currentCuepoint = cuepoints.find((cuepoint) => {
                return cuepoint.highlightId === highlight.id;
              });
              const currentRoughNotation = currentCuepoint?.roughNotation;
              let component;

              let highlightIndex;

              if (highlights) {
                highlightIndex = highlights.findIndex(
                  (h) => h.id === highlight.id
                );
              }

              if (
                currentCuepoint?.roughNotation === undefined ||
                Boolean(currentCuepoint?.zoom)
              ) {
                component = isTextHighlight ? (
                  <div>
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                      onClick={() => handleOnClick(highlight)}
                    />
                    <Tooltip
                      autoAdjustOverflow
                      title={
                        <UUID id={highlight?.id} style={{ color: "#fff" }} />
                      }
                    >
                      <div style={idStylesAbsolute as CSSProperties}>
                        {highlightIndex + 1}
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    onMouseDown={() => handleOnClick(highlight)}
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip
                      autoAdjustOverflow
                      title={
                        <UUID id={highlight?.id} style={{ color: "#fff" }} />
                      }
                    >
                      <div style={idStylesAbsolute as CSSProperties}>
                        {highlightIndex + 1}
                      </div>
                    </Tooltip>
                    <AreaHighlight
                      isScrolledTo={isScrolledTo}
                      highlight={highlight}
                      onChange={(boundingRect) => {
                        // change not supported yet,
                        // this code also make the highlight moves to another position
                        // when using pdfCoordinates = true
                        // MED-1014
                        // handleUpdateHighlight(
                        //   highlight.id,
                        //   {
                        //     boundingRect: viewportToScaled(boundingRect),
                        //   },
                        //   { image: screenshot(boundingRect) }
                        // );
                      }}
                    />
                    {currentCuepoint?.zoom && highlight.content.image && (
                      <HighlightZoom
                        src={highlight.content.image}
                        position={highlight.position}
                      />
                    )}
                  </div>
                );
              } else {
                // console.debug("yes rough notation");
                const showAnnotation =
                  highlight.position.pageNumber === scrolledPage.current ||
                  //   highlightedIds.current.includes(highlight.id)
                  scrolledPages.current.includes(highlight.position.pageNumber);
                component = (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      left: highlight?.position.boundingRect.left,
                      top: highlight?.position.boundingRect.top - 25,
                      height: highlight.position.boundingRect.height,
                      width: highlight.position.boundingRect.width,
                      color: "#fff",
                      fontSize: 10,
                      padding: 2,
                      borderRadius: 3,
                    }}
                  >
                    <Tooltip
                      autoAdjustOverflow
                      title={
                        <UUID id={highlight?.id} style={{ color: "#fff" }} />
                      }
                    >
                      <div style={idStylesRelative as CSSProperties}>
                        {highlightIndex + 1}
                      </div>
                    </Tooltip>
                    <RoughNotation
                      key={JSON.stringify(currentRoughNotation)}
                      show={true}
                      animate={currentRoughNotation?.animate}
                      type={currentRoughNotation!.type}
                      animationDelay={currentRoughNotation?.animationDelay}
                      animationDuration={
                        currentRoughNotation?.animationDuration
                      }
                      strokeWidth={currentRoughNotation?.strokeWidth}
                      padding={currentRoughNotation?.padding}
                      color={currentRoughNotation?.color}
                      brackets={currentRoughNotation?.brackets}
                      iterations={currentRoughNotation?.iterations}
                      multiline={currentRoughNotation?.multiline}
                    >
                      <div
                        onClick={() => handleOnClick(highlight)}
                        style={{
                          height: highlight.position.boundingRect.height,
                          width: highlight.position.boundingRect.width,
                          cursor: "pointer",
                        }}
                      />
                    </RoughNotation>
                  </div>
                );
              }

              return (
                <Popup
                  popupContent={<HighlightPopup {...highlight} />}
                  onMouseOver={(popupContent) =>
                    setTip(highlight, (highlight) => popupContent)
                  }
                  onMouseOut={hideTip}
                  key={index}
                  children={component}
                />
              );
            }}
            highlights={highlights}
          />
        )}
      </PdfLoader>
      {currentHighlight && (
        <InspectorToolbar
          handleNewCuepoint={handleNewCuepoint}
          // onClickSave={handleOnSave}
        />
      )}
    </StyledPdfLoaderWrapper>
  );
};

const StyledPdfLoaderWrapper = styled.div`
  // todo : fix blend mode on .textLayer and .PdfHighlighter__highlight-layer
  // https://stackoverflow.com/questions/31629541/remove-mix-blend-mode-from-child-element
  .textLayer {
    mix-blend-mode: normal;
  }
  .AreaHighlight__part {
    background: rgba(255, 226, 143, 0.4);
  }
  .PdfHighlighter__highlight-layer .rough-annotation {
    opacity: 0.35;
  }

  position: relative;
  width: 100%;
  height: calc(100vh - 200px);
`;
