import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useState } from "react";
import { Button, ButtonProps, Modal, Space, Typography } from "antd";
import { PlayCircleOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Play } from "@phosphor-icons/react";

type PreviewVideoProps = {
  url: string;
} & ButtonProps;

const PreviewVideoButton: React.FC<PreviewVideoProps> = ({
  url,
  size = "small",
  children,
}) => {
  const t = useTranslate();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  // const getFileExtension = (url: string): string | undefined => {
  //   const filename = url.split("?")[0];
  //   return filename.split(".").pop()?.toLowerCase();
  // };
  // const isVideoFile = (url: string | undefined): boolean => {
  //   if (!url) return false;
  //   const extension = getFileExtension(url);
  //   console.log(extension);
  //   return extension === "mp4" || extension === "avi";
  // };

  return (
    <Space>
      <Button
        type="text"
        size={size}
        icon={
          <span className="anticon">
            <Play style={{ fontSize: 24 }} weight="bold" />
          </span>
        }
        onClick={showModal}
        style={{
          color: "white",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Button>
      <Modal open={visible} onCancel={handleCancel} footer={null}>
        {url && (
          <video controls style={{ maxHeight: "85vh" }}>
            <source src={url} type="video/mp4" />
            {t("projects.components.PreviewVideoButton.yourBrowserDoes")}
          </video>
        )}
      </Modal>
    </Space>
  );
};

export default PreviewVideoButton;

