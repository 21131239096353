import { useContext, useEffect, useState } from "react";
import { OnboardingContainer } from ".";
import { AppContext } from "appContext";
import useSessionData from "hooks/useSessionData";
import { useGo, useNavigation } from "@refinedev/core";
import { Button, Flex, Form, Input, Spin, Typography } from "antd";
import { t } from "i18next";
import useForm from "antd/es/form/hooks/useForm";
import useOrganizationCreate from "./hooks/useOrganizationCreate";
import { SELECTED_ORG_ID_KEY } from "authProvider";
import styled from "styled-components";

export default function CreateOrganizationPage() {
  const { dispatch, state } = useContext(AppContext);
  const { userData, isLoading } = useSessionData();
  const { create } = useNavigation();
  const go = useGo();
  const [form] = useForm<{ organizationName: string }>();
  const { Title, Text } = Typography;
  const [hasCreatedOrg, setHasCreatedOrg] = useState(false);
  const {
    createOrganization,
    isError,
    isLoading: isLoadingOrganizationCreate,
  } = useOrganizationCreate();
  const hasOrg = userData?.data.organizations.length > 0;

  useEffect(() => {
    dispatch({ type: "setSidebarCollapsed", payload: true });
  }, [dispatch]);
  const handleFinish = async (values) => {
    const organization = await createOrganization(values.name);
    dispatch({ type: "setSidebarCollapsed", payload: false });
    localStorage.setItem(SELECTED_ORG_ID_KEY, organization?.data?.id);
    setHasCreatedOrg(true);
    create("media_projects");
  };
  useEffect(() => {
    if (hasCreatedOrg)
      if (hasOrg) {
        go({ to: "/" });
      }
  }, [userData, hasCreatedOrg]);

  return (
    <>
      <Spin fullscreen spinning={isLoading} />
      <OnboardingContainer isLoading={false} justify={"unset"}>
        <Flex
          vertical
          justify="center"
          align="center"
          style={{
            textAlign: "center",
            margin: "50px 0 0 0",
          }}
        >
          <Title style={{ marginBottom: 0 }} level={3}>
            {t("pages.onboarding.createOrganization.title")}
          </Title>
        </Flex>
        <Text type="secondary">
          {t("pages.onboarding.createOrganization.secondaryText")}
        </Text>
        <StyledForm
          form={form}
          onFinish={handleFinish}
          style={{ marginTop: 30 }}
        >
          <Form.Item
            name="name"
            style={{ minWidth: 300 }}
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[ -~]+$/,
                message: t(
                  "pages.onboarding.createOrganization.invalidCharacters"
                ),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("media.organizations.index.organizationName")}
            />
          </Form.Item>
          <Button
            style={{ marginTop: 30 }}
            disabled={isLoading || isLoadingOrganizationCreate}
            htmlType="submit"
            shape="round"
            size="large"
            type="primary"
          >
            {t("buttons.submit")}
          </Button>
        </StyledForm>
      </OnboardingContainer>
    </>
  );
}
const StyledForm = styled(Form)`
  & .ant-form-item-explain-error {
    max-width: 300px;
  }
`;
