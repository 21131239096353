import { useGetIdentity, useCustom } from "@refinedev/core";
import { AppContext } from "appContext";
import { User } from "authProvider";
import { useContext, useEffect } from "react";

export default function useSessionData() {
  const { data: user, isLoading: isLoadingIdentity } = useGetIdentity<User>();

  const { data: userData, isLoading: isLoadingUserData } = useCustom({
    url: `api/users/me`,
    method: "get",
    queryOptions: {
      enabled: !!user,
      retry: false,
    },
  });

  const { data: organizationsData, isLoading: isLoadingOrganizationData } =
    useCustom({
      url: `api/users/me/organizations`,
      method: "get",
      queryOptions: {
        enabled: !!userData?.data,
        retry: false,
      },
    });

  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({ type: "accesses", payload: userData?.data?.accesses.accesses });
    dispatch({ type: "setUser", payload: userData?.data });
    dispatch({ type: "setOrganizations", payload: organizationsData?.data });
    dispatch({ type: "setOrganization", payload: organizationsData?.data[0] });
  }, [dispatch, userData, organizationsData]);
  return {
    user,
    userData,
    organizationsData,
    isLoading:
      isLoadingUserData || isLoadingOrganizationData || isLoadingIdentity,
  };
}
