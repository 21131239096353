import { components } from "api";
import { createContext } from "react";
import { AppFlavor, Maybe, Organization } from "types";

export type AppState = {
  accesses: object;
  user: Maybe<components["schemas"]["User"]>;
  hasAccesses: boolean;
  organization?: Organization;
  organizations: Array<Organization>;
  sidebarCollapsed: boolean;
  appFlavor: AppFlavor;
};

const DEFAULT_ORGANIZATION = {
  name: "Libertify",
  id: "libertify",
  created: "-",
  updated: "-",
};

export const initialState: AppState = {
  accesses: {},
  hasAccesses: false,
  organizations: [],
  user: null,
  sidebarCollapsed: false,
  appFlavor: "default",
};
export const AppContext = createContext<{
  state: AppState;
  dispatch: Function;
}>({ state: initialState, dispatch: () => true });

export const reducer = (state: AppState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case "accesses":
      return {
        ...state,
        accesses: payload,
        hasAccesses: Boolean(Object.keys(payload ?? {}).length),
      };
    case "setOrganizations":
      return {
        ...state,
        organization: state.organization
          ? state.organization
          : payload?.length > 0
            ? payload[0]
            : DEFAULT_ORGANIZATION,
        organizations: payload,
      };
    case "setOrganization":
      return {
        ...state,
        organization: payload,
      };
    case "setUser":
      return {
        ...state,
        user: payload,
      };
    case "setSidebarCollapsed":
      return {
        ...state,
        sidebarCollapsed: payload,
      };
    case "setAppFlavor":
      return {
        ...state,
        appFlavor: payload,
      };
    default:
      return state;
  }
};
