import { Popover, Flex, Typography } from "antd";
import { ContactResponse, PresenterProfileResponse } from "pages/media/types";
import { ReactNode } from "react";
import { PresenterProfilePreviewView } from "./PresenterProfilePreviewView";
import { useOne } from "@refinedev/core";

export const ContactsPopover = ({
  children,
  contacts,
}: {
  children: ReactNode;
  contacts: ContactResponse[] | undefined;
}) => {
  return (
    <Popover
      mouseEnterDelay={0.5}
      placement="bottomLeft"
      content={
        <Flex align="center" gap={20}>
          {contacts?.map((contact) => {
            return (
              <Flex
                vertical
                align="center"
                justify="center"
                gap={2}
                key={contact.id}
              >
                {contact && (
                  <PresenterWrapper
                    organizationId={contact.organization_id}
                    presenterId={contact.presenter_id}
                    contact={contact}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};

const PresenterWrapper = ({
  organizationId,
  presenterId,
  contact,
}: {
  organizationId: string;
  presenterId: string;
  contact: ContactResponse;
}) => {
  const { data: presenter } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: presenterId,
  });

  return (
    <Flex vertical align="center" gap={10}>
      {presenter && (
        <PresenterProfilePreviewView
          presenter={presenter.data}
          style={{ width: 100, height: 100 }}
        />
      )}
      <Flex vertical align="center">
        <Typography.Text>
          {contact.firstname} {contact.lastname}
        </Typography.Text>
        <Typography.Text type="secondary">
          {presenter?.data.name}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};
