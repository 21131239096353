import { t } from "i18next";
import { AuthBindings } from "@refinedev/core";
import axios, { AxiosError } from "axios";
import { API_URL } from "./services/api";

export const TOKEN_KEY = "refine-auth";
export const SELECTED_ORG_ID_KEY = "selectedOrganizationId";
const MFA_SESSION_KEY = "refine-auth-mfa";

export type User = {
  id: string;
  avatar?: string;
  name?: string;
  token?: {
    access_token: string;
    refresh_token: string;
    token_type: string;
  };
};

export const refreshAccessToken = async () => {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY) || "{}");
  if (tokens?.refresh_token) {
    const refresh_token = tokens?.refresh_token;

    const refreshTokenParams = { refresh_token };
    const response = await axios({
      url: `${API_URL}/api/tokens/refresh`,
      method: "post",
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      // },
      data: refreshTokenParams, // new URLSearchParams(refreshTokenParams).toString(),
    });
    if (response.status !== 200) {
      return Promise.reject(
        new Error(t("src.authProvider.refreshTokenFailed"))
      );
    } else {
      localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify({ ...response.data, refresh_token })
      );
      return response.data.access_token;
    }
  }
};

const storeToken = (data: any) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
};
const storeMfaSession = (data: any) => {
  localStorage.setItem(MFA_SESSION_KEY, JSON.stringify(data));
};
const clearMfaSession = () => {
  localStorage.removeItem(MFA_SESSION_KEY);
};

export const authProvider: AuthBindings = {
  login: async ({ email, password }: { email: string; password: string }) => {
    const body = new URLSearchParams({
      username: email.toLowerCase(),
      password,
    });
    const mime = "application/x-www-form-urlencoded";

    const response = await axios({
      url: `${API_URL}/api/tokens/`,
      method: "POST",
      headers: {
        "Content-Type": mime,
      },
      data: body,
      validateStatus: function (status) {
        // accept 400 errors (don't reject the axios promise) to process use cases
        return status >= 200;
      },
    });

    // console.debug("login", response);
    if (response.data.access_token) {
      storeToken(response.data);
      return {
        success: true,
        redirectTo: "/",
      };
    } else if (
      response.data?.detail?.challenge === "NEW_PASSWORD_REQUIRED" &&
      response.data?.detail?.code === "MFA_REQUIRED"
    ) {
      storeMfaSession({
        email,
        session: response.data.detail.session,
        challenge: response.data?.detail?.challenge,
      });
      return {
        success: false, // necessary for the redirect to work
        redirectTo: "/change-password",
        error: {
          message: t("src.authProvider.youMustChange"),
          name: t("src.authProvider.login"),
        },
      };
    } else if (response.data?.detail?.code === "EMAIL_UNVERIFIED") {
      return {
        success: false,
        // pass in base64 in url
        redirectTo: "/verification?email=" + btoa(email),
        error: {
          message: t("src.authProvider.youMustActivate"),
          name: t("src.authProvider.login"),
        },
      };
    }

    return {
      success: false,
      error: {
        message: t("src.authProvider.loginError"),
        name: t("src.authProvider.invalidEmailOr"),
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    // cleanup personal data
    localStorage.removeItem(SELECTED_ORG_ID_KEY);

    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    console.debug("onError", error);

    const isRefreshTokenIssue = [
      "Invalid Refresh Token",
      "Refresh Token has been revoked",
      "Refresh Token has expired",
    ].includes(error.message);

    if (error.status === 400 && isRefreshTokenIssue) {
      return {
        logout: true,
        redirectTo: "/login",
        error,
      };
    }
    return {};
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
      logout: true,
      error: {
        message: t("src.authProvider.checkFailed"),
        name: t("src.authProvider.userNotFound"),
      },
    };
  },
  getPermissions: async () => {},
  getIdentity: async (): Promise<User | null> => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    return {
      id: "", // todo
      token: JSON.parse(token),
    };
  },
  register: async ({ email, password, firstname = "", lastname = "" }) => {
    // You can handle the register process according to your needs.
    const response = await axios({
      url: `${API_URL}/api/users/signup`,
      method: "post",
      data: {
        email,
        password,
        firstname,
        lastname,
      },
      validateStatus: function (status) {
        // accept 409 errors (don't reject the axios promise) to process use cases
        return status >= 200;
      },
    });

    if (response.status === 200) {
      // storeToken(response.data);
      // If the process is successful.
      return {
        success: true,
        redirectTo: "/verification?email=" + btoa(email),
      };
    }

    return {
      success: false,
      error: {
        name: t("src.authProvider.registrationError"),
        message: response.data.detail.message,
      },
    };
  },
  updatePassword: async ({ password, confirmPassword }) => {
    const token = JSON.parse(localStorage.getItem(MFA_SESSION_KEY) ?? "{}");
    let response;
    try {
      response = await axios({
        url: `${API_URL}/api/tokens/mfa`,
        method: "post",
        data: {
          email: token.email,
          session: token.session,
          challenge: token.challenge,
          challenge_response: password,
        },
      });

      if (response.status === 200) {
        clearMfaSession();
        return authProvider.login({
          username: token.email,
          email: token.email,
          password,
        });
      }
    } catch (e) {
      const error = e as Error | AxiosError;
      console.debug({ error });
      if (axios.isAxiosError(error)) {
        console.debug(error.response?.data.detail.message);
        return {
          success: false,
          error: {
            name: t("src.authProvider.invalidPasswordChange"),
            message: error.response?.data.detail.message,
          },
        };
      } else {
        return {
          success: false,
          error: {
            name: t("src.authProvider.invalidPasswordChange"),
            message: "",
          },
        };
      }
    }
    return {
      success: false,
      error: {
        name: t("src.authProvider.invalidPasswordChange"),
        message: "",
      },
    };
  },
};

