import { useList } from "@refinedev/core";
import { AppContext } from "appContext";
import { useContext } from "react";
import { Asset, AssetClass, Maybe, Organization } from "types";
import { SELECTED_ORG_ID_KEY } from "authProvider";

export const useOrganization = ({
  assetClass = "Equity",
  id,
}: {
  assetClass?: AssetClass;
  id?: string;
}) => {
  const {
    state,
    dispatch,
    state: { organization: currentOrganization, organizations },
  } = useContext(AppContext);
  const hasMultipleOrganizations = organizations?.length > 1;

  const { data: tickersData, isLoading: isLoadingAssets } = useList<Asset>({
    resource: `api/assets/${assetClass}/clients_tickers?organization_id=${currentOrganization?.id}`,
    queryOptions: {
      enabled: Boolean(currentOrganization) && !id,
    },
  });
  const assets = tickersData?.data;

  const getAsset = (ticker: string): Maybe<Asset> =>
    assets?.find((x) => x.ticker === ticker) ?? null;

  const getOrganization = (id: string): Maybe<Organization> =>
    organizations?.find((x) => x.id === id) ?? null;

  const changeOrganization = (value: string) => {
    dispatch({
      type: "setOrganization",
      payload: organizations.find(({ id }) => id === value),
    });
    localStorage.setItem(SELECTED_ORG_ID_KEY, value);
  };

  const organization = id ? getOrganization(id) : currentOrganization;
  return {
    organization,
    assets,
    isLoadingAssets,
    getAsset,
    getOrganization,
    organizations,
    hasMultipleOrganizations,
    changeOrganization,
  };
};
