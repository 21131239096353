import { useTranslate } from "@refinedev/core";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Pause, Play } from "@phosphor-icons/react";
import { Flex, Button, Typography, Progress } from "antd";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
type AudioSampleProps = {
  removeAudioSample?: () => void;
  audio: File | HTMLAudioElement | string; // String is path or base64 data URI
  canDelete?: boolean;
  children?: React.ReactNode;
  containerJustify?: React.CSSProperties["justifyContent"];
  onDemand?: boolean;
  onClickPlay?: () => void;
  loading?: boolean;
  playImmediately?: boolean;
};

export const AudioSample: React.FC<AudioSampleProps> = ({
  audio,
  removeAudioSample,
  canDelete = true,
  children,
  containerJustify = "space-between",
  onDemand,
  onClickPlay,
  loading,
  playImmediately,
}) => {
  const t = useTranslate();
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [hasTouchedPlayBefore, setHasTouchedPlayBefore] =
    useState<boolean>(false);
  const playSound = async (audio: HTMLAudioElement) => {
    if (currentTime === duration) {
      audio.currentTime = 0;
    }
    if (audio.paused) {
      setPlaying(true);
      await audio.play();
    } else {
      audio.pause();
      setPlaying(false);
    }
    setHasTouchedPlayBefore(true);
  };
  const [duration, setDuration] = useState<number>(
    Math.floor((audio as HTMLAudioElement)?.duration ?? 0)
  );
  const assignDuration = () => {
    setDuration(audioElement.duration);
  };
  const audioElement = useMemo<HTMLAudioElement>((): HTMLAudioElement => {
    if (typeof audio === "string") {
      try {
        return new Audio(URL.createObjectURL(base64toBlob({ data: audio })));
      } catch {
        // Treat as a URL
        return new Audio(audio);
      }
    } else if (audio instanceof File) {
      return new Audio(URL.createObjectURL(audio as Blob));
    } else {
      return audio;
    }
  }, [audio]);
  function handleEnded() {
    setPlaying(false);
    setCurrentTime(0);
  }
  useEffect(() => {
    if (onDemand) return;
    if (playImmediately && !hasTouchedPlayBefore) {
      playSound(audioElement);
    }
    let animation = 0;
    function updateCurrentTime() {
      setCurrentTime(audioElement.currentTime);
      requestAnimationFrame(updateCurrentTime);
    }
    animation = requestAnimationFrame(updateCurrentTime);
    // stop animation when component unmounts
    return () => cancelAnimationFrame(animation);
  }, [audioElement]);
  useEffect(() => {
    // Reset the progress bar and once again play after the audio has loaded
    // from the server
    if (!audio) {
      setHasTouchedPlayBefore(false);
      setCurrentTime(0);
    }
  }, [audioElement]);
  useEffect(() => {
    if (onDemand) return;
    audioElement.addEventListener("loadedmetadata", assignDuration);
    audioElement.addEventListener("ended", handleEnded);
    return () => {
      audioElement.removeEventListener("loadedmetadata", assignDuration);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, [audioElement]);
  function handleClick() {
    if (onDemand) {
      onClickPlay?.();
    } else {
      playSound(audioElement);
    }
  }
  let icon = playing ? (
    <Pause size={20} weight="fill" />
  ) : (
    <Play size={20} weight="fill" />
  );
  if (loading) {
    icon = (
      <LoadingOutlined
        spin
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  }
  return (
    <Flex
      gap={16}
      justify={containerJustify}
      style={{ width: "100%" }}
      align="center"
    >
      <Flex align="center">
        <Button
          shape="circle"
          style={{ position: "relative", left: "-10px", flex: 0 }}
          onClick={handleClick}
          icon={icon}
          type="text"
        ></Button>
        {children}
      </Flex>
      <StyledProgress
        showInfo={false}
        style={{ width: "100%", flex: "1 0" }}
        percent={onDemand ? 0 : (currentTime / duration) * 100}
      />
      <Flex gap={16} align="center" justify="flex-end">
        {!onDemand && (
          <Typography.Text
            style={{ flex: "1 0", textAlign: "right" }}
            type="secondary"
          >
            {`${Math.floor(currentTime)}${t("media.components.AudioSample.s")}`}{" "}
            / {Math.floor(duration)}
            {t("media.components.AudioSample.s")}
          </Typography.Text>
        )}
        {canDelete && (
          <Button
            style={{ flex: "0 1" }}
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              removeAudioSample?.();
              return audioElement.remove();
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
type Base64 = {
  data: string;
  mime?: string;
};

function base64toBlob({ data, mime = "audio/mp3" }: Base64): Blob {
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
}

const StyledProgress = styled(Progress)`
  & .ant-progress-bg {
    transition: none;
  }
`;
