import { ReactNode, SVGAttributes } from "react";

export function ChartBarSquareIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      fill="none"
      strokeWidth={1.5}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
      />
    </svg>
  );
}

export function DocumentChartBarIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      fill="none"
      strokeWidth={1.5}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
      />
    </svg>
  );
}

export function NewspaperIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      fill="none"
      strokeWidth={1.5}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
      />
    </svg>
  );
}

export function ArrowExternal(props: SVGAttributes<SVGElement>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 6 6" height="7" width="7" {...props}>
      <path
        d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function ProgressBarStatusSeparator(props: SVGAttributes<SVGAElement>) {
  return (
    <svg
      width="auto"
      height="50px"
      viewBox="0 0 766 3507"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M764.871 1754.2L765.509 1754.44L93.5088 3527.44L0 3492L658.998 1753.3L2.00002 -0.000457764L95.6415 -35.0898L765.97 1753.79L764.871 1754.2Z"
        fill={props.fill ?? "white"}
      />
    </svg>
  );
}

export function ProjectIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.5119 5.85L13.5719 2.42C12.6019 1.86 11.4019 1.86 10.4219 2.42L4.49187 5.85C3.52187 6.41 2.92188 7.45 2.92188 8.58V15.42C2.92188 16.54 3.52187 17.58 4.49187 18.15L10.4319 21.58C11.4019 22.14 12.6019 22.14 13.5819 21.58L19.5219 18.15C20.4919 17.59 21.0919 16.55 21.0919 15.42V8.58C21.0819 7.45 20.4819 6.42 19.5119 5.85ZM14.2519 13.4L13.2119 14L12.1719 14.6C10.8419 15.37 9.75187 14.74 9.75187 13.2V12V10.8C9.75187 9.26 10.8419 8.63 12.1719 9.4L13.2119 10L14.2519 10.6C15.5819 11.37 15.5819 12.63 14.2519 13.4Z"
        fill="#292D32"
      />
    </svg>
  );
}

// from https://www.figma.com/file/8RXiI90uve2pcXfEoZ51au/Pikaicons-V2.0-FREE-(Community)?type=design&node-id=12-415&mode=design&t=TCJHlvcy0hG8aly9-0
export function AiIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke={props.stroke ?? "#111"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth ?? "2"}
        d="M6.577 3.764c.46-.893.69-1.34.999-1.485a1 1 0 01.848 0c.31.145.539.592.999 1.485l1.191 2.315c.08.154.12.232.171.3a1 1 0 00.157.165c.065.055.14.098.291.186l2.386 1.387c.782.454 1.173.681 1.305.977a1 1 0 010 .812c-.132.296-.523.523-1.305.977l-2.386 1.387c-.15.088-.226.131-.291.186-.059.049-.111.104-.157.165-.051.068-.091.146-.17.3l-1.192 2.315c-.46.893-.69 1.34-.999 1.485a1 1 0 01-.848 0c-.31-.145-.539-.592-.999-1.485l-1.191-2.315c-.08-.154-.12-.232-.171-.3a1.003 1.003 0 00-.157-.165 2.099 2.099 0 00-.291-.186l-2.386-1.387c-.782-.454-1.173-.681-1.305-.977a1 1 0 010-.812c.132-.296.523-.523 1.305-.977L4.767 6.73c.15-.088.226-.131.291-.186a1 1 0 00.157-.165 2.1 2.1 0 00.17-.3l1.192-2.315zM15.46 17.406c-.254-.317-.381-.476-.429-.659a.99.99 0 010-.494c.048-.183.175-.342.428-.66l.816-1.019c.254-.317.38-.475.527-.535a.52.52 0 01.396 0c.146.06.273.218.527.535l.816 1.02c.253.317.38.476.428.659a.99.99 0 010 .494c-.048.183-.175.342-.428.66l-.816 1.019c-.254.317-.38.475-.527.535a.52.52 0 01-.396 0c-.146-.06-.273-.218-.527-.535l-.815-1.02zM16.23 2.362c-.127-.126-.19-.19-.214-.263a.32.32 0 010-.198c.023-.073.087-.137.214-.263l.407-.408c.127-.127.19-.19.264-.214a.32.32 0 01.198 0c.073.023.137.087.264.214l.407.408c.127.126.19.19.214.263a.32.32 0 010 .198c-.024.073-.087.137-.214.263l-.407.408c-.127.127-.19.19-.264.214a.32.32 0 01-.198 0c-.073-.023-.137-.087-.264-.214l-.407-.408z"
      ></path>
    </svg>
  );
}

export function AiStarIcon(
  props: SVGAttributes<SVGElement> & { defs?: ReactNode }
) {
  return (
    <svg
      width="55"
      height="57"
      viewBox="0 0 55 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.7188 0.388887C26.7188 15.9219 39.3106 28.5139 54.8438 28.5139C39.3106 28.5139 26.7188 41.1058 26.7188 56.6389C26.7188 41.1058 14.1267 28.5139 -1.40625 28.5139C14.1267 28.5139 26.7188 15.9219 26.7188 0.388887Z"
        fill={props.color ?? "url(#paint0_linear_282_445)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_282_445"
          x1="37.5"
          y1="7.37501"
          x2="37.5"
          y2="63.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2400FF" />
          <stop offset="1" stopColor="#00FFD1" />
        </linearGradient>
        {props.defs}
      </defs>
    </svg>
  );
}

export function AiReadyMediaBrain(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <polygon
            opacity="1"
            points="16.64 15.13 17.38 13.88 20.91 13.88 22 12 19.82 8.25 16.75 8.25 15.69 6.39 14.5 6.39 14.5 5.13 16.44 5.13 17.5 7 19.09 7 16.9 3.25 12.63 3.25 12.63 8.25 14.36 8.25 15.09 9.5 12.63 9.5 12.63 12 14.89 12 15.94 10.13 18.75 10.13 19.47 11.38 16.67 11.38 15.62 13.25 12.63 13.25 12.63 17.63 16.03 17.63 15.31 18.88 12.63 18.88 12.63 20.75 16.9 20.75 20.18 15.13 18.09 15.13 17.36 16.38 14.5 16.38 14.5 15.13 16.64 15.13"
          />

          <polygon points="7.36 15.13 6.62 13.88 3.09 13.88 2 12 4.18 8.25 7.25 8.25 8.31 6.39 9.5 6.39 9.5 5.13 7.56 5.13 6.5 7 4.91 7 7.1 3.25 11.38 3.25 11.38 8.25 9.64 8.25 8.91 9.5 11.38 9.5 11.38 12 9.11 12 8.06 10.13 5.25 10.13 4.53 11.38 7.33 11.38 8.38 13.25 11.38 13.25 11.38 17.63 7.97 17.63 8.69 18.88 11.38 18.88 11.38 20.75 7.1 20.75 3.82 15.13 5.91 15.13 6.64 16.38 9.5 16.38 9.5 15.13 7.36 15.13" />
        </g>
      </g>
    </svg>
  );
}

export function LibertifyLogo(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="52"
      height="45"
      fill="none"
      viewBox="0 0 52 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.632 35.44 4.083-23.138c.32-1.912.256-3.76-.446-5.546C17.737 2.868 14.037 0 9.826 0H0v.51c2.297.829 4.083 2.804 4.913 5.227.064.127.064.318.127.446.256 1.083.256 2.23.064 3.378L.957 32.953c-.255 1.275-.191 2.677.064 3.952C2.106 41.558 6.125 45 10.847 45h10.017v-.446c-2.36-.829-4.211-2.741-5.04-5.227-.065-.127-.065-.319-.128-.446-.255-1.147-.255-2.295-.064-3.442Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M33.497 0c-7.146 0-10.719 5.737-11.995 13.831h18.503C47.151 13.831 50.724 8.095 52 0H33.497ZM21.12 16.062l-2.425 13.832h9.187c7.146 0 10.72-5.737 11.995-13.832H21.12Z"
        fill={props.color ?? "#3BD4CA"}
      />
    </svg>
  );
}
