import {
  useApiUrl,
  useCustomMutation,
  useGetLocale,
  useTranslate,
} from "@refinedev/core";
import React, { useContext } from "react";
import {
  Flex,
  Typography,
  Col,
  Row,
  Button,
  Space,
  message,
  MenuProps,
  Modal,
  Dropdown,
  Popconfirm,
} from "antd";
import { DateField } from "@refinedev/antd";
import { useGo, useList, useInvalidate } from "@refinedev/core";
import {
  ArrowSquareOut,
  BracketsCurly,
  ClockCountdown,
  DotsThreeVertical,
  Sparkle,
} from "@phosphor-icons/react";
import {
  EditOutlined,
  EyeOutlined,
  CopyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CanAccess } from "@refinedev/core";
import { useState } from "react";

import { addDateTz } from "pages/media/utils";
import { useAntTheme } from "hooks/useAntTheme";
import {
  MediaProjectResponse,
  MediaProjectPublicationBase,
  ProjectMediaResponse,
  ContactResponse,
} from "../../types";
import { MediaStatus } from "pages/media/components/MediaStatusComponent";
import { AppContext } from "appContext";
import { getLanguageName } from "pages/media/utils";
import { HoverableCard } from "components/HoverableCard";
import PreviewVideoButton from "./PreviewVideoButton";
import { ProjectMediaUpload } from "./ProjectMediaUpload";
import backgroundImg from "../../../../assets/background_3dmesh_white.jpg";
import { SimpleProjectMediaPublish } from "./SimpleProjectMediaPublish";
import { MEDIAPLAYER_URL } from "../../../../constants";
import DownloadButton from "./DownloadButton";
import { ProjectProgress } from "./ProjectProgress";
import { ContactsPopover } from "pages/media/components/ContactsPopover";

interface ProjectMediasProps {
  medias: ProjectMediaResponse[];
  project: MediaProjectResponse;
  onEditCuePoints: (mediaId: string) => void;
  refetchProjectDetail: () => void;
}

export const ProjectMedias: React.FC<ProjectMediasProps> = ({
  medias,
  project,
  onEditCuePoints,
  refetchProjectDetail,
}) => {
  const { data: publications } = useList<MediaProjectPublicationBase>({
    resource: `media/projects/${project.id}/publications`,
  });
  const go = useGo();
  const { data: contactsData } = useList<ContactResponse>({
    resource: `media/${project.organization_id}/contacts`,
  });
  const {
    state: { appFlavor },
  } = useContext(AppContext);
  const isStudio = appFlavor === "studio";

  const getLocale = useGetLocale();
  const currentLanguage = getLocale();

  return (
    <Flex vertical>
      {/* <Title level={5}>
          Videos <Badge showZero={true} count={medias.length} color="#999" />
        </Title> */}
      <Row gutter={20}>
        {medias.map((media, index) => {
          const mediaPublications = publications?.data.filter(
            // use contact_id+language as pivot instead of mediaId that can change during
            // the publication workflow in the backend
            (x) =>
              media?.id &&
              x.contact_id === media.contact_id &&
              x.language === media.language
          );

          const contact = contactsData?.data.find(
            (x) => x.id === media.contact_id
          );

          return (
            <Col
              key={index}
              xl={{ flex: "33%" }}
              md={{ flex: "50%" }}
              xs={{ flex: "100%" }}
            >
              <ProjectMediaCard
                media={media}
                onEditCuePoints={onEditCuePoints}
                project={project}
                mediaPublications={mediaPublications}
                refetchProjectDetail={refetchProjectDetail}
                index={index}
              />
              <Flex justify="space-between" style={{ marginTop: 10 }}>
                <Flex vertical>
                  {contact && (
                    <ContactsPopover contacts={[contact]}>
                      <Typography.Text
                        strong
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (contact?.id)
                            go({
                              to: {
                                resource: "media_contacts",
                                id: contact?.id,
                                action: "edit",
                              },
                            });
                        }}
                      >
                        {contact?.firstname} {contact?.lastname}
                      </Typography.Text>
                    </ContactsPopover>
                  )}
                  <Flex align="center" wrap="wrap">
                    {media.created && (
                      // <DateField
                      //   type="secondary"
                      //   value={getFormatedDate(media.created)}
                      //   format="LLL"
                      // />
                      <DateField
                        type="secondary"
                        value={addDateTz(media.created)}
                        format="L"
                      />
                    )}

                    <Typography.Text
                      type="secondary"
                      style={{
                        margin: "0 8px",
                        fontSize: "20px",
                      }}
                    >
                      &middot;
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {getLanguageName(media.language, currentLanguage)}
                    </Typography.Text>
                  </Flex>
                </Flex>

                <Flex vertical gap={10}>
                  {media.status && <MediaStatus status={media.status} />}
                </Flex>
              </Flex>
            </Col>
          );
        })}
      </Row>
    </Flex>
  );
};

export const ProjectMediaCard = ({
  media,
  onEditCuePoints,
  project,
  mediaPublications,
  refetchProjectDetail,
  index,
}: {
  media: ProjectMediaResponse;
  project: MediaProjectResponse;
  onEditCuePoints: (mediaId: string) => void;
  mediaPublications: MediaProjectPublicationBase[] | undefined;
  refetchProjectDetail: () => void;
  index: number;
}) => {
  const t = useTranslate();
  const { Text } = Typography;
  const invalidate = useInvalidate();
  const { mutateAsync } = useCustomMutation();
  const { theme } = useAntTheme();
  const apiUrl = useApiUrl();

  const handleCopyId = () => {
    navigator.clipboard
      .writeText(media.id ?? "")
      .then(() => {
        message.success(
          `${t("projects.components.ProjectDocumentsList.idCopied")} ${
            media.id
          }`
        );
      })
      .catch((error) => {
        message.error(
          t("projects.components.ProjectDocumentsList.idCopyFailed")
        );
        console.error("Copy to clipboard failed:", error);
      });
  };

  const handleRegenerate = async () => {
    try {
      await mutateAsync({
        url: `${apiUrl}/media/media/${media.id}/regenerate`,
        method: "post",
        values: {},
        successNotification: {
          type: "success",
          message: t("projects.components.ProjectMedias.regenerateSuccess"),
        },
      });
      // restart polling media status
      setTimeout(() => {
        refetchProjectDetail();
      }, 3000); // wait 3s for server to process (not sure necessary but didn't work without)
    } catch {}
  };

  const [openedModal, setOpenedModal] = useState(false);
  const go = useGo();
  const openModal = () => {
    setOpenedModal(true);
  };
  const closeModal = () => setOpenedModal(false);

  const menuItems: MenuProps["items"] = [
    {
      key: "copy-id",
      label: (
        <Button
          key="copy-id"
          type="text"
          size="large"
          style={{ background: "none" }}
          icon={<CopyOutlined style={{ color: theme.colorText }} />}
        >
          {t("projects.components.ProjectMedias.copyId")}
        </Button>
      ),
      onClick: handleCopyId,
    },
    {
      key: "download",
      label: <DownloadButton url={media.url || ""} size="large" />,
    },
    {
      key: "upload-video",
      label: (
        <Button
          type="text"
          style={{ background: "none" }}
          size="large"
          icon={<PlusOutlined />}
          onClick={() => openModal()}
        >
          {t("projects.components.ProjectMedias.uploadAVideo")}
        </Button>
      ),
    },
  ];

  const menuProps = {
    items: menuItems,
  };

  return (
    <HoverableCard
      style={{
        background: ["Preview", "Published", "Ready"].includes(media.status)
          ? `linear-gradient(
                                rgba(59, 212, 202, 0.6),
                                rgba(59, 212, 202, 0.6)
                              ), url(${backgroundImg})`
          : `linear-gradient(
                                rgba(32, 135, 128, 0.4),
                                rgba(32, 135, 128, 0.4)
                              ), url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: 300,
      }}
      bordered
      hoverable
      size="default"
      styles={{
        header: {
          border: 0,
          padding: 0,
        },

        body: {
          paddingTop: 20,
          paddingBottom: 10,
        },
        actions: {
          border: 0,
          background: "none",
          display: "flex",
          gap: 0,
          flexDirection: "column",
        },

        extra: {
          margin: "20px",
        },
      }}
      extra={
        <Flex vertical justify="space-between">
          <Flex vertical>
            {media.url && (
              <PreviewVideoButton url={media.url || ""} size="large">
                <span className="hoverable-button-text">
                  {t("projects.components.ProjectMedias.watchVideo")}
                </span>
              </PreviewVideoButton>
            )}
            {Boolean(media.id) &&
              ["Ready", "Preview", "Published"].includes(media.status) && (
                <Button
                  onClick={() => onEditCuePoints(media.id!)}
                  icon={
                    <span className="anticon">
                      <ClockCountdown style={{ fontSize: 24 }} weight="bold" />
                    </span>
                  }
                  type="text"
                  size="large"
                  style={{
                    color: "white",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="hoverable-button-text">
                    {t("projects.components.ProjectMedias.documentAnimation")}
                  </span>
                </Button>
              )}
            {media.id &&
              ["Ready", "Preview", "Published"].includes(media.status) && (
                <Space>
                  <SimpleProjectMediaPublish
                    size="large"
                    key="publish"
                    title={t("projects.components.ProjectMedias.share")}
                    project={project}
                    mediaId={media.id}
                    publicationId={
                      mediaPublications?.[0]?.project_publication_id
                    }
                    style={{
                      color: "white",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </Space>
              )}

            <Space>
              {Boolean(media.id) &&
                !["Published", "Generating"].includes(media.status) && (
                  <Popconfirm
                    title={t("buttons.confirm")}
                    onConfirm={handleRegenerate}
                  >
                    <Button
                      icon={
                        <span className="anticon">
                          <Sparkle style={{ fontSize: 24 }} weight="bold" />
                        </span>
                      }
                      type="text"
                      size="large"
                      style={{
                        color: "white",
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span className="hoverable-button-text">
                        {t("media.media.detail.regenerate")}
                      </span>
                    </Button>
                  </Popconfirm>
                )}
            </Space>

            <CanAccess resource="media_generation_steps" action="show">
              {Boolean(media.id) && (
                <Space>
                  <Button
                    onClick={() => {
                      go({
                        to: {
                          resource: "media_media",
                          id: media.id!,
                          action: "show",
                        },
                      });
                    }}
                    icon={
                      <span className="anticon">
                        <BracketsCurly style={{ fontSize: 24 }} weight="bold" />
                      </span>
                    }
                    type="text"
                    size="large"
                    style={{
                      color: "white",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="hoverable-button-text">
                      {t("projects.components.ProjectMedias.debugMedia")}
                    </span>
                  </Button>
                </Space>
              )}
            </CanAccess>
          </Flex>
        </Flex>
      }
    >
      {/* show old publications with multiple items */}
      {(mediaPublications?.length ?? 0) > 1 && (
        <Flex gap={10} vertical>
          <Flex gap={5} wrap="wrap">
            {mediaPublications?.map((publication) => {
              const url = `${MEDIAPLAYER_URL}?projectId=${project.id}&publicationId=${publication.project_publication_id}`;

              const open = () => {
                window.open(url);
              };
              return (
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    flex: "1 0 100%",
                    padding: "0px 15px",
                  }}
                  key={publication.project_publication_id}
                >
                  <Text style={{ flex: "1", color: "white" }} strong ellipsis>
                    {publication.project_publication_id}
                  </Text>
                  <Button
                    onClick={open}
                    icon={<EyeOutlined />}
                    size="large"
                    type="text"
                    style={{ color: "white" }}
                  ></Button>
                  {media.id && (
                    <SimpleProjectMediaPublish
                      type="text"
                      size="large"
                      icon={<EditOutlined />}
                      project={project}
                      mediaId={media.id}
                      publicationId={publication.project_publication_id}
                      title=""
                      style={{ color: "white" }}
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
      {/* show first publication link */}
      {mediaPublications?.[0]?.project_publication_id && (
        <Button
          size="large"
          type="text"
          shape="circle"
          icon={
            <ArrowSquareOut type="text" weight="bold" size={24} color="white" />
          }
          onClick={() => {
            const url = `${MEDIAPLAYER_URL}?projectId=${project.id}&publicationId=${mediaPublications?.[0]?.project_publication_id}`;

            window.open(url);
          }}
          style={{ position: "absolute", bottom: 20, right: 25 }}
        ></Button>
      )}
      <div
        className="media-card-dropdown"
        style={{ position: "absolute", bottom: 18, left: 28 }}
      >
        <Dropdown menu={menuProps} trigger={["click"]}>
          <DotsThreeVertical size={32} color={"#fff"} weight="bold" />
        </Dropdown>
      </div>

      <Modal
        title={t("projects.components.ProjectMedias.attachAMedia")}
        open={openedModal}
        onCancel={() => closeModal()}
        footer={null}
        styles={{ body: { padding: 30 } }}
      >
        <ProjectMediaUpload
          projectData={project}
          media={media}
          onUploadSuccess={async () => {
            const refresh = async () => {
              refetchProjectDetail();
              await invalidate({
                resource: `media/projects/${project.id}/assets?size=50`,
                invalidates: ["list"],
              });
            };
            refresh();
            closeModal();
          }}
        />
      </Modal>
      {media.status === "Generating" && (
        <Flex
          justify="center"
          align="center"
          style={{
            position: "absolute",
            bottom: "45%",
            width: "90%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <ProjectProgress
            creationTime={media.created ?? null}
            processDuration={media.created ? 10 : 0}
            colors={{ from: "#fff", to: "#fff" }}
          />
        </Flex>
      )}
    </HoverableCard>
  );
};
