import { TimelineState } from "@xzdarcy/react-timeline-editor";
import { TDetailedSection } from "pages/media/types";
import { useCuepointsStore } from "./useCuepointsStore";
import { useHighlightsStore } from "./useHighlightsStore";
import { useEffect, useState } from "react";
import { useAntTheme } from "hooks/useAntTheme";
import { Flex, GlobalToken, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { UUID } from "components/UUID";

export const TimestampedScript = ({
  handleScroll,
  timeline,
  setTs,
  ts,
  stepsData,
}: {
  handleScroll: (id: string) => void;
  timeline: TimelineState | null;
  setTs: (time: number) => void;
  ts: number;
  stepsData: any;
}) => {
  const cuepoints = useCuepointsStore((state) => state.cuepoints);
  const { setCurrentHighlight, highlights } = useHighlightsStore();
  const [steps, setSteps] = useState<any>();

  useEffect(() => {
    if (stepsData?.data?.chapters) {
      let previousDuration = 0;

      const timestampedSteps = stepsData?.data?.chapters.map((step, index) => {
        if (index > 0) {
          previousDuration += stepsData?.data?.chapters[index - 1].duration;
        }

        return {
          ...step,
          subtitles: {
            ...step.subtitles,
            words: step.subtitles.words.map((word) => ({
              ...word,
              start_time: previousDuration + word.start_time,
              end_time: previousDuration + word.start_time + word.duration,
            })),
          },
        };
      });

      setSteps(timestampedSteps);
    }
  }, [stepsData, cuepoints]);

  const handleWordClick = (time: number, id?: string) => {
    const videoElement = document.querySelector("video");
    timeline?.setTime(time);

    setTs(time);
    if (!id) {
      setCurrentHighlight(undefined);
    } else {
      handleScroll(id);
    }

    if (videoElement) {
      videoElement.currentTime = time;
    }
  };

  if (!steps?.every((step) => step.duration)) {
    return null;
  }

  return (
    <Flex gap={30} vertical style={{ paddingLeft: 12 }}>
      {steps.map((step) => {
        return (
          <div>
            <Typography.Text
              style={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              {step.chapter_name}
            </Typography.Text>
            <Typography.Text style={{ display: "block", lineHeight: 2.5 }}>
              {step.subtitles.words.map((word: any, index: number) => {
                let highlight;
                const cuepoint = cuepoints.find((cuepoint) => {
                  return (
                    cuepoint.start * 1000 >= word.start_time &&
                    cuepoint.start * 1000 < word.end_time
                  );
                });

                if (cuepoint) {
                  highlight = highlights.find(
                    (h) => h.id === cuepoint.highlightId
                  );
                }

                const isPicked =
                  ts * 1000 >= word.start_time && ts * 1000 < word.end_time;

                if (cuepoint) {
                  return (
                    <>
                      <CuepointedWord
                        key={word.start_time}
                        time={word.start_time / 1000}
                        word={word.word}
                        id={cuepoint.highlightId}
                        handleWordClick={handleWordClick}
                        generated={
                          highlight?.position?.usePdfCoordinates === true
                        }
                        picked={isPicked}
                      />{" "}
                    </>
                  );
                }
                return (
                  <>
                    <StyledWord
                      key={word.start_time}
                      onClick={() => handleWordClick(word.start_time / 1000)}
                      picked={isPicked}
                    >
                      {word.word}
                    </StyledWord>{" "}
                  </>
                );
              })}
            </Typography.Text>
          </div>
        );
      })}
    </Flex>
  );
};

const CuepointedWord = ({
  time,
  word,
  id,
  handleWordClick,
  generated,
  picked,
}: {
  time: number;
  word: string;
  id: string;
  handleWordClick: (time: number, id?: string) => void;
  generated?: boolean;
  picked?: boolean;
}) => {
  const { highlights, currentHighlight } = useHighlightsStore();
  const { theme } = useAntTheme();

  const highlightIndex = highlights.findIndex((h) => h.id === id);

  return (
    <StyledWord
      generated={generated}
      picked={picked}
      cuepointed={true}
      onClick={() => handleWordClick(time, id)}
      style={{
        background:
          currentHighlight && currentHighlight.id === id
            ? `${theme.colorPrimary}`
            : `${theme.colorBgSpotlight}`,
        color: `${theme.colorTextLightSolid}`,
      }}
    >
      {word}
      <WordAnchor
        style={{
          background:
            currentHighlight && currentHighlight.id === id
              ? `${theme.colorPrimary}`
              : `${theme.colorBgSpotlight}`,
        }}
      >
        <Tooltip
          autoAdjustOverflow
          title={<UUID id={id} style={{ color: "#fff" }} />}
        >
          {highlightIndex + 1}
        </Tooltip>
      </WordAnchor>
    </StyledWord>
  );
};

const StyledWord = styled(Typography.Text)<{
  theme: GlobalToken;
  picked?: boolean;
  generated?: boolean;
  cuepointed?: boolean;
}>`
  cursor: pointer;
  border: 2px solid;
  padding-right: 3px;
  padding-left: 3px;
  margin-right: -1px;
  margin-left: -1px;
  border-color: ${({ picked, theme }) =>
    picked ? theme.colorPrimary : "transparent"};
  position: relative;

  ${({ generated }) =>
    generated &&
    `border-bottom: 0;
    &:after {
    position: absolute;
    content: "";
    width: calc(100% + 2px);
    left: -1px;
    height: 3px;
    bottom: -4px;
    background-image: linear-gradient(
      0.25turn,
      #EE00C2,
      #5F00E3
    );
  }`}

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary} !important;
  }
  transition: 0.2s;
  font-size: 18px;
`;

const WordAnchor = styled.div`
  position: absolute;
  font-size: 9px;
  top: -9px;
  right: -9px;
  line-height: 12px;
  padding: 2px 4px;
  border-radius: 20px;
  font-weight: bold;
`;
