import { NumberField } from "@refinedev/antd";
import { GlobalToken, Tooltip } from "antd";
import { AccessScope } from "types";

interface Currency {
  ticker: string;
  market_cap: number;
}

interface AllocationItem {
  ticker: string;
  weight: number;
}

export function balanceToken(
  currencies: Currency[],
  tokens: string[],
  squareRoot: boolean,
  minRate: number,
  maxRate: number,
  isEqual: boolean
): AllocationItem[] {
  let totalCap = 0; // will store the sum of all caps or sqrt of caps
  const tokenCap: Record<string, number> = {}; // will store the cap per token or sqrt of cap
  const composition: AllocationItem[] = []; // will store the resulting composition
  let totalWeight = 0;

  // if (minRate * currencies.length > 1) {
  //   throw new Error(
  //     "Unable to balance tokens, as total composition would be more than 100%"
  //   );
  // }

  // if (maxRate * currencies.length < 1) {
  //   throw new Error(
  //     "Unable to balance tokens, as total composition would be less than 100%"
  //   );
  // }

  for (const currency of currencies) {
    // if (!currency.market_cap) {
    //   continue;
    // }

    const market_cap = squareRoot
      ? Math.sqrt(currency.market_cap)
      : currency.market_cap;
    totalCap += market_cap;
    tokenCap[currency.ticker] = market_cap;
  }

  for (const token of tokens) {
    if (!Object.keys(tokenCap).includes(token)) {
      continue;
    }

    const market_cap = tokenCap[token];
    let weight = market_cap / totalCap;
    if (weight < minRate) {
      weight = minRate;
    } else if (weight > maxRate) {
      weight = maxRate;
    }

    if (isEqual) {
      weight = 1 / tokens.length;
    }

    composition.push({ ticker: token, weight: weight });
    totalWeight += weight;
  }

  let weightDelta = 1 - totalWeight;
  let adjustableWeight = 0;
  const adjustableComponents = [];

  for (const item of composition) {
    if (item.weight > minRate && weightDelta < 0) {
      adjustableWeight += item.weight;
      adjustableComponents.push(item);
    } else if (item.weight < maxRate && weightDelta > 0) {
      adjustableWeight += item.weight;
      adjustableComponents.push(item);
    }
  }

  for (const item of adjustableComponents) {
    if (item.weight > minRate && weightDelta < 0) {
      let tickerDelta = weightDelta * (item.weight / adjustableWeight);
      adjustableWeight -= item.weight;
      if (item.weight + tickerDelta < minRate) {
        item.weight = minRate;
        tickerDelta = minRate - item.weight;
      } else if (item.weight + tickerDelta > maxRate) {
        item.weight = maxRate;
        tickerDelta = maxRate - item.weight;
      } else {
        item.weight += tickerDelta;
      }
      weightDelta -= tickerDelta;
    }
  }

  return composition;
}

export const selectUserCan = (
  accesses: any,
  {
    subject,
    action,
    scope,
  }: { subject: string; action: string; scope?: AccessScope }
) => {
  if (!subject || !action) {
    return false;
  }

  const listAccess: AccessScope[] =
    accesses?.[`Libertify.${subject}.${action}`] ?? [];

  if (!scope) {
    // check we have any scope but not NONE
    return listAccess?.find((x) => x !== "NONE");
  }
  if (listAccess?.includes("ALL")) {
    return true;
  }
  if (scope === "MINE") {
    // if user has wider scope, let him also
    return listAccess?.includes(scope) || listAccess?.includes("ORG");
  }
  return listAccess?.includes(scope);
};

export const sortByDate = <T, K extends keyof T>(
  data: Array<T> = [],
  field: K,
  order: "ASC" | "DESC" = "ASC"
) => {
  return data.sort(
    (a, b) =>
      (order === "ASC" ? 1 : -1) *
      (a[field] as string).localeCompare(b[field] as string)
  );
};

export const autoFormatValue = (
  key: string,
  value: string | number | undefined,
  theme: GlobalToken,
  currency: string = "USD"
) => {
  if (!value) return null;

  if (key === "year") {
    // Exclude formatting for the year field
    return value;
  } else if (
    Number.isInteger(value) &&
    typeof value === "number" &&
    Math.abs(value) > 10000
  ) {
    return (
      <Tooltip
        title={
          <NumberField
            style={{ color: theme.colorTextLightSolid }} // need to force color
            value={value}
            options={{
              style: "currency",
              currency,
              notation: "standard",
            }}
          />
        }
      >
        <NumberField
          value={value}
          options={{
            style: "currency",
            currency,
            notation: "compact",
          }}
        />
      </Tooltip>
    );
  } else {
    return value?.toLocaleString("en-US", {
      style: "currency",
      currency,
    });
  }
};

const appFlavor =
  window.location.search.indexOf("__studio") >= 0 ||
  window.location.hostname.indexOf("studio.") === 0
    ? "studio"
    : "default";
export const isStudio = appFlavor === "studio";
export const isInStorybook = Boolean(
  typeof window !== "undefined" && (window as any).__STORYBOOK_CLIENT_API__
);
export const sortByCreated = <T extends { created: string }[]>({
  items,
  order = "desc",
}: {
  items: T;
  order?: "asc" | "desc";
}) => {
  const newItems = [...items];
  newItems.sort((a, b) => {
    if (a.created === "new") {
      return -1;
    } else if (b.created === "new") {
      return 1;
    }
    return new Date(b.created).getTime() - new Date(a.created).getTime();
  });
  return newItems;
};
export const positionToXY = (position: [number, number, number, number]) => {
  const [x1, y1, x2, y2] = position;
  return { x1, y1, x2, y2 };
};

// A valid phone number is any number that is at least 4 digits long and
// at most 15 digits long. The number can start with a plus sign (+) and
// can contain spaces, hyphens (no more than one in a row and not mixed,
// always followed by a number; whitespace at the end is allowed and
// trimmed for the purpose of validation), and numbers.
export function isValidPhoneNumber(phoneNumber: string) {
  const phoneRegex = /^(\+)?(?:\d+[-\s]?)+\d+$/;
  const digitsOnly = phoneNumber.replace(/[+\s-]/g, "");
  return (
    phoneRegex.test(phoneNumber.trim()) &&
    digitsOnly.length >= 4 &&
    digitsOnly.length <= 15
  );
}

export const isSystemPreferenceLight = window?.matchMedia?.(
  "(prefers-color-scheme: light)"
).matches;

export const getLanguageVariant = (lang: string) => {
  if (lang.includes("-")) {
    return lang;
  }
  const langMap = {
    en: "en-US",
    fr: "fr-FR",
  };
  return langMap[lang] || lang;
};
