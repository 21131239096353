import { useCreate, useInvalidate } from "@refinedev/core";
import { AppContext } from "appContext";
import useSessionData from "hooks/useSessionData";
import { useContext } from "react";
import { t } from "i18next";
import { Organization } from "types";

export default function useOrganizationCreate() {
  const {
    mutateAsync,
    isLoading: isLoadingCreateOrganization,
    error,
    isError,
  } = useCreate<Organization>();
  const invalidate = useInvalidate();
  const { userData, isLoading: isLoadingUserData } = useSessionData();
  const { dispatch } = useContext(AppContext);
  const createOrganization = async (name) => {
    if (!userData?.data?.organizations.length) {
      try {
        const organization = await mutateAsync({
          resource: "media/organizations",
          values: {
            name: name,
            city: "",
            country: "",
            language: "",
          },
          successNotification: {
            type: "success",
            message: t("pages.onboarding.createOrganization.successMessage"),
            description: t(
              "pages.onboarding.createOrganization.successDescription"
            ),
          },
        });
        dispatch({
          type: "setOrganization",
          payload: organization?.data,
        });
        await invalidate({
          resource: "api/users/me/organizations",
          invalidates: ["all"],
        });
        return organization;
      } catch (e) {
        console.debug(e);
      }
    }
  };

  return {
    isLoading: isLoadingUserData || isLoadingCreateOrganization,
    error,
    isError,
    createOrganization,
  };
}
