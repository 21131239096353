import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { UserRectangle } from "@phosphor-icons/react";
import { Flex, Divider, Button } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import FaceSelector from "./FaceSelector";
import { VoiceAndFaceContainerProps } from "./types";
import VoiceSelector from "./VoiceSelector";

const VoiceAndFaceContainer = (props: VoiceAndFaceContainerProps) => {
  const t = useTranslate();
  const { setSelectedVoice } = props;
  const { theme } = useAntTheme();
  return (
    <Flex vertical gap={32} style={{ flex: "5 0 0" }}>
      <Flex>
        <VoiceSelector
          {...props}
          style={{ flex: "3 1 400px" }}
          setSelectedVoice={setSelectedVoice}
          clonedVoice={props.clonedVoice}
        />
        <Flex style={{ flex: "1 1 20%" }}> </Flex>
        <FaceSelector
          {...props}
          style={{ flex: "3 1 425px" }}
          disableFace={props.disableFace}
          setSelectedFace={props.setSelectedFace}
        />
        <Flex style={{ flex: "1 1 20%" }}> </Flex>
      </Flex>

      <Flex style={{ paddingRight: theme.paddingMD }} vertical>
        <Divider>
          {t("components.ContactWithPresenter.VoiceAndFaceContainer.or")}
        </Divider>
        <Button
          icon={
            <span className="anticon">
              <UserRectangle size={24} />
            </span>
          }
          style={{
            display: "flex",
            alignItems: "center",
            border: 0,
            width: "max-content",
            margin: "0 auto",
            boxShadow: "none",
          }}
          onClick={() => {
            props.setCurrentModal("presenter");
          }}
        >
          <span style={{ textDecoration: "underline" }}>
            {t(
              "components.ContactWithPresenter.VoiceAndFaceContainer.useAnExisting"
            )}
          </span>
        </Button>
      </Flex>
    </Flex>
  );
};

export default VoiceAndFaceContainer;

