import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Spin } from "antd";
import "react-indiana-drag-scroll/dist/style.css";
async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

// enableMocking().then(() => {
root.render(
  // <React.StrictMode>
  <React.Suspense fallback={<Spin />}>
    <App />
  </React.Suspense>
  // </React.StrictMode>
);
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
