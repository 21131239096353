import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { User, Sparkle } from "@phosphor-icons/react";
import { Typography, Flex, Switch, Button, List } from "antd";
import { FaceProfileResponse } from "pages/media/types";
import { VoiceAndFaceContainerProps } from "./types";
import useFaceSelector from "./hooks/useFaceSelector";
import VoiceAndFaceSelector from "./VoiceAndFaceSelector";
import styled from "styled-components";
import { FaceProfilePreview } from "../FaceProfilePreview";

const FaceSelector = (props: VoiceAndFaceContainerProps) => {
  const t = useTranslate();
  const { Title, Text } = Typography;
  // so that we don't put the face on top again after initial load
  const { items, isLoading } = useFaceSelector(
    props.selectedFace,
    props.clonedFace,
    props.cloneFaceResponse,
    props.existingPresenter
  );

  const getItems = (items: FaceProfileResponse[]) => {
    if (!items.length && !isLoading) {
      return t("components.ContactWithPresenter.FaceSelector.noFacesFound");
    }

    return (
      <StyledList loading={isLoading ?? props.isLoadingExisting}>
        {items.map((face, index) => (
          <Flex
            key={face.id}
            onClick={() => {
              props.setClonedFace(undefined);
              props.setSelectedFace && props.setSelectedFace(face);
            }}
          >
            <FaceProfilePreview
              selected={
                (props.selectedFace?.id ??
                  props?.cloneFaceResponse?.face_profile_id) === face.id
              }
              name={face.name}
              id={face.id}
              assetPath={
                face.thumbnail_asset_path ??
                face.extracted_asset_path ??
                face.custom_asset_path
              }
            />
          </Flex>
        ))}
      </StyledList>
    );
  };

  return (
    <Flex vertical style={props.style}>
      <Flex gap={5} style={{ flex: 1 }} justify="flex-start" align="center">
        <Flex vertical style={{ flex: "1 0 auto", alignSelf: "start" }}>
          <Flex
            style={{ flex: "1 0 auto" }}
            justify="space-between"
            align="center"
          >
            <Flex gap={10}>
              <span className="anticon">
                <User size="32" />
              </span>
              <Title style={{ margin: 0 }} level={3}>
                {t("components.ContactWithPresenter.FaceSelector.face")}
              </Title>
            </Flex>
            <Switch
              value={!props.isFaceDisabled}
              onChange={(value) => props.disableFace(value)}
            />
          </Flex>
          <Text type="secondary">
            {t("components.ContactWithPresenter.FaceSelector.disableToUse")}
          </Text>
        </Flex>
      </Flex>
      <Flex style={{ height: 400 }}>
        {!props.isFaceDisabled && (
          <VoiceAndFaceSelector
            activeKey={
              (props.cloneFaceResponse?.category ??
                props.selectedFace?.category ??
                "Premade") !== "Premade"
                ? "2"
                : "1"
            }
            premade={getItems(items?.premadeFaces ?? [])}
            custom={getItems(items?.customFaces ?? [])}
          />
        )}
      </Flex>
      <Button
        style={{ margin: "20px auto", width: "max-content" }}
        icon={
          <span className="anticon">
            <Sparkle />
          </span>
        }
        shape="round"
        onClick={() => {
          props.setCurrentModal("face");
        }}
      >
        {t("components.ContactWithPresenter.FaceSelector.cloneANew")}
      </Button>
    </Flex>
  );
};
export default FaceSelector;
const StyledList = styled(List)`
  & .ant-spin-container [style*="min-height: 53px"] {
    position: absolute;
  }
  & .ant-spin-container {
    width: 100%;
  }
  & .ant-spin-nested-loading {
    width: 100%;
  }
  & .ant-spin-container {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  & > .ant-spin-nested-loading > div > .ant-spin > .ant-spin-dot {
    margin-top: 0 !important;
  }
  & .ant-list-empty-text {
    margin: 0 auto;
  }
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

