import { Progress } from "antd";
import { useEffect, useState } from "react";
import { addDateTz } from "pages/media/utils";
import { useAntTheme } from "hooks/useAntTheme";

const PROCESS_INTERVAL = 3000; // milliseconds
const PERCENT_STEP = 4;

export const ProjectProgress = ({
  creationTime,
  processDuration,
  colors,
}: {
  creationTime: string | null;
  processDuration: number;
  colors?: { from: string; to: string };
}) => {
  const [processPercent, setProcessPercent] = useState(0);
  const { theme } = useAntTheme();

  useEffect(() => {
    const durationMillis = processDuration * 60000;

    const calculateProgress = () => {
      if (creationTime === "") {
        setProcessPercent((prevState) => prevState + PERCENT_STEP);
      } else if (creationTime === null) {
        // if there is no creationTime in DB
        setProcessPercent(0);
      } else {
        const startTime = new Date(addDateTz(creationTime)).getTime();
        const currentTime = Date.now();
        const endTime = startTime + durationMillis;

        // Calculate the progress
        const totalDuration = endTime - startTime;
        const elapsedDuration = Math.max(0, currentTime - startTime);
        const progress = Math.min(100, (elapsedDuration / totalDuration) * 100);

        setProcessPercent(Math.round(progress));
      }
    };

    calculateProgress();

    // Update progress every 3 second
    const intervalId = setInterval(calculateProgress, PROCESS_INTERVAL);

    return () => clearInterval(intervalId);
  }, [creationTime, processDuration]);

  useEffect(() => {
    setProcessPercent(0);
  }, [creationTime]);

  return (
    <Progress
      showInfo={false}
      percent={processPercent}
      strokeColor={
        colors ?? { from: theme.colorPrimaryActive, to: theme.colorPrimary }
      }
      status="active"
    />
  );
};
