import { useTranslate } from "@refinedev/core";
import React from "react";
import { useParams } from "react-router-dom";
import { Authenticated, useGo, useOne } from "@refinedev/core";
import { Typography, Card, Space, ColorPicker, Flex, Row, Col } from "antd";
import { useOrganization } from "hooks/useOrganization";
import { EditButton, RefreshButton, Show, DeleteButton } from "@refinedev/antd";
import { BrandKit } from "../types";
import {
  BrandKitAssetResponseWithTypedPurpose,
  BrandKitAssets,
} from "./components/BrandKitAssets";
import { BrandKitMockup } from "./components/BrandKitMockup";
import { colorKeys } from "./index";

export const BrandKitDetail: React.FC = () => {
  const t = useTranslate();
  const params = useParams();
  const go = useGo();
  const brandKitId = params.id;

  const { organization } = useOrganization({});

  const { data, isLoading, refetch, isRefetching } = useOne<BrandKit>({
    resource: `media/${organization?.id}/brand_kits`,
    id: brandKitId,
  });

  const brandKit = data?.data;
  const brandkitAssets = brandKit?.assets as
    | BrandKitAssetResponseWithTypedPurpose[]
    | undefined;

  return (
    <Authenticated key="brandkit_detail">
      <Show
        isLoading={isLoading || isRefetching}
        title={brandKit?.name}
        headerButtons={({ refreshButtonProps, deleteButtonProps }) => (
          <Space>
            <EditButton shape="round" />
            <RefreshButton
              {...refreshButtonProps}
              loading={isRefetching}
              shape="round"
              onClick={() => refetch()}
            />
            <DeleteButton
              {...deleteButtonProps}
              resource={`media/${organization?.id}/brand_kits`}
              loading={isLoading || isRefetching}
              shape="round"
              onSuccess={() => {
                go({ to: { resource: "media_brandkits", action: "list" } });
              }}
              accessControl={{ enabled: false }}
            />
          </Space>
        )}
      >
        <Flex vertical gap={16} style={{ paddingRight: 380 }}>
          <Card title={t("media.brandkit.detail.colorPalette")} type="inner">
            <Row gutter={[16, 16]}>
              {colorKeys.map((colorKey) => (
                <Col
                  xl={8}
                  lg={24}
                  md={24}
                  sm={24}
                  key={colorKey}
                  style={{ display: "flex" }}
                >
                  <Card
                    style={{
                      width: "100%",
                    }}
                    size="small"
                  >
                    <Typography.Text>
                      {t(`media.brandkit.create.${colorKey}`)}
                    </Typography.Text>
                    <Flex
                      gap={10}
                      // hack to force rerender of new updated color
                      key={brandKit?.palette[colorKey] + colorKey}
                    >
                      <ColorPicker
                        disabled
                        format="hex"
                        size="large"
                        defaultValue={brandKit?.palette[colorKey] ?? ""}
                        showText
                      />
                    </Flex>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>

          <BrandKitAssets assets={brandkitAssets} />
        </Flex>
        {brandkitAssets && (
          <BrandKitMockup assets={brandkitAssets} colors={brandKit?.palette} />
        )}
      </Show>
    </Authenticated>
  );
};
