import { useTranslate } from "@refinedev/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Authenticated,
  HttpError,
  useGo,
  useInvalidate,
  useNotification,
  useUpdate,
} from "@refinedev/core";
import {
  Card,
  Input,
  ColorPicker,
  Form,
  Flex,
  Typography,
  Row,
  Col,
} from "antd";
import { useOrganization } from "hooks/useOrganization";
import { Edit, useForm } from "@refinedev/antd";
import { ColorPickerProps } from "antd/lib";
import { BrandKit } from "../types";
import { UploadFilesRef } from "/components/UploadFiles";
import {
  BrandKitAssetResponseWithTypedPurpose,
  BrandKitAssets,
} from "./components/BrandKitAssets";
import { PaletteResponse } from "media";
import { colorKeys } from "./index";
import { BrandKitMockup } from "./components/BrandKitMockup";

const colorPickerProps: ColorPickerProps = {
  showText: true,
  format: "hex",
  size: "large",
  disabledAlpha: true,
};

// type UploadImage = {
//   asset_id?: string;
//   name: string;
//   file?: File;
//   status: string;
//   url: string;
//   purpose: string;
// };

export const BrandKitEdit: React.FC = () => {
  const t = useTranslate();
  const params = useParams();
  const brandKitId = params.id;
  const invalidate = useInvalidate();
  const go = useGo();
  const { open } = useNotification();
  const uploadMusicRef = React.useRef<UploadFilesRef>(null);
  const uploadFontsRef = React.useRef<UploadFilesRef>(null);
  const uploadVideoRef = React.useRef<UploadFilesRef>(null);
  const uploadPicturesRef = React.useRef<UploadFilesRef>(null);
  const refs = [
    uploadMusicRef,
    uploadFontsRef,
    uploadVideoRef,
    uploadPicturesRef,
  ];
  const [validFiles, setValidFiles] = useState(false);
  const [saving, setSaving] = useState(false);

  const { organization } = useOrganization({});
  const { mutate: updateBrandKit } = useUpdate<BrandKit>();

  const { formProps, form, saveButtonProps, queryResult } = useForm<
    BrandKit,
    HttpError,
    PaletteResponse
  >({
    resource: `media/${organization?.id}/brand_kits`,
    id: brandKitId,
  });

  const brandKit = queryResult?.data?.data;
  const brandKitPalette = brandKit?.palette as PaletteResponse;
  const brandkitAssets = brandKit?.assets as
    | BrandKitAssetResponseWithTypedPurpose[]
    | undefined;

  const [colors, setColors] = useState<PaletteResponse>();

  useEffect(() => {
    setColors(brandKitPalette);
  }, [brandKitPalette]);

  function validateFiles(
    assets: {
      purpose?: string;
    }[]
  ) {
    setValidFiles(!assets.some((asset) => !asset.purpose));
  }

  useEffect(() => {
    if (brandKit && form) {
      form.setFieldsValue({ name: brandKit.name, ...brandKit.palette });
    }
  }, [brandKit, form]);

  const handleSave = async (values: any) => {
    try {
      if (!validFiles) {
        throw {
          type: "error",
          message: t("media.brandkit.edit.pleaseSetA"),
        };
      }
      const brandKitAssetUrl = `media/${organization?.id}/brand_kits/${brandKit?.id}/assets`;
      // Create binary assets and upload to S3
      setSaving(true);

      const uploadResult = await Promise.all([
        uploadPicturesRef.current?.upload(brandKitAssetUrl),
        uploadMusicRef.current?.upload(brandKitAssetUrl),
        uploadFontsRef.current?.upload(brandKitAssetUrl),
        uploadVideoRef.current?.upload(brandKitAssetUrl),
      ]);

      await updateBrandKit({
        resource: `media/${organization?.id}/brand_kits`,
        id: brandKitId!,
        values: {
          name: values.name,
          palette: values.colors,
        },
      });
      const uploadResultLength = uploadResult
        .flat()
        .filter((result): result is number => !!result)
        .reduce((acc, count) => acc + count, 0);
      if (uploadResultLength) {
        // const multiple = uploadResultLength > 1;
        open!({
          type: "success",
          message: t("media.brandkit.edit.assetsUploaded"),
        });
      }
      await invalidate({
        resource: `media/${organization?.id}/brand_kits`,
        id: brandKitId,
        invalidates: ["all"],
      });

      go({
        to: {
          resource: "media_brandkits",
          id: brandKitId!,
          action: "show",
        },
      });
    } catch (error) {
      console.error(error);
      open!({
        type: "error",
        message:
          (
            error as {
              message: string;
            }
          )?.message ?? t("media.brandkit.edit.failedToSave"),
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Authenticated key="brandkit_edit">
      <Edit
        isLoading={queryResult?.isLoading || queryResult?.isRefetching}
        title={brandKit?.name}
        saveButtonProps={{
          ...saveButtonProps,
          shape: "round",
          size: "large",
          icon: null,
          loading: saving,
        }}
        headerButtons={<></>}
      >
        <Flex vertical style={{ paddingRight: 380 }}>
          <Form
            layout={"vertical"}
            {...formProps}
            form={form}
            onFinish={handleSave}
          >
            {/* <StyledWrapper> */}
            <Form.Item
              label={t("media.brandkit.edit.title")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Flex vertical gap={16}>
              <Card
                title={t("media.brandkit.edit.colorPalette")}
                type="inner"
                key={brandKit?.palette?.primary_color}
              >
                <Row gutter={[16, 16]}>
                  {colorKeys.map((colorKey) => (
                    <Col
                      xl={8}
                      lg={24}
                      md={24}
                      sm={24}
                      key={colorKey}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <Card style={{ width: "100%" }} size="small">
                        <Form.Item
                          name={["colors", colorKey]}
                          label={t(`media.brandkit.create.${colorKey}`)}
                        >
                          <ColorPicker
                            {...colorPickerProps}
                            defaultValue={brandKit?.palette[colorKey] ?? ""}
                            // showText={
                            //   !!brandKit?.palette[colorKey] ||
                            //   form.isFieldTouched(colorKey)
                            // }
                            size="large"
                            onChange={(color) => {
                              form.setFieldValue(
                                ["colors", colorKey],
                                color.toHexString()
                              );
                              setColors((prev) => ({
                                ...prev,
                                [colorKey]: color.toHexString(),
                              }));
                            }}
                          />
                        </Form.Item>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>

              <BrandKitAssets
                validateFiles={validateFiles}
                refs={refs}
                assets={brandkitAssets}
              />
            </Flex>
          </Form>
        </Flex>
        <BrandKitMockup assets={brandkitAssets} colors={colors} />
      </Edit>
    </Authenticated>
  );
};
