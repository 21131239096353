import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import {
  AlignLeftOutlined,
  DownloadOutlined,
  EyeOutlined,
  GlobalOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  YahooOutlined,
} from "@ant-design/icons";
import {
  Authenticated,
  CanAccess,
  useCustom,
  useGo,
  useInfiniteList,
} from "@refinedev/core";
import {
  Affix,
  Badge,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Grid,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { Organization } from "types";
import { Show } from "@refinedev/antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjsBusinessDays from "dayjs-business-days";
import { useDebounce } from "use-debounce";
import { memoize } from "lodash";
import { MediaDescriptor } from "../types";
import React from "react";
import BatchScriptList from "../components/BatchScriptList";
import { MediaStatus } from "../components/MediaStatusComponent";

dayjs.extend(dayjsBusinessDays);
dayjs.extend(customParseFormat);

type Video = MediaDescriptor & {
  status?: string;
};

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}
const colors = [
  "cyan",
  "magenta",
  "geekblue",
  "purple",
  "blue",
  "yellow",
  "pink",
  "gold",
  "volcano",
];

const videoColors: Record<string, string> = {};
const getVideoTypeColor = memoize((videoType: string) => {
  if (videoColors[videoType]) {
    return videoColors[videoType];
  } else {
    const nextColor =
      colors.find((color) => !Object.values(videoColors).includes(color)) ??
      "blue";
    videoColors[videoType] = nextColor;
    return nextColor;
  }
});
const getVideoTypeLabel = (videoType: string) => {
  if (videoType?.indexOf("_") > 0) {
    // remove prefix
    return videoType.split("_")[1];
  } else {
    return videoType;
  }
};

type MetadataAsset = Record<string, string | number>;

const VideoCard = memo(({ video }: { video: Video }) => {
  const title = (video.metadata.assets as MetadataAsset[])
    .map((x) => x.name)
    .join(", ");
  const url = video.url;
  const linkRef = createRef<HTMLAnchorElement>();
  const go = useGo();
  const [thumb, setThumb] = useState(true);
  const statusMode = Boolean(video.status);
  const isCompact = statusMode; // TODO a real compact mode

  const handleDownload = () => {
    return linkRef.current?.click();
  };
  const handleShowMedia = () => {
    go({
      to: {
        resource: "media_media",
        action: "show",
        id: video.id,
      },
    });
  };
  return (
    <Card
      size="small"
      title={
        <>
          {statusMode ? (
            <MediaStatus status={video.status!} label={title} />
          ) : (
            title
          )}
        </>
      }
      styles={{ header: { fontSize: 18 } }}
      extra={
        <Space size={"small"}>
          <Tooltip title={t("media.batches.list.copyId")}>
            <Typography.Text
              copyable={{ text: video.id, tooltips: false }}
              style={{ marginRight: 5 }}
            />
          </Tooltip>
          <CanAccess resource="media_generation_steps" action="show">
            <Tooltip title={t("media.batches.list.showSteps")}>
              <Button
                onClick={handleShowMedia}
                icon={<EyeOutlined />}
                shape="circle"
                type="text"
              ></Button>
            </Tooltip>
          </CanAccess>
          <Tooltip title={t("media.batches.list.download")}>
            <Button
              onClick={handleDownload}
              icon={<DownloadOutlined />}
              shape="circle"
              type="text"
            ></Button>
          </Tooltip>
        </Space>
      }
    >
      {!isCompact && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
            marginTop: -10,
          }}
        >
          <a
            ref={linkRef}
            rel="noreferrer"
            target="_blank"
            style={{ display: "none" }}
            download={video.url}
            href={url}
          >
            {t("media.batches.list.download")}
          </a>
          {!thumb ? (
            <video
              controls
              width={360}
              height={500}
              preload="none"
              autoPlay={true}
            >
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <Flex
              style={{ height: 500, width: 360 }}
              justify="center"
              align="center"
              vertical
            >
              <Button
                icon={
                  <PlayCircleOutlined style={{ fontSize: 70, opacity: 0.5 }} />
                }
                type="text"
                size="large"
                shape="circle"
                onClick={() => setThumb(false)}
                style={{ width: 100, height: 100, padding: 0 }}
              />
            </Flex>
          )}
        </div>
      )}
      <Card.Meta
        style={{ marginBottom: -10 }}
        description={
          <>
            <Flex justify="space-between">
              <Badge
                style={{ fontWeight: "bold" }}
                color={getVideoTypeColor(video.metadata.type)}
                text={getVideoTypeLabel(video.metadata.type)}
              />
              <Typography.Text>
                {dayjs(video.metadata.date).format("dddd LL")}
              </Typography.Text>
            </Flex>
            <Tabs
              size="small"
              items={(video.metadata.assets as MetadataAsset[]).map(
                (asset, index) => {
                  return {
                    label: String(asset.ticker),
                    key: String(index),
                    disabled: false,
                    children: (
                      <Flex vertical style={{ marginTop: -8 }}>
                        <Typography.Text>{asset.name}</Typography.Text>
                        <Flex justify="space-between">
                          <Space>
                            <Typography.Text>{asset.isin}</Typography.Text>
                            <CanAccess
                              resource="media_generation_steps"
                              action="show"
                            >
                              <Button
                                target="_blank"
                                icon={<YahooOutlined />}
                                type="link"
                                href={`https://finance.yahoo.com/quote/${asset.ticker}`}
                              />
                            </CanAccess>
                          </Space>
                          <Tooltip title={asset.mic_code}>
                            <Typography.Text style={{ opacity: 0.5 }}>
                              <GlobalOutlined /> {asset.market}
                            </Typography.Text>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    ),
                  };
                }
              )}
            />
          </>
        }
      />
    </Card>
  );
});

type SortField = "ticker" | "name" | "market" | "type" | "status";

export const MediaBatchList = ({
  organization,
}: {
  organization?: Organization;
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
  const id = "ALL";
  const dateFormat = "YYYY-MM-DD";
  const defaultSortField = "name";

  const [videos, setVideos] = useState<any[]>([]);
  const [loadAll, setLoadAll] = useState(true);
  const [showStatus, setShowStatus] = useState(false);
  const [isMatching, startTransition] = useTransition();
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState<SortField>(defaultSortField);
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const chunkSize = breakpoint.xl ? 3 : 2;
  const [date, setDate] = useState<dayjs.Dayjs>(
    (dayjs(new Date()) as any).prevBusinessDay()
  );
  const key = date.format(dateFormat);

  const hardcoded: Video[] = [];

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteList<any>({
    resource: `media/${organization?.id}/batches/${id}/execution/${key}`,
    meta: { cursor: true },
    queryOptions: { enabled: Boolean(organization?.id) },
  });
  const { data: statusData } = useCustom({
    url: `media/${organization?.id}/batches/${id}/execution/${key}/status`,
    method: "get",
    queryOptions: {
      enabled: Boolean(organization?.id) && showStatus,
    },
  });

  const handleDateChange = (value: dayjs.Dayjs | null) => {
    value && setDate(value);
  };
  const videoList: Video[] = useMemo(() => {
    return showStatus
      ? ((statusData?.data?.medias?.map((x: any) => x.media) as Video[]) ?? [])
      : [
          ...(data ? hardcoded : []),
          ...(data?.pages.reduce(
            (acc: Video[], page: any) => [...acc, ...page.data.medias],
            []
          ) ?? []),
        ];
  }, [data, showStatus, statusData]);

  const searchInField = (value: string, query: string) =>
    value.toLocaleLowerCase().includes(query.toLocaleLowerCase());

  const searchVideo = useCallback((query: string) => {
    setSearch(query);
  }, []);
  const [handleSearch] = useDebounce(searchVideo, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.currentTarget.value);
  };

  useEffect(() => {
    if (loadAll && hasNextPage) {
      fetchNextPage();
    }
  }, [loadAll, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const sortVideos = useCallback(
    (items: Video[]) => {
      let result;
      if (sortField === "type") {
        result = items.sort((a, b) =>
          (a.metadata[sortField] as string).localeCompare(b.metadata[sortField])
        );
      } else if (sortField === "status") {
        result = items.sort((a, b) =>
          (a[sortField] as string).localeCompare(b[sortField] as string)
        );
      } else {
        result = items.sort((a, b) =>
          (a.metadata?.assets[0] as any)?.[sortField].localeCompare(
            (b.metadata?.assets[0] as any)?.[sortField]
          )
        );
      }
      return result;
    },
    [sortField]
  );

  const typeFilter = filterValue
    .filter((x) => x.indexOf("type:") === 0)
    .map((x) => x.split(":")[1]);
  const marketFilter = filterValue
    .filter((x) => x.indexOf("market:") === 0)
    .map((x) => x.split(":")[1]);
  const statusFilter = filterValue
    .filter((x) => x.indexOf("status:") === 0)
    .map((x) => x.split(":")[1]);

  const filterVideos = (
    items: Video[],
    search: string,
    filterValue: string[]
  ) => {
    const keywords = search
      .split(",")
      .map((x) => x.trim())
      .filter(Boolean); // remove trailing space
    return items.filter((video) => {
      const typeMatches =
        typeFilter.length === 0 ||
        typeFilter.includes(video.metadata?.type as string);
      if (!typeMatches) return false;

      const marketMatches =
        marketFilter.length === 0 ||
        marketFilter.includes(
          (video.metadata?.assets[0] as any)?.market as string
        );
      if (!marketMatches) return false;

      const statusMatches =
        statusFilter.length === 0 ||
        (video.status && statusFilter.includes(video.status));

      if (!statusMatches) return false;

      if (keywords.length === 0) return true;
      const assetMatches =
        video.metadata?.assets &&
        keywords.some((keyword) =>
          (video.metadata.assets as MetadataAsset[]).some(
            (asset) =>
              searchInField(asset.name as string, keyword) ||
              searchInField(asset.ticker as string, keyword) ||
              searchInField(asset.market as string, keyword)
          )
        );

      return assetMatches;
    });
  };

  const handleFilterFieldChange = (value: string[]) => {
    setFilterValue(value);
  };

  const handleSortFieldChange = (value: SortField) => {
    setSortField(value);
  };

  const allVideos = [...hardcoded, ...videoList];
  useEffect(() => {
    startTransition(() => {
      if (search.length === 0) {
        setVideos([
          ...filterVideos(hardcoded, search, filterValue),
          ...sortVideos(filterVideos(videoList, search, filterValue)),
        ]);
        return;
      }
      const filtered = filterVideos(allVideos, search, filterValue);
      const sorted = sortVideos(filtered);
      setVideos(sorted);
    });
  }, [data, search, videoList, sortField, filterValue]);

  const videoTypes = [...new Set(allVideos.map((x) => x.metadata.type))];
  const videoTypeOptions = [...new Set(allVideos.map((x) => x.metadata.type))]
    .sort()
    .map((value: string) => ({ label: getVideoTypeLabel(value), value }));

  const marketOptions = [
    ...new Set(
      allVideos.map((x) => (x.metadata.assets[0] as any)?.market as string)
    ),
  ]
    .sort()
    .map((value: string) => ({ label: getVideoTypeLabel(value), value }));

  const statusOptions = [
    ...new Set(allVideos.map((video) => video.status)),
  ].map((status) => ({
    label: status,
    value: status,
  }));

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Authenticated key="media_batches">
      <Show
        title={
          <Space wrap size={"large"}>
            <Typography.Title
              level={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {t("media.batches.list.videoBuilder")}{" "}
              <Typography.Text style={{ opacity: 0.6 }}>
                {t("media.batches.list.v10")}
              </Typography.Text>
            </Typography.Title>

            <Image
              onError={(e) => ((e.target as any).style.display = "none")}
              onLoad={(e) => ((e.target as any).style.display = "block")}
              width={200}
              height={30}
              alt="logo"
              preview={false}
              src={`/organization/${organization?.id}/${organization?.id}.svg`}
            />
          </Space>
        }
        headerButtons={({ defaultButtons }) => (
          <Space size={"middle"}>
            <CanAccess resource="media_generation_steps" action="show">
              <Switch
                unCheckedChildren={"Status"}
                checkedChildren={"Status"}
                checked={showStatus}
                onClick={() => setShowStatus((value) => !value)}
              />
            </CanAccess>
            <DatePicker
              size="large"
              defaultValue={(dayjs() as any).prevBusinessDay()}
              format={dateFormat}
              onChange={handleDateChange}
            />
            <CanAccess resource="media_generation_steps" action="show">
              <Button
                onClick={toggleDrawer}
                size="large"
                icon={<AlignLeftOutlined />}
              >
                {t("media.batches.list.showScripts")}
              </Button>
            </CanAccess>
          </Space>
        )}
      >
        {organization && (
          <BatchScriptList
            organization={organization}
            date={key}
            mediaTypes={videoTypes}
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
          />
        )}
        <Flex
          gap={10}
          vertical={isMobile}
          align={!isMobile ? "center" : "start"}
          justify="space-between"
          style={{ marginBottom: 40 }}
        >
          <div>
            <Space size={"small"}>
              <Typography.Text style={{ opacity: 0.6 }}>
                {allVideos.length} {t("media.batches.list.loaded")}
                {videos.length} {t("media.batches.list.matches")}
              </Typography.Text>
              <Spin
                size="small"
                style={{ opacity: isFetching || isMatching ? 1 : 0 }}
                indicator={
                  isFetching ? undefined : (
                    <LoadingOutlined style={{ fontSize: 20 }} spin />
                  )
                }
              />
            </Space>
            <Form.Item
              label={t("media.batches.list.loadAll")}
              style={{ margin: 0 }}
            >
              <Switch
                size="default"
                checked={loadAll}
                onClick={() => setLoadAll((value) => !value)}
              />
            </Form.Item>
          </div>
          <Affix offsetTop={20}>
            <Input.Search
              size="large"
              placeholder={t("media.batches.list.tickerOrName")}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              style={{
                width: 300,
                boxShadow: "0 0 20px #00000020",
                borderRadius: 10,
              }}
            />
          </Affix>
          <Flex vertical align={isMobile ? "start" : "end"} gap={5}>
            <Form.Item
              label={t("media.batches.list.sortBy")}
              style={{ margin: 0 }}
            >
              <Select
                size="middle"
                defaultValue={defaultSortField}
                style={{ width: 250 }}
                onChange={handleSortFieldChange}
                options={[
                  { value: "name", label: t("media.batches.list.stockName") },
                  {
                    value: "ticker",
                    label: t("media.batches.list.stockTicker"),
                  },
                  { value: "market", label: t("media.batches.list.market") },
                  { value: "type", label: t("media.batches.list.template") },
                  ...(showStatus
                    ? [
                        {
                          value: "status",
                          label: t("media.batches.list.status"),
                        },
                      ]
                    : []),
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("media.batches.list.filter")}
              style={{ margin: 0 }}
            >
              <Select
                mode="multiple"
                size="middle"
                style={{ width: 250 }}
                listHeight={400}
                onChange={handleFilterFieldChange}
              >
                <Select.OptGroup
                  label={t("media.batches.list.type")}
                  key={"type"}
                >
                  {videoTypeOptions.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={`type:${option.value}`}
                      label={option.label}
                    >
                      <Badge
                        color={getVideoTypeColor(option.value)}
                        text={option.label}
                      />
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                <Select.OptGroup
                  label={t("media.batches.list.market")}
                  key={"market"}
                >
                  {marketOptions.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={`market:${option.value}`}
                      label={option.label}
                    >
                      <GlobalOutlined /> {option.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                {showStatus && (
                  <Select.OptGroup
                    label={t("media.batches.list.status")}
                    key={"status"}
                  >
                    {statusOptions.map((option) => (
                      <Select.Option
                        key={option.value}
                        value={`status:${option.value}`}
                      >
                        <MediaStatus
                          status={option.value!}
                          label={option.value}
                        />
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}
              </Select>
            </Form.Item>
          </Flex>
        </Flex>
        <Flex vertical gap={30}>
          {[...chunks(videos, chunkSize)].map((chunk, i) => (
            <Row gutter={[30, 30]} key={"chunk" + i}>
              {chunk.map((video) => (
                <Col key={video.id} span={24} md={24 / chunkSize}>
                  <VideoCard video={video} />
                </Col>
              ))}
            </Row>
          ))}
        </Flex>
        {hasNextPage && (
          <Flex justify="center" style={{ marginTop: 40 }}>
            <Button
              onClick={() => fetchNextPage()}
              loading={isFetchingNextPage}
            >
              {t("media.batches.list.loadMore")}
            </Button>
          </Flex>
        )}
      </Show>
    </Authenticated>
  );
};
